export const centeredModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minWidth: '360px',
    transform: 'translate(-50%, -50%)'
  },
  overlay: { zIndex: 1000, backgroundColor: '#00000090' }
}
