import styled from 'styled-components'

const OurStoresStyle = {
  Container: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
  `,
  ScrollContainer: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    padding: 10px;
    overflow: auto;
  `
}

export default OurStoresStyle
