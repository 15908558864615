import { Bubble } from 'pages/custom-home/homeTypes'
import { ChangeEvent, useRef, useState } from 'react'
import Modal from 'react-modal'
import { centeredModal } from 'utils/modalStyles'
import PlaceHolder from '../../../../../../assets/image-placeholder.png'

type ModalProps = {
  bubble?: Bubble
  selectedImage?: string
  isOpen: boolean
  onClose: () => void
  onSave: (
    oldId: string,
    bubble: Bubble,
    newImage?: { file: File; b64: string }
  ) => void
}

export const BubblesModal = ({
  isOpen,
  onClose,
  bubble,
  selectedImage,
  onSave
}: ModalProps) => {
  const [bubbleTitle, setBubbleTitle] = useState<string>(
    bubble ? bubble.title : ''
  )
  const [bubbleId, setBubbleId] = useState<string>(bubble ? bubble.id : '')
  const [oldId, setOldId] = useState<string>(bubble ? bubble.id : '')
  const [imageAsFile, setImageAsFile] = useState<
    { file: File; b64: string } | undefined
  >()
  const [previousImage, setPreviousImage] = useState<string | undefined>(
    selectedImage ?? undefined
  )

  const inputFile = useRef<HTMLInputElement | null>(null)

  const handleImageAsFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : undefined
    if (!file) return

    function getBase64(callback: (b64: string) => void) {
      if (!file) return callback('')
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => callback(reader.result?.toString() ?? '')
      reader.onerror = () => callback('')
    }

    getBase64((b64: string) => setImageAsFile({ file, b64 }))
  }

  return (
    <Modal
      isOpen={isOpen}
      style={centeredModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        onClose()
      }}
      onAfterOpen={() => {
        setBubbleTitle(bubble ? bubble.title : '')
        setBubbleId(bubble ? bubble.id : '')
        setOldId(bubble ? bubble.id : '')
        setPreviousImage(selectedImage)
      }}
      onAfterClose={() => {
        setBubbleTitle('')
        setBubbleId('')
        setOldId('')
        setImageAsFile(undefined)
        setPreviousImage(undefined)
      }}
    >
      <div className="flex flex-col items-center">
        <div className="static">
          <input
            type="file"
            accept="image/png"
            onChange={handleImageAsFile}
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
          />
          <img
            onClick={() => {
              inputFile?.current?.click()
            }}
            className="absolute object-cover rounded-full h-24 w-24 mb-4 opacity-0 hover:opacity-80 cursor-pointer"
            src={PlaceHolder}
          />
          <img
            className="object-cover rounded-full h-24 w-24 mb-4"
            src={
              imageAsFile?.b64 ?? previousImage ?? bubble?.image ?? PlaceHolder
            }
          />
        </div>
        <input
          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
          defaultValue={bubbleTitle}
          placeholder="Insira o nome da categoria"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setBubbleTitle(e.target.value)
          }}
        />
        <input
          className="appearance-none mt-2 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
          defaultValue={bubbleId}
          pattern="[0-9]*"
          placeholder="Insira o ID da categoria"
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            setBubbleId(
              e.target.validity.valid && e.target.value.length
                ? e.target.value
                : ''
            )
          }}
        />
        <div className="pt-4 border-t border-gray-200 mt-5 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              if (!bubbleId.length || !bubbleTitle.length) return
              if (!imageAsFile && !bubble) return
              onSave(
                oldId,
                bubble
                  ? {
                      title: bubbleTitle,
                      image: bubble?.image,
                      id: bubbleId
                    }
                  : {
                      title: bubbleTitle,
                      image: '',
                      id: bubbleId
                    },
                imageAsFile
              )
            }}
          >
            Salvar
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onClose()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}
