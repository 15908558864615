import { Loading } from 'components/loading'
import { CategoriesPage } from 'pages/categories'
import { CustomHome } from 'pages/custom-home'
import { LoginPage } from 'pages/login'
import { NotificationsPage } from 'pages/notifications'
import { ProductPagesPage } from 'pages/product-pages'
import { PromotionsPage } from 'pages/promotions'
import { RegisterPage } from 'pages/register'
import { SettingsPage } from 'pages/settings'
import { MaintenancePeriod } from 'pages/maintenance-period'
import { StoresPage } from 'pages/stores'
import { SubCategoriesPage } from 'pages/subcategories'
import { UsersPage } from 'pages/users-pages'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  Link,
  Navigate,
  Outlet,
  ReactLocation,
  Router,
  useMatch
} from 'react-location'
import { auth } from '../config/firebase'

function Auth({ children }: { children: {} }) {
  const [user, loading] = useAuthState(auth)

  if (loading) {
    return <Loading />
  }

  if (!user) {
    return <Navigate to="/login" />
  }

  return <>{children}</>
}

export default function getRounterConfig() {
  const location = new ReactLocation()
  const routes = [
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/notifications',
      element: (
        <Auth>
          <NotificationsPage />
        </Auth>
      )
    },
    {
      path: '/minhas-lojas',
      element: (
        <Auth>
          <StoresPage />
        </Auth>
      )
    },
    {
      path: '/register',
      element: (
        <Auth>
          <RegisterPage />
        </Auth>
      )
    },
    {
      path: '/',
      element: (
        <Auth>
          <CustomHome />
        </Auth>
      )
    },
    {
      path: '/categories',
      element: (
        <Auth>
          <CategoriesPage />
        </Auth>
      )
    },
    {
      path: '/subcategories/:id',
      element: (
        <Auth>
          <SubCategoriesPage />
        </Auth>
      )
    },
    {
      path: '/product-pages',
      element: (
        <Auth>
          <ProductPagesPage />
        </Auth>
      )
    },
    {
      path: '/promotions',
      element: (
        <Auth>
          <PromotionsPage />
        </Auth>
      )
    },
    {
      path: '/users',
      element: (
        <Auth>
          <UsersPage />
        </Auth>
      )
    },
    {
      path: '/settings',
      element: (
        <Auth>
          <SettingsPage />
        </Auth>
      )
    },
    {
      path: '/maintenance-period',
      element: (
        <Auth>
          <MaintenancePeriod />
        </Auth>
      )
    }
  ]

  return { location, routes, Router, Link, Outlet, useMatch }
}
