import Ui from './style'

const HeaderOrderHistory = () => {
  return (
    <Ui.Container>
      <Ui.Touchable>
        <Ui.IconContainer>
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6666 6.22851C14.6666 6.54492 14.4315 6.80642 14.1264 6.8478L14.0416 6.85351L3.05496 6.85301L7.02412 10.8059C7.26872 11.0495 7.26957 11.4452 7.02602 11.6898C6.80461 11.9122 6.45743 11.9331 6.21237 11.7521L6.14214 11.6917L1.10047 6.67173C1.06823 6.63963 1.04022 6.60488 1.01646 6.56819C1.00974 6.55715 1.00291 6.54585 0.996429 6.53432C0.990471 6.52444 0.985086 6.51418 0.980006 6.50381C0.97295 6.48872 0.966057 6.47313 0.959798 6.45722C0.954712 6.44495 0.950465 6.43302 0.946592 6.421C0.941988 6.40609 0.937513 6.3902 0.933665 6.37406C0.930804 6.3628 0.928538 6.35196 0.926562 6.34108C0.923782 6.32488 0.921417 6.30813 0.919729 6.29117C0.918271 6.27824 0.917394 6.26543 0.916909 6.2526C0.916784 6.24483 0.916626 6.23668 0.916626 6.22851L0.91694 6.2043C0.917418 6.19203 0.918256 6.17977 0.919454 6.16755L0.916626 6.22851C0.916626 6.18907 0.92028 6.15048 0.927266 6.11306C0.928886 6.10413 0.930817 6.09496 0.932955 6.08582C0.937394 6.06701 0.942525 6.04889 0.948433 6.03113C0.951333 6.0223 0.954711 6.01287 0.958321 6.00351C0.965625 5.98471 0.973563 5.96678 0.982296 5.94932C0.986354 5.94111 0.990897 5.93251 0.995656 5.924C1.00347 5.91012 1.01151 5.89693 1.02002 5.88407C1.02602 5.87497 1.03267 5.86549 1.03961 5.85617L1.04502 5.84897C1.06186 5.82697 1.08013 5.80612 1.09968 5.78656L1.10043 5.78598L6.1421 0.765152C6.38668 0.52158 6.78241 0.5224 7.02598 0.766982C7.24741 0.98933 7.26686 1.33659 7.08481 1.58089L7.02415 1.65086L3.05663 5.60301L14.0416 5.60351C14.3868 5.60351 14.6666 5.88333 14.6666 6.22851Z"
              fill="#637390"
            />
          </svg>
        </Ui.IconContainer>
      </Ui.Touchable>
      <Ui.TitleContainer>
        <Ui.TitleText>Nossas Lojas</Ui.TitleText>
      </Ui.TitleContainer>
    </Ui.Container>
  )
}

export default HeaderOrderHistory
