import { Heading } from 'components'
import Layout from 'components/layout'
import { useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd'
import ReactLoading from 'react-loading'
import { upload } from 'services/storage'
import {
  HOME_REORDER_FLAG,
  HOME_TYPE_BRANDS,
  HOME_TYPE_BUBBLES,
  HOME_TYPE_CAROUSEL,
  HOME_TYPE_CATEGORIES_BANNER,
  HOME_TYPE_COLLECTIONS,
  HOME_TYPE_COUNTDOWN,
  HOME_TYPE_DIVIDER,
  HOME_TYPE_FILTER_GRID,
  HOME_TYPE_MID_CAROUSEL,
  HOME_TYPE_PORTRAIT_CAROUSEL,
  HOME_TYPE_PROD_GRID,
  HOME_TYPE_PROD_GRID_CAT,
  HOME_TYPE_PROD_LIST,
  HOME_TYPE_SHELF,
  HOME_TYPE_SHELF_WITH_BANNER,
  HOME_TYPE_SMARTHINT_PRODUCT_RECOMENDATION,
  HOME_TYPE_STATIC_BANNER
} from 'utils/customHome'
import { generateRequestConfig } from 'utils/requestConfig'
import DeleteIcon from '../../assets/delete_48.svg'
import DragHandle from '../../assets/dragHandle.svg'
import { api } from '../../services/api'
import { BrandsComp } from './components/brands'
import { Bubbles } from './components/bubbles'
import { CategoriesBanner } from './components/categories-banner'
import { CarouselComp } from './components/carousel'
import { MidCarouselComp } from './components/carousel-mid'
import { PortraitCarouselComp } from './components/carousel-portrait'
import { ChangeNameModal } from './components/change-name-modal'
import { CollectionComp } from './components/collections'
import { ConfirmModal } from './components/confirm-modal'
import { CountDownComp } from './components/countdown'
import { FilterGridComp } from './components/filter-grid'
import { NewComponentComp } from './components/new-component'
import {
  NewComponent,
  availableHomeComponents,
  defaultHomes
} from './components/new-component/componentTypes'
import { ProductGridComp } from './components/product-grid'
import { ProductListComp } from './components/product-list'
import { ProductShelf } from './components/product-shelf'
import { ProductShelfWithBanner } from './components/product-shelf-with-banner'
import { SmarthintProductsRecomendation } from './components/smarthint-products-recomendation'
import { StaticBannerComp } from './components/static-banner'
import { Carousel, Home } from './homeTypes'

export const CustomHome = () => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const [animateLast, setAnimateLast] = useState<boolean>(false)
  const [showChangeNameModal, setShowChangeNameModal] = useState<{
    show: boolean
    item?: Home
  }>({ show: false, item: undefined })
  const [showDeleteModal, setShowDeleteModal] = useState<{
    show: boolean
    item?: Home
  }>({ show: false, item: undefined })
  const [homes, setHomes] = useState<Home[]>([])
  const [saving, setSaving] = useState<boolean>(false)
  const [deletions, setDeletions] = useState<Set<string>>(new Set<string>())
  const [updateItems, setUpdateItems] = useState<Set<string>>(new Set<string>())
  const [newImages, setNewImages] = useState<
    {
      key: string
      newImage?: File
      uploadCallack: (fileName: string) => void
    }[]
  >([])

  useEffect(() => {
    getHome()
  }, [])

  async function getHome() {
    setLoaded(false)
    try {
      const { data } = await api.get<Home[]>(
        '/v2/home?admin=true',
        await generateRequestConfig()
      )
      setHomes(data)
      setLoaded(true)
    } catch (e) {
      console.log(e)
      setLoaded(true)
    }
  }

  async function deleteBatch() {
    try {
      await api.patch(
        `/v2/home/delete`,
        [...deletions],
        await generateRequestConfig()
      )
    } catch (e) {
      console.log(e)
    }
  }

  async function updateBatch(home: Home) {
    try {
      await api.put(
        `/v2/home/${home.id}/batch`,
        home,
        await generateRequestConfig()
      )
    } catch (e) {
      console.log(e)
    }
  }

  async function reorderHome(reordered: string[]) {
    try {
      await api.patch(
        '/v2/home/reorder',
        reordered,
        await generateRequestConfig()
      )
    } catch (e) {
      console.log(e)
    }
  }

  async function updloadImages() {
    const uploads: Promise<string>[] = []
    newImages.forEach(async (a) => {
      uploads.push(
        uploadImage((fileName) => {
          a.uploadCallack(fileName)
          return fileName
        }, a.newImage)
      )
    })
    await Promise.all(uploads)
  }

  async function saveChanges() {
    try {
      setSaving(true)
      await updloadImages()
      if (!updateItems.size) setSaving(false)
      const updates: Promise<void>[] = []
      updateItems.forEach(async (homeId) => {
        const home = homes.find((h) => h.id == homeId)
        switch (home?.type) {
          case HOME_TYPE_BUBBLES:
          case HOME_TYPE_CATEGORIES_BANNER:
          case HOME_TYPE_CAROUSEL:
          case HOME_TYPE_MID_CAROUSEL:
          case HOME_TYPE_PORTRAIT_CAROUSEL:
          case HOME_TYPE_PROD_GRID:
          case HOME_TYPE_PROD_GRID_CAT:
          case HOME_TYPE_COUNTDOWN:
          case HOME_TYPE_BRANDS:
          case HOME_TYPE_COLLECTIONS:
          case HOME_TYPE_STATIC_BANNER:
            updates.push(updateBatch(home))
            updateItems.delete(homeId)
            setNewImages([])
            break
          case HOME_TYPE_PROD_LIST:
          case HOME_TYPE_DIVIDER:
          case HOME_TYPE_FILTER_GRID:
          case HOME_TYPE_SMARTHINT_PRODUCT_RECOMENDATION:
          case HOME_TYPE_SHELF:
          case HOME_TYPE_SHELF_WITH_BANNER:
            updates.push(updateBatch(home))
            updateItems.delete(homeId)
            break
        }
        setUpdateItems(updateItems)
      })
      await Promise.all(updates)
      if (deletions.size) await deleteBatch()
      deletions.clear()
      setDeletions(deletions)
      if (updateItems.has(HOME_REORDER_FLAG)) {
        await reorderHome(homes.map((h) => h.id))
        updateItems.delete(HOME_REORDER_FLAG)
      }
      if (!updateItems.size) {
        await getHome()
        setSaving(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function uploadImage(
    callback: (fileName: string) => string,
    image?: File
  ) {
    return callback(await upload(image))
  }

  const handleDeleteHome = () => {
    const home = showDeleteModal.item
    if (!home) return
    setAnimateLast(false)
    homes.splice(homes.indexOf(home), 1)
    const hms = [...homes]
    setHomes(hms)
    setDeletions(deletions.add(home.id))
    if (updateItems.has(home.id)) {
      updateItems.delete(home.id)
      setUpdateItems(updateItems)
    }
    setShowDeleteModal({ show: false, item: undefined })
  }

  const handleDrop = (result: DropResult) => {
    if (!result.destination) return
    const updatedList = [...(homes ?? [])]
    const [reorderedItem] = updatedList.splice(result.source.index, 1)
    updatedList.splice(result.destination.index, 0, reorderedItem)
    if (!homes?.every((val, index) => val === updatedList[index])) {
      setHomes(updatedList)
      setUpdateItems(updateItems.add(HOME_REORDER_FLAG))
    }
  }

  function renderBubbles(home: Home) {
    return (
      <Bubbles
        home={home}
        onUpdate={(bubble, newImage) => {
          setHomes(homes)
          setUpdateItems(updateItems.add(home.id))
          replaceNewImage(
            (fileName) => {
              if (bubble) bubble.image = fileName
            },
            newImage,
            bubble?.id
          )
        }}
      />
    )
  }

  function renderCategoriesBanner(home: Home) {
    return (
      <CategoriesBanner
        home={home}
        onUpdate={(categoryBanner, newImage) => {
          setHomes(homes)
          setUpdateItems(updateItems.add(home.id))
          replaceNewImage(
            (fileName) => {
              if (categoryBanner) categoryBanner.image = fileName
            },
            newImage,
            categoryBanner?.id
          )
        }}
      />
    )
  }

  function renderCarousel(home: Home) {
    function update(action: (homes: Carousel[]) => void) {
      const carouselHome = homes.indexOf(home)
      const newArr = [...homes]
      const newCarousel = [...(newArr[carouselHome].metadata?.carousel || [])]
      action(newCarousel)
      newArr[carouselHome].metadata = {
        ...newArr[carouselHome].metadata,
        carousel: newCarousel
      }
      setHomes(newArr)
      setUpdateItems(updateItems.add(home.id))
    }

    return (
      <CarouselComp
        home={home}
        onDelete={(index) => {
          update((newCarousel: Carousel[]) => {
            const key = `c${index}`
            const carouselFileIndex = newImages.findIndex((bf) => bf.key == key)
            newCarousel.splice(index, 1)
            if (carouselFileIndex != undefined && carouselFileIndex >= 0)
              newImages.splice(carouselFileIndex, 1)
          })
        }}
        onAdd={(index, carousel, newImage) => {
          update((newCarousel: Carousel[]) => {
            for (let i = newCarousel.length - 1; i >= 0; i--) {
              const key = `c${i}`
              const carouselFileEntry = newImages.find((bf) => bf.key == key)
              if (i < index || !carouselFileEntry) continue
              carouselFileEntry.key = `c${i + 1}`
            }
            newImages.push({
              key: `c${index}`,
              newImage,
              uploadCallack: (fileName) => {
                carousel.image = fileName
              }
            })
            newCarousel.splice(index, 0, carousel)
            setNewImages(newImages)
          })
        }}
        onUpdate={(index, carousel, newImage) => {
          update((newCarousel: Carousel[]) => {
            newCarousel.splice(index, 1, carousel)
            const key = `c${index}`
            replaceNewImage(
              (fileName) => {
                carousel.image = fileName
              },
              newImage,
              key
            )
          })
        }}
      />
    )
  }

  function renderMidCarousel(home: Home) {
    function update(action: (homes: Carousel[]) => void) {
      const carouselHome = homes.indexOf(home)
      const newArr = [...homes]
      const newCarousel = [...(newArr[carouselHome].metadata?.carousel || [])]
      action(newCarousel)
      newArr[carouselHome].metadata = {
        ...newArr[carouselHome].metadata,
        carousel: newCarousel
      }
      setHomes(newArr)
      setUpdateItems(updateItems.add(home.id))
    }

    return (
      <MidCarouselComp
        home={home}
        onDelete={(index) => {
          update((newCarousel: Carousel[]) => {
            const key = `c${index}`
            const carouselFileIndex = newImages.findIndex((bf) => bf.key == key)
            newCarousel.splice(index, 1)
            if (carouselFileIndex != undefined && carouselFileIndex >= 0)
              newImages.splice(carouselFileIndex, 1)
          })
        }}
        onAdd={(index, carousel, newImage) => {
          update((newCarousel: Carousel[]) => {
            for (let i = newCarousel.length - 1; i >= 0; i--) {
              const key = `c${i}`
              const carouselFileEntry = newImages.find((bf) => bf.key == key)
              if (i < index || !carouselFileEntry) continue
              carouselFileEntry.key = `c${i + 1}`
            }
            newImages.push({
              key: `c${index}`,
              newImage,
              uploadCallack: (fileName) => {
                carousel.image = fileName
              }
            })
            newCarousel.splice(index, 0, carousel)
            setNewImages(newImages)
          })
        }}
        onUpdate={(index, carousel, newImage) => {
          update((newCarousel: Carousel[]) => {
            newCarousel.splice(index, 1, carousel)
            const key = `c${index}`
            replaceNewImage(
              (fileName) => {
                carousel.image = fileName
              },
              newImage,
              key
            )
          })
        }}
      />
    )
  }

  function renderPortraitCarousel(home: Home) {
    function update(action: (homes: Carousel[]) => void) {
      const carouselHome = homes.indexOf(home)
      const newArr = [...homes]
      const newCarousel = [...(newArr[carouselHome].metadata?.carousel || [])]
      action(newCarousel)
      newArr[carouselHome].metadata = {
        ...newArr[carouselHome].metadata,
        carousel: newCarousel
      }
      setHomes(newArr)
      setUpdateItems(updateItems.add(home.id))
    }

    return (
      <PortraitCarouselComp
        home={home}
        onDelete={(index) => {
          update((newCarousel: Carousel[]) => {
            const key = `c${index}`
            const carouselFileIndex = newImages.findIndex((bf) => bf.key == key)
            newCarousel.splice(index, 1)
            if (carouselFileIndex != undefined && carouselFileIndex >= 0)
              newImages.splice(carouselFileIndex, 1)
          })
        }}
        onAdd={(index, carousel, newImage) => {
          update((newCarousel: Carousel[]) => {
            for (let i = newCarousel.length - 1; i >= 0; i--) {
              const key = `c${i}`
              const carouselFileEntry = newImages.find((bf) => bf.key == key)
              if (i < index || !carouselFileEntry) continue
              carouselFileEntry.key = `c${i + 1}`
            }
            newImages.push({
              key: `c${index}`,
              newImage,
              uploadCallack: (fileName) => {
                carousel.image = fileName
              }
            })
            newCarousel.splice(index, 0, carousel)
            setNewImages(newImages)
          })
        }}
        onUpdate={(index, carousel, newImage) => {
          update((newCarousel: Carousel[]) => {
            newCarousel.splice(index, 1, carousel)
            const key = `c${index}`
            replaceNewImage(
              (fileName) => {
                carousel.image = fileName
              },
              newImage,
              key
            )
          })
        }}
      />
    )
  }
  function renderProductList(home: Home, index: number) {
    return (
      <ProductListComp
        home={home}
        onChange={(home) => {
          const hms = [...homes]
          hms[index] = home

          setHomes(hms)
          setUpdateItems(updateItems.add(home.id))
        }}
      />
    )
  }

  function renderProductGrid(home: Home) {
    return (
      <ProductGridComp
        home={home}
        onChange={(updHome) => {
          const hms = [...homes]
          hms[homes.indexOf(home)] = updHome
          setHomes(hms)
          setUpdateItems(updateItems.add(home.id))
        }}
      />
    )
  }

  function renderCountDown(home: Home) {
    return (
      <CountDownComp
        home={home}
        onUpdate={(countDown, newImage) => {
          const countDownHome = homes.indexOf(home)
          const newArr = [...homes]
          const newCountDown = [
            ...(newArr[countDownHome].metadata?.countDown || [])
          ]
          newCountDown[0] = countDown
          newArr[countDownHome].metadata = {
            ...newArr[countDownHome].metadata,
            countDown: newCountDown
          }
          setHomes(newArr)
          setUpdateItems(updateItems.add(home.id))
          replaceNewImage(
            (fileName) => {
              const element = home.metadata.countDown![0]
              if (!element) return
              element.image = fileName
            },
            newImage?.file,
            home.id
          )
        }}
      />
    )
  }

  function renderBrands(home: Home) {
    return (
      <BrandsComp
        home={home}
        onChange={(brand, newImage) => {
          setHomes(homes)
          setUpdateItems(updateItems.add(home.id))
          replaceNewImage(
            (fileName) => {
              if (brand) brand.image = fileName
            },
            newImage,
            brand?.id.toString()
          )
        }}
      />
    )
  }

  function renderCollections(home: Home) {
    return (
      <CollectionComp
        home={home}
        onChange={(collection, newImage) => {
          setHomes(homes)
          setUpdateItems(updateItems.add(home.id))
          if (newImage) {
            const collectionFileEntry = newImages.find(
              (bf) => bf.key == collection?.id.toString()
            )
            collectionFileEntry
              ? (collectionFileEntry.newImage = newImage)
              : newImages.push({
                  key: collection?.id.toString() ?? '',
                  newImage,
                  uploadCallack: (fileName) => {
                    if (collection) collection.image = fileName
                  }
                })
            setNewImages(newImages)
          }
        }}
      />
    )
  }

  function renderDivider() {
    return <></>
  }

  function renderFilterGrid(home: Home) {
    return (
      <FilterGridComp
        home={home}
        onUpdate={() => {
          setHomes(homes)
          setUpdateItems(updateItems.add(home.id))
        }}
      />
    )
  }

  function renderProductShelf(home: Home) {
    return (
      <ProductShelf
        home={home}
        onUpdate={() => {
          setHomes(homes)
          setUpdateItems(updateItems.add(home.id))
        }}
      />
    )
  }

  function renderProductShelfWithBanner(home: Home) {
    return (
      <ProductShelfWithBanner
        home={home}
        onUpdate={() => {
          setHomes(homes)
          setUpdateItems(updateItems.add(home.id))
        }}
        onUpdateImage={(newImage) => {
          setHomes(homes)
          setUpdateItems(updateItems.add(home.id))
          replaceNewImage((fileName) => {
            console.log('UPLOAD NEW FILE', fileName)
            home.metadata.banner = fileName
          }, newImage)
        }}
      />
    )
  }
  function renderSmarthintProductsRecomendation(home: Home) {
    return (
      <SmarthintProductsRecomendation
        home={home}
        onUpdate={() => {
          setHomes(homes)
          setUpdateItems(updateItems.add(home.id))
        }}
      />
    )
  }

  function renderStaticBanner(home: Home) {
    return (
      <StaticBannerComp
        home={home}
        onUpdate={(newImage) => {
          setHomes(homes)
          setUpdateItems(updateItems.add(home.id))
          replaceNewImage((fileName) => {
            if (home.metadata.staticBanner) {
              home.metadata.staticBanner[0].image = fileName
            }
          }, newImage)
        }}
      />
    )
  }

  function renderHomeItem(home: Home, index: number) {
    function getHomeView() {
      switch (home.type) {
        case HOME_TYPE_BUBBLES:
          return renderBubbles(home)
        case HOME_TYPE_CATEGORIES_BANNER:
          return renderCategoriesBanner(home)
        case HOME_TYPE_PROD_LIST:
          return renderProductList(home, index)
        case HOME_TYPE_BRANDS:
          return renderBrands(home)
        case HOME_TYPE_CAROUSEL:
          return renderCarousel(home)
        case HOME_TYPE_MID_CAROUSEL:
          return renderMidCarousel(home)
        case HOME_TYPE_PORTRAIT_CAROUSEL:
          return renderPortraitCarousel(home)
        case HOME_TYPE_COUNTDOWN:
          return renderCountDown(home)
        case HOME_TYPE_PROD_GRID_CAT:
        case HOME_TYPE_PROD_GRID:
          return renderProductGrid(home)
        case HOME_TYPE_COLLECTIONS:
          return renderCollections(home)
        case HOME_TYPE_DIVIDER:
          return renderDivider()
        case HOME_TYPE_FILTER_GRID:
          return renderFilterGrid(home)
        case HOME_TYPE_SHELF:
          return renderProductShelf(home)
        case HOME_TYPE_SHELF_WITH_BANNER:
          return renderProductShelfWithBanner(home)
        case HOME_TYPE_STATIC_BANNER:
          return renderStaticBanner(home)
        case HOME_TYPE_SMARTHINT_PRODUCT_RECOMENDATION:
          return renderSmarthintProductsRecomendation(home)
      }
    }

    function renderHandle(home: Home) {
      return (
        <div className="flex bg-white p-1 rounded-t-md border-b border-gray-100">
          <img src={DragHandle} className="flex-none w-6 h-6" />
          <p
            className="flex-none font-semibold ml-2 text-gray-700 cursor-text text-sm"
            onClick={() => {
              setShowChangeNameModal({ show: true, item: home })
            }}
          >
            {home.name}
          </p>
          <div className="flex flex-grow justify-end">
            <img
              src={DeleteIcon}
              className="w-6 h-6 flex-none cursor-pointer"
              onClick={() => {
                setShowDeleteModal({ show: true, item: home })
              }}
            />
          </div>
        </div>
      )
    }

    const animation =
      index == homes.length - 1 && animateLast ? 'animate-ping' : 'hidden'
    const innerPing = index == homes.length - 1 && animateLast ? '' : 'hidden'

    return (
      <Draggable key={home.id} draggableId={`${home.id}`} index={index}>
        {(provided) => (
          <div
            className={`mb-4 relative shadow rounded-md`}
            key={home.id}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div className="absolute right-2.5 -top-2.5">
              <span
                className={`absolute ${animation} inline-flex h-5 w-5 rounded-full bg-red-600 opacity-90 left-0`}
              />
              <span
                className={`absolute ${innerPing} inline-flex h-5 w-5 rounded-full bg-red-600 left-0`}
              />
            </div>
            <div {...provided.dragHandleProps}>{renderHandle(home)}</div>
            <div className="">{getHomeView()}</div>
          </div>
        )}
      </Draggable>
    )
  }

  function renderHeader() {
    return (
      <div className="flex flex-col">
        {renderComponentBar()}

        <button
          type="button"
          className="btn-primary mx-3 justify-center"
          onClick={() => saveChanges()}
        >
          {saving ? (
            <ReactLoading type="bars" color="#FFF" height={20} width={20} />
          ) : (
            <>Salvar alterações</>
          )}
        </button>
      </div>
    )
  }

  function renderComponentBar() {
    function changeNameIfExists(newHome: () => Home, nameIncrement = 1): Home {
      const home = newHome()
      const same = homes.find((h) => h.name == home.name)
      if (!same) {
        return home
      } else {
        const increment = home.name.replace(/\(\d+\)/, `(${nameIncrement})`)
        if (increment === home.name) {
          home.name = `${home.name} (${nameIncrement})`
        } else {
          home.name = increment
        }
        return changeNameIfExists(() => home, nameIncrement + 1)
      }
    }

    return (
      <div className="bg-white p-3 shadow-custom rounded-lg">
        <div className="px-2 py-3">
          <p className="text-primary font-semibold text-xs whitespace-nowrap">
            BANNERS
          </p>
          <p className="text-gray-400 text-xs whitespace-nowrap">
            Carrosséis e Imagens
          </p>
        </div>
        <ul>
          {availableHomeComponents.map((c) => {
            if (c.group === 'banners')
              return (
                <NewComponentComp
                  key={c.type}
                  component={c}
                  onClick={(component: NewComponent) => {
                    const newHome = defaultHomes.get(component.type)
                    if (!newHome) return
                    const newHomeItem = changeNameIfExists(newHome)
                    homes.push(newHomeItem)
                    setHomes(homes)
                    setUpdateItems(updateItems.add(newHomeItem.id))
                    setTimeout(
                      () =>
                        scrollTo({
                          left: 0,
                          top: document.body.scrollHeight,
                          behavior: 'smooth'
                        }),
                      50
                    )
                    setAnimateLast(true)
                    setTimeout(() => setAnimateLast(false), 5000)
                  }}
                />
              )
          })}
        </ul>
        <div className="px-2 py-3">
          <p className="text-primary font-semibold text-xs whitespace-nowrap">
            ROLAGEM HORIZONTAL
          </p>
          <p className="text-gray-400 text-xs whitespace-nowrap">
            Componentes de Rolagem Horizontal
          </p>
        </div>
        <ul>
          {availableHomeComponents.map((c) => {
            if (c.group === 'scrolling')
              return (
                <NewComponentComp
                  key={c.type}
                  component={c}
                  onClick={(component: NewComponent) => {
                    const newHome = defaultHomes.get(component.type)
                    if (!newHome) return
                    const newHomeItem = changeNameIfExists(newHome)
                    homes.push(newHomeItem)
                    setHomes(homes)
                    setUpdateItems(updateItems.add(newHomeItem.id))
                    setTimeout(
                      () =>
                        scrollTo({
                          left: 0,
                          top: document.body.scrollHeight,
                          behavior: 'smooth'
                        }),
                      50
                    )
                    setAnimateLast(true)
                    setTimeout(() => setAnimateLast(false), 5000)
                  }}
                />
              )
          })}
        </ul>
        <div className="px-2 py-3">
          <p className="text-primary font-semibold text-xs whitespace-nowrap">
            PRODUTOS
          </p>
          <p className="text-gray-400 text-xs whitespace-nowrap">
            Componentes de Produtos
          </p>
        </div>
        <ul>
          {availableHomeComponents.map((c) => {
            if (c.group === 'products')
              return (
                <NewComponentComp
                  key={c.type}
                  component={c}
                  onClick={(component: NewComponent) => {
                    const newHome = defaultHomes.get(component.type)
                    if (!newHome) return
                    const newHomeItem = changeNameIfExists(newHome)
                    homes.push(newHomeItem)
                    setHomes(homes)
                    setUpdateItems(updateItems.add(newHomeItem.id))
                    setTimeout(
                      () =>
                        scrollTo({
                          left: 0,
                          top: document.body.scrollHeight,
                          behavior: 'smooth'
                        }),
                      50
                    )
                    setAnimateLast(true)
                    setTimeout(() => setAnimateLast(false), 5000)
                  }}
                />
              )
          })}
        </ul>
        <div className="px-2 py-3">
          <p className="text-primary font-semibold text-xs whitespace-nowrap">
            FILTROS
          </p>
          <p className="text-gray-400 text-xs whitespace-nowrap">
            Componentes de Filtros
          </p>
        </div>
        <ul>
          {availableHomeComponents.map((c) => {
            if (c.group === 'filters')
              return (
                <NewComponentComp
                  key={c.type}
                  component={c}
                  onClick={(component: NewComponent) => {
                    const newHome = defaultHomes.get(component.type)
                    if (!newHome) return
                    const newHomeItem = changeNameIfExists(newHome)
                    homes.push(newHomeItem)
                    setHomes(homes)
                    setUpdateItems(updateItems.add(newHomeItem.id))
                    setTimeout(
                      () =>
                        scrollTo({
                          left: 0,
                          top: document.body.scrollHeight,
                          behavior: 'smooth'
                        }),
                      50
                    )
                    setAnimateLast(true)
                    setTimeout(() => setAnimateLast(false), 5000)
                  }}
                />
              )
          })}
        </ul>
        <div className="px-2 py-3">
          <p className="text-primary font-semibold text-xs whitespace-nowrap">
            ESTÁTICOS
          </p>
          <p className="text-gray-400 text-xs whitespace-nowrap">
            Componentes Gerais
          </p>
        </div>
        <ul>
          {availableHomeComponents.map((c) => {
            if (c.group === 'statics')
              return (
                <NewComponentComp
                  key={c.type}
                  component={c}
                  onClick={(component: NewComponent) => {
                    const newHome = defaultHomes.get(component.type)
                    if (!newHome) return
                    const newHomeItem = changeNameIfExists(newHome)
                    homes.push(newHomeItem)
                    setHomes(homes)
                    setUpdateItems(updateItems.add(newHomeItem.id))
                    setTimeout(
                      () =>
                        scrollTo({
                          left: 0,
                          top: document.body.scrollHeight,
                          behavior: 'smooth'
                        }),
                      50
                    )
                    setAnimateLast(true)
                    setTimeout(() => setAnimateLast(false), 5000)
                  }}
                />
              )
          })}
        </ul>
        <div className="px-2 py-3">
          <p className="text-secondary font-semibold text-xs whitespace-nowrap">
            INTEGRAÇÕES (SMARTHINT)
          </p>
          <p className="text-gray-400 text-xs whitespace-nowrap">
            Integração com a SmartHint
          </p>
        </div>
        <ul>
          {availableHomeComponents.map((c) => {
            if (c.group === 'smarthint')
              return (
                <NewComponentComp
                  key={c.type}
                  component={c}
                  onClick={(component: NewComponent) => {
                    const newHome = defaultHomes.get(component.type)
                    if (!newHome) return
                    const newHomeItem = changeNameIfExists(newHome)
                    homes.push(newHomeItem)
                    setHomes(homes)
                    setUpdateItems(updateItems.add(newHomeItem.id))
                    setTimeout(
                      () =>
                        scrollTo({
                          left: 0,
                          top: document.body.scrollHeight,
                          behavior: 'smooth'
                        }),
                      50
                    )
                    setAnimateLast(true)
                    setTimeout(() => setAnimateLast(false), 5000)
                  }}
                />
              )
          })}
        </ul>
        <div className="px-2 py-3">
          <p className="text-secondary font-semibold text-xs whitespace-nowrap">
            INTEGRAÇÕES (WORDPRESS)
          </p>
          <p className="text-gray-400 text-xs whitespace-nowrap">
            Integração com Wordpress
          </p>
        </div>
        <ul>
          <li className="bg-gray-50 rounded-lg shadow-sm mb-2">
            <button className="text-gray-400 font-medium text-sm flex px-4 py-3 whitespace-nowrap cursor-not-allowed">
              Em desenvolvimento
            </button>
          </li>
          {availableHomeComponents.map((c) => {
            if (c.group === 'wordpress')
              return (
                <NewComponentComp
                  key={c.type}
                  component={c}
                  onClick={(component: NewComponent) => {
                    const newHome = defaultHomes.get(component.type)
                    if (!newHome) return
                    const newHomeItem = changeNameIfExists(newHome)
                    homes.push(newHomeItem)
                    setHomes(homes)
                    setUpdateItems(updateItems.add(newHomeItem.id))
                    setTimeout(
                      () =>
                        scrollTo({
                          left: 0,
                          top: document.body.scrollHeight,
                          behavior: 'smooth'
                        }),
                      50
                    )
                    setAnimateLast(true)
                    setTimeout(() => setAnimateLast(false), 5000)
                  }}
                />
              )
          })}
        </ul>
      </div>
    )
  }

  function replaceNewImage(
    callback: (fileName: string) => void,
    newImage?: File,
    key?: string
  ) {
    if (!newImage) return
    const fileEntry = newImages.find((fe) => fe.key == key)
    fileEntry
      ? (fileEntry.newImage = newImage)
      : newImages.push({
          key: key ?? '',
          newImage,
          uploadCallack: callback
        })
    setNewImages(newImages)
  }

  return (
    <Layout>
      <Heading
        title="Personalização da Home"
        subtitle="Personalize a tela inicial do seu aplicativo."
      />
      <section className="w-full mx-auto flex gap-8 relative">
        <div className="sticky top-0 mx-auto w-auto">
          <div className="bg-white shadow overflow-hidden sm:rounded-t-lg">
            {renderHeader()}
          </div>
        </div>
        <div className="w-full mx-auto">
          <div className=" overflow-hidden sm:rounded-b-lg">
            <div className="">
              <dl>
                <DragDropContext onDragEnd={handleDrop}>
                  <Droppable droppableId="list-container">
                    {(provided) => (
                      <div
                        className="col-span-1 overflow-hidden"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {!loaded ? (
                          <div className="flex justify-center w-full">
                            <ReactLoading
                              type="spinningBubbles"
                              color="rgba(0, 0, 0, var(--tw-border-opacity))"
                              height={50}
                              width={50}
                            />
                          </div>
                        ) : (
                          homes.map((h, indext) => renderHomeItem(h, indext))
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </dl>
            </div>
          </div>
        </div>
        <ConfirmModal
          isOpen={showDeleteModal.show}
          message="Tem certeza que deseja excluir este item da home?"
          negativeText="Não"
          positiveText="Sim"
          onNegative={() => {
            setShowDeleteModal({ show: false, item: undefined })
          }}
          onPositive={handleDeleteHome}
        />
        <ChangeNameModal
          isOpen={showChangeNameModal.show}
          onCancel={() => {
            setShowChangeNameModal({ show: false, item: undefined })
          }}
          onChange={(newName) => {
            if (!showChangeNameModal.item) return
            const hms = [...homes]
            const index = hms.indexOf(showChangeNameModal.item)
            hms[index].name = newName
            setHomes(hms)
            setShowChangeNameModal({ show: false, item: undefined })
            setUpdateItems(updateItems.add(hms[index].id))
          }}
          currentText={showChangeNameModal.item?.name ?? ''}
        />
      </section>
    </Layout>
  )
}
