import styled from 'styled-components'

export const StoreImage = styled.img`
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 10px;
  margin-bottom: 10px;
`
export const InfosContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
export const StoreName = styled.p`
  font-weight: bold;
  font-size: 18px;
`
export const StoreAddress = styled.span``
