import { Filter } from 'pages/custom-home/homeTypes'
import { ChangeEvent, useState } from 'react'
import Modal from 'react-modal'
import { centeredModal } from 'utils/modalStyles'
import { OrderTypes } from 'utils/orderTypes'

type ModalProps = {
  isOpen: boolean
  currentFilter?: Filter
  onCancel: () => void
  onSave: (filter: Filter) => void
}

export const FilterModal = ({
  isOpen,
  currentFilter,
  onCancel,
  onSave
}: ModalProps) => {
  const [filter, setFilter] = useState<Filter>({})

  return (
    <Modal
      isOpen={isOpen}
      style={centeredModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        onCancel()
      }}
      onAfterOpen={() => {
        setFilter({ ...currentFilter })
      }}
    >
      <div className="flex flex-col items-center">
        <div className="grid grid-cols-6 gap-6 px-4 py-5">
          <h3 className="col-span-12 md:col-span-1 w-full text-center text-lg leading-6 mt-2 font-medium text-gray-900 pb-2 pt-2">
            Alterar Filtros
          </h3>
          <div className="col-span-12 md:col-span-6">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Título
            </label>
            <input
              type="text"
              name="title"
              id="title"
              value={filter.title}
              onChange={(e) => setFilter({ ...filter, title: e.target.value })}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="col-span-12 md:col-span-3">
            <label
              htmlFor="orderSelect"
              className="block text-sm font-medium text-gray-700"
            >
              Ordem
            </label>
            <select
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setFilter({ ...filter, order: e.target.value })
              }
            >
              {Object.keys(OrderTypes).map((key: string) => (
                <option key={key} value={key} selected={key === filter.order}>
                  {OrderTypes[key]}
                </option>
              ))}
            </select>
          </div>
          <div className="col-span-12 md:col-span-3">
            <label
              htmlFor="categoryId"
              className="block text-sm font-medium text-gray-700"
            >
              Id da Categoria
            </label>
            <input
              type="text"
              name="categoryId"
              id="categoryId"
              value={filter.category}
              onChange={(e) =>
                setFilter({ ...filter, category: e.target.value })
              }
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="col-span-12 md:col-span-3">
            <label
              htmlFor="brandId"
              className="block text-sm font-medium text-gray-700"
            >
              Id da Marca
            </label>
            <input
              type="text"
              name="brandId"
              id="brandId"
              value={filter.brand}
              onChange={(e) => setFilter({ ...filter, brand: e.target.value })}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="col-span-12 md:col-span-3">
            <label
              htmlFor="collectionsIds"
              className="block text-sm font-medium text-gray-700"
            >
              Ids das Coleções (Separados por vírgula)
            </label>
            <input
              type="text"
              name="collectionsIds"
              id="collectionsIds"
              value={filter.collectionsIds}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  collectionsIds: e.target.value.split(',').map((x) => x.trim())
                })
              }
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="col-span-12 md:col-span-3">
            <label
              htmlFor="productsIds"
              className="block text-sm font-medium text-gray-700"
            >
              Ids de Produtos (Separados por vírgula)
            </label>
            <input
              type="text"
              name="productsIds"
              id="productsIds"
              value={filter.productsIds}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  productsIds: e.target.value.split(',').map((x) => x.trim())
                })
              }
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="col-span-12 md:col-span-3">
            <label
              htmlFor="skusIds"
              className="block text-sm font-medium text-gray-700"
            >
              Ids de Skus (Separados por vírgula)
            </label>
            <input
              type="text"
              name="skusIds"
              id="skusIds"
              value={filter.skusIds}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  skusIds: e.target.value.split(',').map((x) => x.trim())
                })
              }
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div className="pt-4 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              onSave(filter)
            }}
          >
            Salvar
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onCancel()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}
