import { CategoryBanner } from 'pages/custom-home/homeTypes'
import { ChangeEvent, useRef, useState } from 'react'
import Modal from 'react-modal'
import { centeredModal } from 'utils/modalStyles'
import PlaceHolder from '../../../../../../assets/image-placeholder.png'

type ModalProps = {
  categoryBanner?: CategoryBanner
  selectedImage?: string
  isOpen: boolean
  onClose: () => void
  onSave: (
    oldId: string,
    categoryBanner: CategoryBanner,
    newImage?: { file: File; b64: string }
  ) => void
}

export const CategoryBannerModal = ({
  isOpen,
  onClose,
  categoryBanner,
  selectedImage,
  onSave
}: ModalProps) => {
  const [categoryBannerId, setCategoryBannerId] = useState<string>(
    categoryBanner ? categoryBanner.id : ''
  )
  const [oldId, setOldId] = useState<string>(
    categoryBanner ? categoryBanner.id : ''
  )
  const [imageAsFile, setImageAsFile] = useState<
    { file: File; b64: string } | undefined
  >()
  const [previousImage, setPreviousImage] = useState<string | undefined>(
    selectedImage ?? undefined
  )

  const inputFile = useRef<HTMLInputElement | null>(null)

  const handleImageAsFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : undefined
    if (!file) return

    function getBase64(callback: (b64: string) => void) {
      if (!file) return callback('')
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => callback(reader.result?.toString() ?? '')
      reader.onerror = () => callback('')
    }

    getBase64((b64: string) => setImageAsFile({ file, b64 }))
  }

  return (
    <Modal
      isOpen={isOpen}
      style={centeredModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        onClose()
      }}
      onAfterOpen={() => {
        setCategoryBannerId(categoryBanner ? categoryBanner.id : '')
        setOldId(categoryBanner ? categoryBanner.id : '')
        setPreviousImage(selectedImage)
      }}
      onAfterClose={() => {
        setCategoryBannerId('')
        setOldId('')
        setImageAsFile(undefined)
        setPreviousImage(undefined)
      }}
    >
      <div className="flex flex-col items-center">
        <div className="static">
          <input
            type="file"
            accept="image/png"
            onChange={handleImageAsFile}
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
          />
          <img
            onClick={() => {
              inputFile?.current?.click()
            }}
            className="absolute object-cover h-24 w-53 mb-4 opacity-0 hover:opacity-80 cursor-pointer"
            src={PlaceHolder}
          />
          <img
            className="object-cover h-24 w-53 mb-4"
            src={
              imageAsFile?.b64 ??
              previousImage ??
              categoryBanner?.image ??
              PlaceHolder
            }
          />
        </div>
        <input
          className="appearance-none mt-2 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
          defaultValue={categoryBannerId}
          pattern="[0-9]*"
          placeholder="Insira o ID da categoria"
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            setCategoryBannerId(
              e.target.validity.valid && e.target.value.length
                ? e.target.value
                : ''
            )
          }}
        />
        <div className="pt-4 border-t border-gray-200 mt-5 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              if (!categoryBannerId.length) return
              if (!imageAsFile && !categoryBanner) return
              onSave(
                oldId,
                categoryBanner
                  ? {
                      image: categoryBanner?.image,
                      id: categoryBannerId
                    }
                  : {
                      image: '',
                      id: categoryBannerId
                    },
                imageAsFile
              )
            }}
          >
            Salvar
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onClose()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}
