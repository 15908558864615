import { Home } from 'pages/custom-home/homeTypes'
import { ChangeEvent, useRef, useState } from 'react'
import PlaceHolder from '../../../../assets/image-placeholder.png'

type StaticBannerProps = {
  home: Home
  onUpdate: (newImage: File) => void
}

export const StaticBannerComp = ({ home, onUpdate }: StaticBannerProps) => {
  const [imageAsFile, setImageAsFile] = useState<
    { file: File; b64: string } | undefined
  >()
  const inputFile = useRef<HTMLInputElement | null>(null)

  const handleImageAsFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : undefined
    if (!file) return

    function getBase64(callback: (b64: string) => void) {
      if (!file) return callback('')
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => callback(reader.result?.toString() ?? '')
      reader.onerror = () => callback('')
    }

    getBase64((b64: string) => {
      setImageAsFile({ file, b64 })
      onUpdate(file)
    })
  }

  return (
    <div className="p-4 bg-white rounded-b-md">
      <div className="flex lg:flex-row flex-col gap-5 justify-center">
        <input
          type="file"
          accept="image/png"
          onChange={handleImageAsFile}
          id="file"
          ref={inputFile}
          style={{ display: 'none' }}
        />
        <div className="relative lg:w-5/12 h-72">
          <img
            onClick={() => {
              inputFile?.current?.click()
            }}
            src={PlaceHolder}
            className="absolute hover:opacity-80 opacity-0 object-cover w-full h-full cursor-pointer"
          />
          <img
            src={imageAsFile?.b64 ?? home.metadata.staticBanner![0]?.image}
            className="object-cover w-full h-full"
          />
        </div>
      </div>
    </div>
  )
}
