import { NewComponent } from './componentTypes'

type NewComponentProps = {
  component: NewComponent
  onClick: (component: NewComponent) => void
}

export const NewComponentComp = ({ component, onClick }: NewComponentProps) => {
  return (
    <li className="bg-gray-100 rounded-lg shadow-sm mb-2 hover:bg-gray-200 transition-all">
      <button
        onClick={() => onClick(component)}
        className="text-gray-600 font-medium text-sm flex px-4 py-3 whitespace-nowrap w-full"
      >
        {component.name}
      </button>
    </li>
  )
}
