import { Buffer } from 'buffer'
import { auth, firestore } from 'config/firebase'
import { collection, getDocs, query, where } from 'firebase/firestore'

async function encodeAuthHeader() {
  const id = auth.currentUser?.uid ?? ''
  const q = query(collection(firestore, 'userApps'), where('ownerId', '==', id))
  const snapShot = await getDocs(q)
  let value = ''
  snapShot.forEach((doc) => {
    const apps = doc.data()['apps'] ?? []
    const selectedApp = apps.find(
      (app: { appId: string; selected: boolean }) => app.selected
    )
    value = Buffer.from(`${selectedApp.appId}:${id}`).toString('base64')
  })
  return value
}

export const generateRequestConfig = async () => {
  return {
    headers: { AUTH: await encodeAuthHeader() }
  }
}
