import { Header, Sidebar } from 'components'

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="h-screen flex">
      <Sidebar />
      <main className="flex flex-col flex-1 min-h-full min-w-0 relative overflow-auto bg-gray-100">
        <Header />
        <div className="p-8">{children}</div>
      </main>
    </div>
  )
}
