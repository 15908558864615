import Modal from 'react-modal'
import { useState, useEffect, ChangeEvent } from 'react'
import ReactLoading from 'react-loading'

import { Store } from '../../storeTypes'

import { upload } from 'services/storage'
import { api } from 'services/api'

import { generateRequestConfig } from 'utils/requestConfig'

import * as Ui from './style'

type ModalProps = {
  isOpen: boolean
  onClose: (reload?: boolean) => void
  index: number
  editingStore?: Store
}
export const StoreModal = ({
  isOpen,
  onClose,
  index,
  editingStore
}: ModalProps) => {
  const [storeName, setStoreName] = useState('')
  const [storeAddress, setStoreAddress] = useState('')
  const [storeSpedient, setStoreSpedient] = useState('')
  const [storePhone, setStorePhone] = useState('')
  const [storeLat, setStoreLat] = useState('')
  const [storeLong, setStoreLong] = useState('')

  const [loading, setLoading] = useState(false)

  const [imageAsFile, setImageAsFile] = useState<
    { file: File; b64: string } | undefined
  >()

  useEffect(() => {
    if (editingStore) {
      setStoreName(editingStore.title)
      setStoreAddress(editingStore.address)
      setStoreSpedient(editingStore.spedient)
      setStorePhone(editingStore.phone)
      setStoreLat(`${editingStore.coord[0]}`)
      setStoreLong(`${editingStore.coord[1]}`)
    } else {
      resetStates()
    }
  }, [editingStore])

  const handleImageAsFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : undefined
    if (!file) return

    function getBase64(callback: (b64: string) => void) {
      if (!file) return callback('')
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => callback(reader.result?.toString() ?? '')
      reader.onerror = () => callback('')
    }

    getBase64((b64: string) => {
      setImageAsFile({ file, b64 })
    })
  }

  const resetStates = () => {
    setStoreName('')
    setStoreAddress('')
    setStoreSpedient('')
    setStorePhone('')
    setStoreLat('')
    setStoreLong('')
    setImageAsFile(undefined)
    console.log('RESET')
  }

  const saveStore = async () => {
    if (!storeName.trim()) {
      return alert('Informe o Nome da Loja')
    } else if (!storeAddress.trim()) {
      return alert('Informe o Endereço da Loja')
    } else if (!storeSpedient.trim()) {
      return alert('Informe o Horário de Funcionamento')
    } else if (!storePhone.trim()) {
      return alert('Informe o Telefone da Loja')
    } else if (!storeLat) {
      return alert('Informe a Latitude')
    } else if (isNaN(parseFloat(storeLat))) {
      return alert('A Latitude deve conter apenas números')
    } else if (!storeLong) {
      return alert('Informe a Longitude')
    } else if (isNaN(parseFloat(storeLong))) {
      return alert('A Longitude deve conter apenas números')
    } else if (!imageAsFile && !editingStore) {
      return alert('Selecione uma imagem')
    }

    try {
      setLoading(true)

      const image = imageAsFile ? await upload(imageAsFile.file) : undefined

      const data = {
        title: storeName,
        image: image,
        address: storeAddress,
        spedient: storeSpedient,
        phone: storePhone,
        coord: [parseFloat(storeLat), parseFloat(storeLong)],
        order: index + 1
      }

      if (editingStore)
        await api.put(
          '/v1/stores/' + editingStore._id,
          data,
          await generateRequestConfig()
        )
      else await api.post('/v1/stores', data, await generateRequestConfig())

      setLoading(false)
      resetStates()
      onClose(true)
    } catch (err) {
      setLoading(false)
      alert('Erro ao incluir a Loja')
      console.log(err)
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <div className="w-full h-full bg-blue flex flex-col">
        <div className="pb-4 border-b border-gray-200 mb-5 flex justify-end">
          <button
            className="text-lg leading-6 font-medium text-gray-900"
            onClick={() => onClose()}
          >
            Fechar
          </button>
        </div>
        <div className="flex flex-col flex-1">
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-name" className="mb-4 font-bold">
              Nome da Loja
            </Ui.FormTitle>
            <input
              id="store-name"
              name="storeName"
              type="text"
              required
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o nome da Loja"
            />
          </div>
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-address" className="mb-4 font-bold">
              Endereço
            </Ui.FormTitle>
            <input
              id="store-address"
              name="storeAddress"
              type="text"
              required
              value={storeAddress}
              onChange={(e) => setStoreAddress(e.target.value)}
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o endereço"
            />
          </div>
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-spedient" className="mb-4 font-bold">
              Horário de Funcionamento
            </Ui.FormTitle>
            <textarea
              id="store-spedient"
              name="storeSpedient"
              required
              value={storeSpedient}
              onChange={(e) => setStoreSpedient(e.target.value)}
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o Horário de Funcionamento"
            />
          </div>
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-phone" className="mb-4 font-bold">
              Telefone
            </Ui.FormTitle>
            <input
              id="store-phone"
              name="storePhone"
              type="text"
              required
              value={storePhone}
              onChange={(e) => setStorePhone(e.target.value)}
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o telefone da Loja"
            />
          </div>
          <div className="flex flex-row">
            <div className="rounded shadow-sm -space-y-px mb-5">
              <Ui.FormTitle htmlFor="store-latitude" className="mb-4 font-bold">
                Latitude
              </Ui.FormTitle>
              <input
                id="store-latitude"
                name="storeLatitude"
                type="number"
                required
                value={storeLat}
                onChange={(e) => setStoreLat(e.target.value)}
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Informe o nome da Loja"
              />
            </div>
            <div className="rounded shadow-sm -space-y-px mb-5 ml-4">
              <Ui.FormTitle
                htmlFor="store-longitude"
                className="mb-4 font-bold"
              >
                Longitude
              </Ui.FormTitle>
              <input
                id="store-longitude"
                name="storeLongitude"
                type="number"
                required
                value={storeLong}
                onChange={(e) => setStoreLong(e.target.value)}
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Informe o nome da Loja"
              />
            </div>
          </div>

          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-phone" className="mb-4 font-bold">
              Telefone
            </Ui.FormTitle>
            <input
              id="store-image"
              name="storeImage"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              required
              onChange={handleImageAsFile}
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o telefone da Loja"
            />
          </div>
        </div>
        <div className="pt-4 border-t border-gray-200 mt-5 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => saveStore()}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading type="bars" color="#fff" height={20} width={20} />
            ) : (
              'Salvar'
            )}
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onClose()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}
