import moment from 'moment'
import { CountDown, Home } from 'pages/custom-home/homeTypes'
import { ChangeEvent, useRef, useState } from 'react'
import DateTimePicker from 'react-datetime-picker'
import PlaceHolder from '../../../../assets/image-placeholder.png'
import { FilterModal } from '../filter-modal'
import { FilterPreivew } from '../filter-preview'

type CountDownProps = {
  home: Home
  onUpdate: (
    countDown: CountDown,
    newImage?: { file: File; b64: string }
  ) => void
}

export const CountDownComp = ({ home, onUpdate }: CountDownProps) => {
  const [element, setElement] = useState<CountDown>(home.metadata.countDown![0])
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false)
  const [imageAsFile, setImageAsFile] = useState<
    { file: File; b64: string } | undefined
  >()

  const inputFile = useRef<HTMLInputElement | null>(null)

  const handleImageAsFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : undefined
    if (!file) return

    function getBase64(callback: (b64: string) => void) {
      if (!file) return callback('')
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => callback(reader.result?.toString() ?? '')
      reader.onerror = () => callback('')
    }

    getBase64((b64: string) => {
      setImageAsFile({ file, b64 })
      onUpdate(element, { file, b64 })
    })
  }

  return (
    <div className="p-4 bg-white rounded-b-md">
      <div className="flex lg:flex-row flex-col gap-5">
        <input
          type="file"
          accept="image/png"
          onChange={handleImageAsFile}
          id="file"
          ref={inputFile}
          style={{ display: 'none' }}
        />
        <div className="relative lg:w-1/2">
          <img
            onClick={() => {
              inputFile?.current?.click()
            }}
            src={PlaceHolder}
            className="absolute hover:opacity-80 opacity-0 object-cover w-full h-full cursor-pointer"
          />
          <img
            src={imageAsFile?.b64 ?? home.metadata.countDown![0].image}
            className="object-cover w-full h-full"
          />
        </div>
        <div className="flex flex-col gap-4 flex-1">
          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Título
            </label>
            <input
              type="text"
              name="title"
              id="title"
              value={element.title}
              onChange={(e) => {
                const upd = {
                  ...element,
                  title: e.target.value
                }
                setElement(upd)
                onUpdate(upd, undefined)
              }}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div className="flex gap-4 md:flex-row lg:flex-row flex-col">
            <FilterPreivew
              filter={element.filter}
              onEditClick={() => {
                setFilterOpen(true)
              }}
            />
            <div className="flex-1 flex flex-col gap-4">
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mensagem
                </label>
                <input
                  type="text"
                  name="message"
                  id="message"
                  value={element.endMessage}
                  onChange={(e) => {
                    const upd = {
                      ...element,
                      endMessage: e.target.value
                    }
                    setElement(upd)
                    onUpdate(upd, undefined)
                  }}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Data Fim Contagem
                </label>
                <DateTimePicker
                  className="mt-1 w-full"
                  onChange={(date) => {
                    if (!date) return
                    const upd = {
                      ...element,
                      endTime: moment(date).format('yyyy-MM-DDTHH:mm')
                    }
                    setElement(upd)
                    onUpdate(upd, undefined)
                  }}
                  value={new Date(element.endTime)}
                />
              </div>
              <b>Programação</b>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Programação Inicio
                </label>
                <DateTimePicker
                  className="mt-1 w-full"
                  onChange={(date) => {
                    let dt = undefined
                    if (date) dt = moment(date).format('yyyy-MM-DDTHH:mm')
                    const upd = {
                      ...element,
                      startDate: dt
                    }
                    setElement(upd)
                    onUpdate(upd, undefined)
                  }}
                  value={
                    element.startDate
                      ? new Date(element.startDate || '')
                      : undefined
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Programação Fim
                </label>
                <DateTimePicker
                  className="mt-1 w-full"
                  onChange={(date) => {
                    let dt = undefined
                    if (date) dt = moment(date).format('yyyy-MM-DDTHH:mm')
                    const upd = {
                      ...element,
                      endDate: dt
                    }
                    setElement(upd)
                    onUpdate(upd, undefined)
                  }}
                  value={
                    element.endDate
                      ? new Date(element.endDate || '')
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
          <FilterModal
            isOpen={isFilterOpen}
            onCancel={() => {
              setFilterOpen(false)
            }}
            currentFilter={element.filter}
            onSave={(filter) => {
              const upd = {
                ...element,
                filter
              }
              setElement(upd)
              setFilterOpen(false)
              onUpdate(upd, undefined)
            }}
          />
        </div>
      </div>
    </div>
  )
}
