import { CepRangeModal } from 'pages/custom-home/components/cep-range/cep-range-modal'
import { CepRangePreview } from 'pages/custom-home/components/cep-range/cep-range-preview'
import { FilterModal } from 'pages/custom-home/components/filter-modal'
import { FilterPreivew } from 'pages/custom-home/components/filter-preview'
import { Carousel, CepRange, Filter } from 'pages/custom-home/homeTypes'
import { ChangeEvent, useRef, useState } from 'react'
import Modal from 'react-modal'
import { centeredModal } from 'utils/modalStyles'
import PlaceHolder from '../../../../../../assets/image-placeholder.png'

type ModalProps = {
  carousel?: Carousel
  selectedImage?: string
  isOpen: boolean
  onClose: () => void
  onSave: (carousel: Carousel, newImage?: { file: File; b64: string }) => void
}

export const CarouselModal = ({
  isOpen,
  onClose,
  carousel,
  selectedImage,
  onSave
}: ModalProps) => {
  const [carouselFilter, setCarouselFilter] = useState<Filter>(
    carousel?.filter ?? {}
  )
  const [cepRanges, setCepRanges] = useState<CepRange[]>(
    carousel?.cepRanges ?? []
  )
  const [imageAsFile, setImageAsFile] = useState<
    { file: File; b64: string } | undefined
  >()
  const [previousImage, setPreviousImage] = useState<string | undefined>(
    selectedImage ?? undefined
  )
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false)
  const [isCepRangeOpen, setCepRangeOpen] = useState<boolean>(false)
  const [link, setLink] = useState<string>('')

  const inputFile = useRef<HTMLInputElement | null>(null)

  const handleImageAsFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : undefined
    if (!file) return

    function getBase64(callback: (b64: string) => void) {
      if (!file) return callback('')
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => callback(reader.result?.toString() ?? '')
      reader.onerror = () => callback('')
    }

    getBase64((b64: string) => setImageAsFile({ file, b64 }))
  }

  return (
    <Modal
      isOpen={isOpen}
      style={centeredModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        onClose()
      }}
      onAfterOpen={() => {
        setPreviousImage(selectedImage)
        setCarouselFilter(carousel?.filter ?? {})
        setCepRanges(carousel?.cepRanges ?? [])
        setLink(carousel?.link ?? '')
      }}
      onAfterClose={() => {
        setImageAsFile(undefined)
        setPreviousImage(undefined)
        setCarouselFilter({})
        setCepRanges([])
        setLink('')
      }}
    >
      <div className="flex flex-col items-center">
        <div className="static">
          <input
            type="file"
            accept="image/png"
            onChange={handleImageAsFile}
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
          />
          <img
            onClick={() => {
              inputFile?.current?.click()
            }}
            className="absolute object-cover h-24 w-48 mb-4 opacity-0 hover:opacity-80 cursor-pointer"
            src={PlaceHolder}
          />
          <img
            className="object-cover h-24 w-48 mb-4"
            src={
              imageAsFile?.b64 ??
              previousImage ??
              carousel?.image ??
              PlaceHolder
            }
          />
        </div>
        <div className="mb-5">
          <CepRangePreview
            cepRanges={cepRanges}
            onEdit={() => {
              setCepRangeOpen(true)
            }}
          />
        </div>
        <div className="p-3 mb-5 border-dashed border-2 border-gray-400 rounded-md relative w-60">
          <label
            htmlFor="link"
            className="block font-medium text-left w-full text-black pb-1 mb-3 border-b"
          >
            Link
          </label>
          <input
            type="text"
            name="link"
            id="link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <FilterPreivew
          filter={carouselFilter}
          onEditClick={() => {
            setFilterOpen(true)
          }}
        />
        <div className="pt-4 border-t border-gray-200 mt-5 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              onSave(
                carousel
                  ? {
                      ...carousel,
                      filter: carouselFilter,
                      cepRanges: cepRanges,
                      link: link
                    }
                  : {
                      image: '',
                      cepRanges: [],
                      filter: carouselFilter,
                      link: link
                    },
                imageAsFile
              )
            }}
          >
            Salvar
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onClose()}
          >
            Cancelar
          </button>
        </div>
      </div>
      <FilterModal
        isOpen={isFilterOpen}
        currentFilter={carouselFilter}
        onCancel={() => {
          setFilterOpen(false)
        }}
        onSave={(filter: Filter) => {
          setCarouselFilter(filter)
          setFilterOpen(false)
        }}
      />
      <CepRangeModal
        isOpen={isCepRangeOpen}
        currentCepRanges={cepRanges}
        onCancel={() => {
          setCepRangeOpen(false)
        }}
        onSave={(ranges: CepRange[]) => {
          setCepRanges(ranges)
          setCepRangeOpen(false)
        }}
      />
    </Modal>
  )
}
