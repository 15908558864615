import { ColorPickerModal } from 'pages/custom-home/components/color-modal'
import { FilterModal } from 'pages/custom-home/components/filter-modal'
import { FilterPreivew } from 'pages/custom-home/components/filter-preview'
import { Filter, FilterGridFilter } from 'pages/custom-home/homeTypes'
import { useState } from 'react'
import Modal from 'react-modal'
import { centeredModal } from 'utils/modalStyles'
import { FilterGridFilterPreview } from '../preview'

type ModalProps = {
  filterGridFilter: FilterGridFilter
  isOpen: boolean
  onClose: () => void
  onSave: (filterGridFilter: FilterGridFilter) => void
}

export const FilterGridFilterModal = ({
  isOpen,
  onClose,
  filterGridFilter,
  onSave
}: ModalProps) => {
  const [filter, setFilter] = useState<Filter>(filterGridFilter?.filter)
  const [bgColor, setBgColor] = useState<string>(filterGridFilter?.bgColor)
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false)
  const [isColorOpen, setColorOpen] = useState<boolean>(false)
  const [id, setId] = useState<string>(filterGridFilter.id)

  return (
    <Modal
      isOpen={isOpen}
      style={centeredModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        onClose()
      }}
      onAfterOpen={() => {
        setFilter(filterGridFilter?.filter ?? {})
        setId(filterGridFilter ? filterGridFilter.id : '')
        setBgColor(filterGridFilter ? filterGridFilter.bgColor : '')
      }}
      onAfterClose={() => {
        setFilter({})
        setId('')
        setBgColor('')
      }}
    >
      <div className="flex flex-col">
        <h3 className="col-span-12 md:col-span-1 w-full text-lg leading-6 mt-2 font-medium text-gray-900">
          Detalhes do filtro
        </h3>
        <div className="border-t border-gray-200 mt-5 flex flex-col justify-center items-center">
          <div
            className="w-36 my-4"
            onClick={() => {
              setColorOpen(true)
            }}
          >
            {filterGridFilter ? (
              <FilterGridFilterPreview filter={filter} bgColor={bgColor} />
            ) : (
              <></>
            )}
          </div>
          <FilterPreivew
            filter={filter}
            onEditClick={() => {
              setFilterOpen(true)
            }}
          />
          <button
            type="button"
            className="inline-flex justify-center w-full my-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              onSave({
                filter,
                id,
                bgColor
              })
            }}
          >
            Salvar
          </button>
          <button
            type="button"
            className="inline-flex justify-center w-full mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onClose()}
          >
            Cancelar
          </button>
        </div>
        <FilterModal
          isOpen={isFilterOpen}
          currentFilter={filterGridFilter?.filter}
          onCancel={() => {
            setFilterOpen(false)
          }}
          onSave={(filter: Filter) => {
            setFilter(filter)
            setFilterOpen(false)
          }}
        />
        <ColorPickerModal
          isOpen={isColorOpen}
          currentColor={bgColor}
          onCancel={() => {
            setColorOpen(false)
          }}
          onSave={(color) => {
            setColorOpen(false)
            setBgColor(color)
          }}
        />
      </div>
    </Modal>
  )
}
