import { useEffect, useState } from 'react'
import { useSearch } from 'react-location'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { api } from 'services/api'
import { generateRequestConfig } from 'utils/requestConfig'

import Layout from 'components/layout'
import { Category, Child } from './categoriesTypes'
import { CategoryModal } from './components/modal'
import Preview from './components/preview'

import { Heading } from 'components'
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'
import ReactLoading from 'react-loading'

export const SubCategoriesPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const search: any = useSearch()
  const [stores] = useState<Category[]>([])
  const [showModal, setShowModal] = useState(false)
  const [category, setCategory] = useState<Category>()
  const [subCategoryEditing, setSubCategoryEditing] = useState<Child>()
  const [loading, setLoading] = useState(false)
  const [lastIndex] = useState(0)

  useEffect(() => {
    console.log('DATA', search.category)
    setCategory(search.category)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function updateCategory(cat: Category) {
    try {
      setLoading(true)
      await api.put(
        '/v1/categories/' + cat._id + '/subcategories',
        { subcategories: cat.children },
        await generateRequestConfig()
      )
      setLoading(false)
    } catch (e) {
      setLoading(false)
      alert('Erro ao atualizar a Sub-Categoria')
      console.log(e)
    }
  }

  async function removeStore(index: number) {
    if (
      window.confirm(
        'Deseja excluir a loja: ' + category!.children[index].title + '?'
      )
    ) {
      const cat = { ...category! }
      cat.children.splice(index, 1)
      updateCategory(cat)
    }
  }

  const SortableItem = SortableElement(({ value: store }: { value: Child }) => (
    <div className="relative bg-white shadow w-full rounded-lg group p-3 hover:cursor-pointer focus:cursor-move">
      <p className="font-semibold mb-1">
        {store.id} - {store.title}
      </p>

      <p className="inline-flex justify-center py-1 text-sm text-gray-500">
        <span>Filhos: </span>
        <span className="font-semibold">{store.children?.length || 0}</span>
      </p>

      <button
        onClick={() => {
          setSubCategoryEditing(store)
          setShowModal(true)
        }}
        disabled={loading}
        title="Editar"
        className="opacity-0 group-hover:opacity-100 absolute text-gray-500 right-1 top-1 h-8 w-8 bg-gray-200 hover:bg-gray-300 flex items-center justify-center rounded-full transition-all"
      >
        <HiOutlinePencil />
      </button>
      <button
        onClick={() => removeStore(category?.children.indexOf(store) || 0)}
        disabled={loading}
        title="Excluir"
        className="opacity-0 group-hover:opacity-100 absolute text-red-600 right-10 top-1 h-8 w-8 bg-red-100 hover:bg-red-200 flex items-center justify-center rounded-full transition-all"
      >
        <HiOutlineTrash />
      </button>
    </div>
  ))

  const SortableList = SortableContainer(({ items }: { items: Child[] }) => {
    return (
      <div className="flex flex-col gap-y-3">
        {items.map((child: Child, index: number) => (
          <SortableItem key={`item-${child.id}`} index={index} value={child} />
        ))}
      </div>
    )
  })

  return (
    <Layout>
      <section className="w-full mx-auto">
        <Heading
          title={`Categoria - ${category?.title}`}
          subtitle={`Gerencia as sub-categorias da categoria ${category?.title}.`}
        />

        <div className="flex gap-8">
          <div className="w-1/3">
            <button
              type="button"
              className="btn-primary"
              onClick={() => setShowModal(true)}
              disabled={loading}
            >
              {loading ? (
                <ReactLoading
                  type="bars"
                  color="rgba(79, 70, 229, var(--tw-border-opacity))"
                  height={30}
                  width={30}
                />
              ) : (
                'Adicionar Sub-categoria'
              )}
            </button>

            <div className="border border-dashed border-gray-300 rounded-lg p-2">
              <SortableList
                items={category?.children || []}
                onSortEnd={(e) => {
                  if (!loading && e.newIndex != e.oldIndex) {
                    const cats = [...(category?.children || [])]
                    const item = cats.splice(e.oldIndex, 1)
                    cats.splice(e.newIndex, 0, item[0])
                    console.log('STRS', cats)
                    const cat = { ...category! }
                    cat.children = cats
                    updateCategory(cat)
                    setCategory(cat)
                  }
                }}
              />
            </div>
          </div>
          <div className="w-2/3">
            <div
              className="bg-white shadow overflow-auto sm:rounded-lg w-full my-4 md:w-1/3 md:mx-4 md:my-0 flex flex-row sm:hidden md:block flex flex-column"
              style={{ height: 896, width: 414 }}
            >
              <h3 className="mx-4 mt-5 text-lg leading-6 font-medium text-gray-900">
                Preview
              </h3>
              <div className="px-4">
                <Preview stores={stores} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <CategoryModal
        isOpen={showModal}
        onClose={(subcat, subCategory) => {
          const catUpdate = { ...category! }
          if (subcat) {
            const sub = catUpdate.children?.find((x) => x.id == subCategory?.id)

            if (sub) {
              sub.id = subcat.id
              sub.title = subcat.title
              sub.children = subcat.children
            } else {
              catUpdate.children?.push(subcat)
            }
            setCategory(catUpdate)
          }
          setShowModal(false)
          setSubCategoryEditing(undefined)
        }}
        index={lastIndex}
        subCategory={subCategoryEditing}
        category={category!}
      />
    </Layout>
  )
}
