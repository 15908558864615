import { ChangeEvent, useState } from 'react'
import Modal from 'react-modal'
import { centeredModal } from 'utils/modalStyles'

type ModalProps = {
  isOpen: boolean
  currentText: string
  onChange: (newName: string) => void
  onCancel: () => void
}

export const ChangeNameModal = ({
  isOpen,
  currentText,
  onChange,
  onCancel
}: ModalProps) => {
  const [name, setName] = useState<string>(currentText)

  return (
    <Modal
      isOpen={isOpen}
      style={centeredModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onAfterOpen={() => {
        setName(currentText)
      }}
      onAfterClose={() => {
        setName('')
      }}
    >
      <div className="flex flex-col items-center">
        <input
          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
          defaultValue={name}
          placeholder="Insira o nome da home"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value)
          }}
        />
        <div className="pt-4 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            onClick={() => {
              if (!name.length || name == currentText) {
                onCancel()
              } else {
                onChange(name)
              }
            }}
          >
            Salvar
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onCancel()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}
