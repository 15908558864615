import Modal from 'react-modal'
import { centeredModal } from 'utils/modalStyles'

type ModalProps = {
  isOpen: boolean
  message: string
  positiveText: string
  negativeText: string
  onNegative: () => void
  onPositive: () => void
}

export const ConfirmModal = ({
  isOpen,
  message,
  positiveText,
  negativeText,
  onNegative,
  onPositive
}: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      style={centeredModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        onNegative()
      }}
    >
      <div className="flex flex-col items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900 border-b border-gray-200 pb-5">
          {message}
        </h3>
        <div className="pt-4 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            onClick={() => {
              onPositive()
            }}
          >
            {positiveText}
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onNegative()}
          >
            {negativeText}
          </button>
        </div>
      </div>
    </Modal>
  )
}
