import { useState } from 'react'
import { SketchPicker } from 'react-color'
import Modal from 'react-modal'
import { centeredModal } from 'utils/modalStyles'

type ModalProps = {
  isOpen: boolean
  currentColor: string
  onSave: (color: string) => void
  onCancel: () => void
}

export const ColorPickerModal = ({
  isOpen,
  currentColor,
  onSave,
  onCancel
}: ModalProps) => {
  const [color, setColor] = useState<string>('')

  return (
    <Modal
      isOpen={isOpen}
      style={centeredModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onAfterOpen={() => {
        setColor(currentColor)
      }}
      onRequestClose={() => {
        onCancel()
        setColor('')
      }}
    >
      <div className="flex flex-col items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900 border-b border-gray-200 pb-5">
          Selecione a cor desejada
        </h3>
        <SketchPicker
          className="z-50"
          color={color}
          onChange={(color) => {
            setColor(color.hex)
          }}
        />
        <div className="pt-4 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              onSave(color)
            }}
          >
            Salvar
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onCancel()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}
