import Modal from 'react-modal'
import { useState, useEffect, ChangeEvent } from 'react'
import ReactLoading from 'react-loading'

import { Category } from '../../categoriesTypes'

import { upload } from 'services/storage'
import { api } from 'services/api'

import { generateRequestConfig } from 'utils/requestConfig'

import * as Ui from './style'
import { centeredModal } from 'utils/modalStyles'
import { Page } from 'pages/product-pages/productPageTypes'

type ModalProps = {
  isOpen: boolean
  onClose: (reload?: boolean) => void
  index: number
  editingCategory?: Category
}
export const CategoryModal = ({
  isOpen,
  onClose,
  index,
  editingCategory
}: ModalProps) => {
  const [categoryName, setCategoryName] = useState('')
  const [categoryDescription, setCategoryDescription] = useState('')
  const [redirectLink, setRedirectLink] = useState('')
  const [rightAlignment, setRightAlignment] = useState(false)
  const [categoryId, setCategoryId] = useState(0)
  const [isPageMode, setPageMode] = useState(false)
  const [pages, setPages] = useState<Page[]>([])
  const [selectedPage, setSelectedPage] = useState<Page | undefined>(undefined)

  const [loading, setLoading] = useState(false)

  const [imageAsFile, setImageAsFile] = useState<File | undefined>()

  useEffect(() => {
    getPages()
    if (editingCategory) {
      setCategoryName(editingCategory.title)
      setRedirectLink(editingCategory.link || '')
      setCategoryDescription(editingCategory.description)
      setRightAlignment(editingCategory.right)
      setCategoryId(editingCategory.id)
      setSelectedPage(editingCategory.page)
      setPageMode(editingCategory.page != undefined)
    } else {
      resetStates()
    }
  }, [editingCategory])

  const handleImageAsFile = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files ? e.target.files[0] : undefined
    setImageAsFile(() => image)
  }

  const resetStates = () => {
    setCategoryName('')
    setCategoryDescription('')
    setRedirectLink('')
    setRightAlignment(false)
    setCategoryId(0)
    setImageAsFile(undefined)
    setPages([])
    setPageMode(false)
    setSelectedPage(undefined)
  }

  const saveStore = async () => {
    if (!categoryId && !isPageMode) {
      return alert('Informe o Identificador da Categoria')
    } else if (!imageAsFile && !editingCategory) {
      return alert('Selecione uma imagem')
    }

    try {
      setLoading(true)

      const image = imageAsFile ? await upload(imageAsFile) : undefined

      const data = {
        name: categoryName,
        image: image,
        description: categoryDescription,
        right: rightAlignment,
        id: categoryId,
        page: selectedPage,
        order: index + 1,
        link: redirectLink
      }

      if (editingCategory)
        await api.put(
          '/v1/categories/' + editingCategory._id,
          data,
          await generateRequestConfig()
        )
      else await api.post('/v1/categories', data, await generateRequestConfig())

      setLoading(false)
      resetStates()
      onClose(true)
    } catch (err) {
      setLoading(false)
      alert('Erro ao incluir a Loja')
      console.log(err)
    }
  }

  async function getPages() {
    try {
      setLoading(true)
      const { data } = await api.get<Page[]>(
        '/v1/customProductPage',
        await generateRequestConfig()
      )

      if (data && data.length) {
        setPages(data)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      alert('Erro ao consultar páginas')
      console.log(e)
    }
  }

  return (
    <Modal isOpen={isOpen} style={centeredModal}>
      <div className="w-full h-full bg-blue flex flex-col">
        <div className="pb-4 border-b border-gray-200 mb-5 flex justify-end">
          <button
            className="text-lg leading-6 font-medium text-gray-900"
            onClick={() => onClose()}
          >
            Fechar
          </button>
        </div>
        <div className="flex flex-col flex-1">
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-name" className="mb-4 font-bold">
              Nome da Categoria
            </Ui.FormTitle>
            <input
              id="store-name"
              name="categoryName"
              type="text"
              required
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o nome da Loja"
            />
          </div>
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-address" className="mb-4 font-bold">
              Descrição
            </Ui.FormTitle>
            <input
              id="store-address"
              name="categoryDescription"
              type="text"
              required
              value={categoryDescription}
              onChange={(e) => setCategoryDescription(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o endereço"
            />
          </div>
          <div className="rounded shadow-sm -space-y-px mb-5 flex flex-row">
            <input
              id="page-mode"
              name="pageMode"
              type="checkbox"
              checked={isPageMode}
              onChange={() => {
                const newMode = !isPageMode
                setPageMode(newMode)
                if (newMode) {
                  setSelectedPage(pages[0])
                } else {
                  setSelectedPage(undefined)
                }
              }}
              className="px-3 py-2 mr-5 form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
              placeholder="Informe o telefone da Loja"
            />
            <Ui.FormTitle
              htmlFor="page-mode"
              className="mb-4 font-bold select-none"
            >
              Redirecionar para página
            </Ui.FormTitle>
          </div>

          {isPageMode ? (
            <div className="rounded shadow-sm -space-y-px mb-5">
              <Ui.FormTitle htmlFor="page-Page" className="mb-4 font-bold">
                Página
              </Ui.FormTitle>
              <select
                id="page-Page"
                name="selectedPage"
                value={selectedPage?.id}
                onChange={(e) => {
                  setSelectedPage(
                    pages.find((page) => e.target.value == page.id)
                  )
                }}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Selecione a Página"
              >
                {pages.map((x) => (
                  <option
                    value={x.id}
                    key={`${x.name} ${x.id}`}
                  >{`${x.name}`}</option>
                ))}
              </select>
            </div>
          ) : (
            <div className="rounded shadow-sm -space-y-px mb-5">
              <Ui.FormTitle htmlFor="store-phone" className="mb-4 font-bold">
                Id da Categoria
              </Ui.FormTitle>
              <input
                id="store-phone"
                name="categoryId"
                type="number"
                value={categoryId}
                onChange={(e) => {
                  if (!isNaN(parseInt(e.target.value)))
                    setCategoryId(parseInt(e.target.value))
                }}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Informe o telefone da Loja"
              />
            </div>
          )}
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-phone" className="mb-4 font-bold">
              Imagem
            </Ui.FormTitle>
            <input
              id="store-image"
              name="storeImage"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              required
              onChange={handleImageAsFile}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o telefone da Loja"
            />
          </div>

          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-phone" className="mb-4 font-bold">
              Link de Redirecionamento
            </Ui.FormTitle>
            <input
              id="store-link"
              name="redirectLink"
              type="text"
              value={redirectLink}
              onChange={(e) => {
                setRedirectLink(e.target.value)
              }}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o link de redirecionamento (se houver)"
            />
          </div>
        </div>
        <div className="pt-4 border-t border-gray-200 mt-5 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => saveStore()}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading type="bars" color="#fff" height={20} width={20} />
            ) : (
              'Salvar'
            )}
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onClose()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}
