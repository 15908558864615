import { Filter, Home } from 'pages/custom-home/homeTypes'
import { useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable
} from 'react-beautiful-dnd'
import { FilterModal } from '../filter-modal'
import { FilterPreivew } from '../filter-preview'

type ProductGridProps = {
  home: Home
  onChange: (home: Home) => void
}

export const ProductGridComp = ({ home, onChange }: ProductGridProps) => {
  const [state, setState] = useState<Home>(home)
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false)
  const [currentFilter, setCurrentFilter] = useState<Filter | undefined>({})
  const [dragging, setDragging] = useState<boolean>(false)

  const handleDrop = (result: DropResult) => {
    setDragging(false)
    if (!result.destination) return
    const updatedList = [...(home.metadata.filters ?? [])]
    const [reorderedItem] = updatedList.splice(result.source.index, 1)
    updatedList.splice(result.destination.index, 0, reorderedItem)
    if (
      !home.metadata.filters?.every((val, index) => val === updatedList[index])
    ) {
      home.metadata.filters = updatedList
      setState({
        ...home,
        metadata: {
          ...home.metadata,
          filters: updatedList
        }
      })
      onChange(home)
    }
  }

  const handleStart = () => setDragging(true)

  return (
    <div className="p-4 bg-white rounded-b-md">
      <DragDropContext onDragEnd={handleDrop} onDragStart={handleStart}>
        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Título *
          </label>
          <input
            type="text"
            name="title"
            id="title"
            value={state.metadata.title}
            onChange={(e) => {
              home.metadata.title = e.target.value
              setState({
                ...home,
                metadata: {
                  ...home.metadata,
                  title: e.target.value
                }
              })
              onChange(home)
            }}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-8">
          <label
            htmlFor="subtitle"
            className="block text-sm font-medium text-gray-700"
          >
            Subtitulo *
          </label>
          <input
            type="text"
            name="subtitle"
            id="subtitle"
            value={state.metadata.subtitle}
            onChange={(e) => {
              home.metadata.subtitle = e.target.value
              setState({
                ...home,
                metadata: {
                  ...home.metadata,
                  subtitle: e.target.value
                }
              })
              onChange(home)
            }}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>

        <Droppable droppableId="list-container" direction="horizontal">
          {(provided) => (
            <div
              className="flex overflow-auto gap-2 pb-4"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {state.metadata?.filters?.map((filter, index) => (
                <Draggable
                  key={index}
                  draggableId={index.toString()}
                  index={index}
                >
                  {(provided: DraggableProvided) => (
                    <div
                      className="mr-2 flex flex-col"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <FilterPreivew
                        filter={filter}
                        canDelete={true}
                        onEditClick={() => {
                          setCurrentFilter(filter)
                          setFilterOpen(true)
                        }}
                        onDeleteClick={() => {
                          home.metadata.filters?.splice(
                            home.metadata.filters?.indexOf(filter),
                            1
                          )
                          setState({ ...home })
                          onChange(home)
                        }}
                      />
                    </div>
                  )}
                </Draggable>
              ))}

              <FilterModal
                isOpen={isFilterOpen}
                currentFilter={currentFilter}
                onCancel={() => {
                  setFilterOpen(false)
                }}
                onSave={(filter: Filter) => {
                  const index =
                    home.metadata.filters?.indexOf(currentFilter ?? {}) ??
                    undefined
                  if (index != undefined && index >= 0) {
                    home.metadata.filters![index] = filter
                  } else {
                    home.metadata.filters?.push(filter)
                  }
                  setState({
                    ...home,
                    metadata: {
                      ...home.metadata
                    }
                  })
                  setFilterOpen(false)
                  onChange(home)
                }}
              />
              {dragging ? (
                <></>
              ) : (
                <div
                  className="flex flex-col h-36 w-36 justify-center items-center self-center"
                  onClick={() => {
                    setCurrentFilter(undefined)
                    setFilterOpen(true)
                  }}
                >
                  <div className="flex justify-center cursor-pointer hover:bg-gray-300 items-center h-24 w-24 border-dashed border-2 border-indigo-900 opacity-70 rounded-full">
                    <svg
                      className="h-12 w-12"
                      fill="#AAAAAA"
                      viewBox="0 0 24 24"
                    >
                      <path d="M19,13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                    </svg>
                  </div>
                </div>
              )}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
