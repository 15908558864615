import moment from 'moment'
import { Home } from 'pages/custom-home/homeTypes'
import {
  HOME_TYPE_BRANDS,
  HOME_TYPE_BUBBLES,
  HOME_TYPE_CAROUSEL,
  HOME_TYPE_CATEGORIES_BANNER,
  HOME_TYPE_COLLECTIONS,
  HOME_TYPE_COUNTDOWN,
  HOME_TYPE_DIVIDER,
  HOME_TYPE_FILTER_GRID,
  HOME_TYPE_MID_CAROUSEL,
  HOME_TYPE_PORTRAIT_CAROUSEL,
  HOME_TYPE_PROD_GRID,
  HOME_TYPE_PROD_LIST,
  HOME_TYPE_SHELF,
  HOME_TYPE_SHELF_WITH_BANNER,
  HOME_TYPE_SMARTHINT_PRODUCT_RECOMENDATION,
  HOME_TYPE_STATIC_BANNER,
  newObjectID
} from 'utils/customHome'

export type NewComponent = {
  type: string
  name: string
  group: string
}

export const availableHomeComponents = Array<NewComponent>(
  { type: HOME_TYPE_BUBBLES, name: 'Bolhas', group: 'scrolling' },
  {
    type: HOME_TYPE_CATEGORIES_BANNER,
    name: 'Categorias com Banner',
    group: 'scrolling'
  },
  { type: HOME_TYPE_CAROUSEL, name: 'Banner', group: 'banners' },
  { type: HOME_TYPE_MID_CAROUSEL, name: 'Banner Médio', group: 'banners' },
  {
    type: HOME_TYPE_PORTRAIT_CAROUSEL,
    name: 'Banner Retrato',
    group: 'banners'
  },
  { type: HOME_TYPE_PROD_LIST, name: 'Lista de produtos', group: 'products' },
  { type: HOME_TYPE_PROD_GRID, name: 'Grade de produtos', group: 'products' },
  { type: HOME_TYPE_COUNTDOWN, name: 'Contagem regressiva', group: 'banners' },
  { type: HOME_TYPE_BRANDS, name: 'Marcas', group: 'scrolling' },
  { type: HOME_TYPE_COLLECTIONS, name: 'Coleções', group: 'scrolling' },
  { type: HOME_TYPE_DIVIDER, name: 'Espaçador', group: 'statics' },
  { type: HOME_TYPE_FILTER_GRID, name: 'Grade de filtros', group: 'filters' },
  { type: HOME_TYPE_SHELF, name: 'Estante de produtos', group: 'products' },
  {
    type: HOME_TYPE_SHELF_WITH_BANNER,
    name: 'Estante de produtos com Imagem',
    group: 'products'
  },
  { type: HOME_TYPE_STATIC_BANNER, name: 'Banner estático', group: 'banners' },
  {
    type: HOME_TYPE_SMARTHINT_PRODUCT_RECOMENDATION,
    name: 'Smarthint Recomendação de Produtos',
    group: 'smarthint'
  }
)

export const defaultHomes = new Map<string, () => Home>([
  [
    HOME_TYPE_BUBBLES,
    () => ({
      name: 'Novas bolhas',
      id: newObjectID(),
      type: HOME_TYPE_BUBBLES,
      metadata: { title: '', bubbles: [] }
    })
  ],
  [
    HOME_TYPE_CATEGORIES_BANNER,
    () => ({
      name: 'Categorias com Banner',
      id: newObjectID(),
      type: HOME_TYPE_CATEGORIES_BANNER,
      metadata: { title: '', categoriesBanner: [] }
    })
  ],
  [
    HOME_TYPE_CAROUSEL,
    () => ({
      name: 'Novo banner',
      id: newObjectID(),
      type: HOME_TYPE_CAROUSEL,
      metadata: { title: '', carousel: [] }
    })
  ],
  [
    HOME_TYPE_MID_CAROUSEL,
    () => ({
      name: 'Novo banner Médio',
      id: newObjectID(),
      type: HOME_TYPE_MID_CAROUSEL,
      metadata: { title: '', carousel: [] }
    })
  ],
  [
    HOME_TYPE_PORTRAIT_CAROUSEL,
    () => ({
      name: 'Novo banner Retrato',
      id: newObjectID(),
      type: HOME_TYPE_PORTRAIT_CAROUSEL,
      metadata: { title: '', carousel: [] }
    })
  ],
  [
    HOME_TYPE_PROD_LIST,
    () => ({
      name: 'Nova lista de produtos',
      id: newObjectID(),
      type: HOME_TYPE_PROD_LIST,
      metadata: { title: '', filters: [{}] }
    })
  ],
  [
    HOME_TYPE_PROD_GRID,
    () => ({
      name: 'Nova grade de produtos',
      id: newObjectID(),
      type: HOME_TYPE_PROD_GRID,
      metadata: { title: '', filters: [] }
    })
  ],
  [
    HOME_TYPE_COUNTDOWN,
    () => ({
      name: 'Nova contagem regressiva',
      id: newObjectID(),
      type: HOME_TYPE_COUNTDOWN,
      metadata: {
        title: '',
        countDown: [
          {
            title: '',
            endMessage: '',
            endTime: moment().format('yyyy-MM-DD HH:MM'),
            image: '',
            filter: {}
          }
        ]
      }
    })
  ],
  [
    HOME_TYPE_BRANDS,
    () => ({
      name: 'Novas marcas',
      id: newObjectID(),
      type: HOME_TYPE_BRANDS,
      metadata: { title: '', brands: [] }
    })
  ],
  [
    HOME_TYPE_COLLECTIONS,
    () => ({
      name: 'Novas coleções',
      id: newObjectID(),
      type: HOME_TYPE_COLLECTIONS,
      metadata: { title: '', collections: [] }
    })
  ],
  [
    HOME_TYPE_DIVIDER,
    () => ({
      name: 'Novo espaçador',
      id: newObjectID(),
      type: HOME_TYPE_DIVIDER,
      metadata: { title: '' }
    })
  ],
  [
    HOME_TYPE_FILTER_GRID,
    () => ({
      name: 'Nova grade de filtros',
      id: newObjectID(),
      type: HOME_TYPE_FILTER_GRID,
      metadata: { title: '', filterGrid: [] }
    })
  ],
  [
    HOME_TYPE_SHELF,
    () => ({
      name: 'Nova estante de produtos',
      id: newObjectID(),
      type: HOME_TYPE_SHELF,
      metadata: { title: '', filters: [{}] }
    })
  ],
  [
    HOME_TYPE_SHELF_WITH_BANNER,
    () => ({
      name: 'Nova estante de produtos com imagem',
      id: newObjectID(),
      type: HOME_TYPE_SHELF_WITH_BANNER,
      metadata: { title: '', filters: [{}] }
    })
  ],
  [
    HOME_TYPE_STATIC_BANNER,
    () => ({
      name: 'Novo banner estático',
      id: newObjectID(),
      type: HOME_TYPE_STATIC_BANNER,
      metadata: {
        title: '',
        staticBanner: [{}]
      }
    })
  ],
  [
    HOME_TYPE_SMARTHINT_PRODUCT_RECOMENDATION,
    () => ({
      name: 'Recomendação de Produtos Smarthint',
      id: newObjectID(),
      type: HOME_TYPE_SMARTHINT_PRODUCT_RECOMENDATION,
      metadata: { position: 1, title: '' }
    })
  ]
])
