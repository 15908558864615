import { Home } from 'pages/custom-home/homeTypes'
import { useState } from 'react'

type ShelfProps = {
  home: Home
  onUpdate: () => void
}

export const SmarthintProductsRecomendation = ({
  home,
  onUpdate
}: ShelfProps) => {
  const [position, setPosition] = useState<number>(home.metadata.position || 0)

  return (
    <div className="p-4 bg-white rounded-b-md">
      <div className="mb-8">
        <label
          htmlFor="position"
          className="block text-sm font-medium text-gray-700"
        >
          Posição da Recomendação *
        </label>
        <input
          type="text"
          name="position"
          id="position"
          value={position}
          onChange={(e) => {
            home.metadata.position = parseInt(e.target.value)
            setPosition(parseInt(e.target.value))
            onUpdate()
          }}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
  )
}
