import { useState } from 'react'
import { Carousel as CarouselView } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import DeleteIcon from '../../../../assets/delete_48.svg'
import EditIcon from '../../../../assets/edit_48.svg'
import { Carousel, Home } from '../../homeTypes'
import { ConfirmModal } from '../confirm-modal'
import { CarouselModal } from './components/modal'

type CarouselProps = {
  home: Home
  onDelete: (index: number) => void
  onUpdate: (index: number, carousel: Carousel, newImage?: File) => void
  onAdd: (index: number, carousel: Carousel, newImage: File) => void
}

const POS_BEFORE = -1
const POS_AFTER = 1

export const PortraitCarouselComp = ({
  home,
  onDelete,
  onUpdate,
  onAdd
}: CarouselProps) => {
  const [selected, setSelected] = useState<number>(0)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [newCarouselPos, setNewCarouselPos] = useState<number | undefined>(
    undefined
  )
  const [carouselNewImage, setCarouselNewImage] = useState<{
    [key: number]: string | undefined
  }>({})

  const handleChange = (index: number) => {
    setSelected(index)
  }

  const handleDelete = () => {
    setShowDeleteModal(false)
    onDelete(selected)
  }

  const onSave = (
    carousel: Carousel,
    newImage?: { file: File; b64: string }
  ) => {
    setShowEditModal(false)
    if (newCarouselPos) {
      if (!newImage) return
      const pos = newCarouselPos == POS_BEFORE ? selected : selected + 1
      onAdd(pos, carousel, newImage.file)
      Object.keys(carouselNewImage)
        .reverse()
        .forEach((key) => {
          if (Number(key) >= pos) {
            const old = carouselNewImage[Number(key)]
            if (old) {
              carouselNewImage[Number(key) + 1] = old
              carouselNewImage[Number(key)] = undefined
            }
          }
        })
      carouselNewImage[pos] = newImage.b64
      setCarouselNewImage(carouselNewImage)
      setNewCarouselPos(undefined)
    } else {
      if (newImage) {
        carouselNewImage[selected] = newImage.b64
        setCarouselNewImage(carouselNewImage)
      }
      onUpdate(selected, carousel, newImage?.file)
    }
  }

  return (
    <div className="pt-4 flex flex-col bg-white rounded-b-md">
      <div className="flex gap-10 px-24 flex-1 justify-center">
        <button
          type="button"
          className="mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => {
            setNewCarouselPos(POS_BEFORE)
            setShowEditModal(true)
          }}
        >
          {home.metadata.carousel?.length ? 'Adicionar Antes' : 'Adicionar'}
        </button>
        {home.metadata.carousel?.length ? (
          <button
            type="button"
            className="mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              setNewCarouselPos(POS_AFTER)
              setShowEditModal(true)
            }}
          >
            Adicionar Depois
          </button>
        ) : (
          <></>
        )}
      </div>
      <div className="self-center md:w-4/12 w-10/12 relative">
        {home.metadata.carousel?.length ? (
          <div>
            <button
              className="opacity-80 hover:opacity-100 absolute bottom-24 right-2 z-10 bg-red-500 hover:bg-red-700 rounded-full p-2"
              onClick={() => setShowDeleteModal(true)}
            >
              <img src={DeleteIcon} className="h-7 w-7 p-1" />
            </button>
            <button
              className="opacity-80 hover:opacity-100 absolute bottom-24 md:right-14 left-2 md:left-auto z-10 bg-indigo-500 hover:bg-indigo-700 rounded-full p-2"
              onClick={() => {
                setShowEditModal(true)
              }}
            >
              <img src={EditIcon} className="h-7 w-7 p-1" />
            </button>
          </div>
        ) : (
          <></>
        )}
        <CarouselView
          showArrows={false}
          dynamicHeight={true}
          onChange={handleChange}
          emulateTouch={true}
          infiniteLoop={true}
          className="self-center text-center select-none"
        >
          {home.metadata.carousel?.map((c, index) => {
            return (
              <div key={c.image}>
                <img
                  src={carouselNewImage[index] ?? c.image}
                  className="object-cover w-full"
                />
              </div>
            )
          })}
        </CarouselView>
      </div>
      <CarouselModal
        isOpen={showEditModal}
        onClose={() => {
          setNewCarouselPos(undefined)
          setShowEditModal(false)
        }}
        carousel={
          newCarouselPos ? undefined : home.metadata.carousel?.[selected]
        }
        selectedImage={
          newCarouselPos ? undefined : carouselNewImage[selected ?? 0]
        }
        onSave={onSave}
      />
      <ConfirmModal
        isOpen={showDeleteModal}
        message="Tem certeza que deseja excluir este item?"
        negativeText="Não"
        positiveText="Sim"
        onNegative={() => {
          setShowDeleteModal(false)
        }}
        onPositive={handleDelete}
      />
    </div>
  )
}
