import { Link } from 'react-location'

import { Disclosure, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { auth } from 'config/firebase'
import { Fragment, useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { getApps, selectApp } from 'services/apps'
import { App } from 'services/appsTypes'
import UserIcon from '../../assets/user_icon.svg'

const userNavigation = [
  { name: 'Meu perfil', href: '/profile' },
  { name: 'Configurações', href: '/settings' },
  { name: 'Sair', href: '/login' }
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const Header = () => {
  const [apps, setApps] = useState<App[]>([])
  const [loadingApps, setLoadingApps] = useState<boolean>(true)

  async function getMyApps() {
    setLoadingApps(true)
    const loadApps = await getApps()
    setApps(loadApps)
    setLoadingApps(false)
  }

  useEffect(() => {
    getMyApps()
  }, [])

  useEffect(() => {
    const user = auth.currentUser
    console.log(user)
  }, [])

  const selectedApp = () => apps.find((app) => app.selected)

  const renderAppSelectionMenu = () => (
    <Menu as="div" className="relative flex items-baseline z-50">
      <div>
        <Menu.Button className="text-gray-600 w-40 justify-between bg-gray-300 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
          {loadingApps ? (
            <ReactLoading type="bars" color="#fff" height={20} width={20} />
          ) : (
            <>
              <span>{selectedApp()?.title}</span>
              <ChevronDownIcon className="block h-4 w-4" aria-hidden="true" />
            </>
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-left absolute mt-12 w-48 shadow-lg text-sm bg-gray-300 rounded-lg ring-1 ring-gray-300 ring-opacity-5 focus:outline-none">
          {apps.map((app) => (
            <Menu.Item key={app.appId}>
              {({ active }) => (
                <button
                  type="button"
                  onClick={async () => {
                    if (await selectApp(app.appId)) {
                      window.location.reload()
                    }
                  }}
                  className={classNames(
                    active ? 'bg-gray-200' : '',
                    app.selected ? 'text-primary' : 'text-gray-600',
                    'block px-4 py-2 text-sm w-full text-left rounded-lg'
                  )}
                >
                  {app.title}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )

  return (
    <>
      <Disclosure
        as="nav"
        className="shadow-md flex top-0 sticky z-30 bg-white"
      >
        {() => (
          <>
            <div className="px-5 h-12 md:h-16 w-full bg-white flex items-center justify-between">
              <div className="hidden md:flex md:items-center">
                {renderAppSelectionMenu()}
              </div>
              <div className="flex items-center">
                <div className="flex flex-col mr-2 text-right">
                  <p className="text-sm font-semibold">
                    {auth.currentUser?.email}
                  </p>
                  <p className="text-primary text-xs">Admin</p>
                </div>

                <Menu as="div" className="relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={UserIcon}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => {
                            return item.name === 'Sair' ? (
                              <button
                                onClick={() => auth.signOut()}
                                className="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                Sair
                              </button>
                            ) : (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                {item.name}
                              </Link>
                            )
                          }}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </>
  )
}
