import { storage } from 'config/firebase'
import { ref, uploadBytes } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'

export const upload = async (file?: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      if (!file) throw Error('No file')
      const splitedName = file.name.split('.')

      const ext = splitedName[splitedName.length - 1]
      const fileName = `${uuidv4()}.${ext}`

      const storageRef = ref(storage, fileName)
      uploadBytes(storageRef, file).then((snapshot) => {
        console.log('Uploaded a blob or file!', snapshot)
        resolve(fileName)
      })
    } catch (err) {
      console.log(err)
      reject()
    }
  })
}
