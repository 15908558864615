export const HOME_TYPE_BRANDS = 'brands'
export const HOME_TYPE_BUBBLES = 'bubbles'
export const HOME_TYPE_CATEGORIES_BANNER = 'categoriesBanner'
export const HOME_TYPE_OFFER_CORNER = 'offerCorner'
export const HOME_TYPE_PROD_GRID = 'productGrid'
export const HOME_TYPE_PROD_GRID_CAT = 'productGridCat'
export const HOME_TYPE_PROD_LIST = 'productList'
export const HOME_TYPE_COUNTDOWN = 'countDown'
export const HOME_TYPE_CAROUSEL = 'carousel'
export const HOME_TYPE_MID_CAROUSEL = 'midCarousel'
export const HOME_TYPE_PORTRAIT_CAROUSEL = 'portraitCarousel'
export const HOME_TYPE_COLLECTIONS = 'collections'
export const HOME_TYPE_DIVIDER = 'divider'
export const HOME_TYPE_FILTER_GRID = 'filterGrid'
export const HOME_TYPE_SHELF = 'shelf'
export const HOME_TYPE_SHELF_WITH_BANNER = 'shelfWithBanner'
export const HOME_TYPE_STATIC_BANNER = 'staticBanner'
export const HOME_TYPE_SMARTHINT_PRODUCT_RECOMENDATION =
  'smarthintProductRecomendation'

export const HOME_REORDER_FLAG = 'reorder'

export function newObjectID() {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16)
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16)
      })
      .toLowerCase()
  )
}
