import { CepRange } from 'pages/custom-home/homeTypes'
import { useInputMask } from 'utils/useInputMask'
import { KeyboardEvent } from 'react'
import DeleteIcon from '../../../../../../../assets/delete_48.svg'

type RangeFieldProps = {
  range: CepRange
  onUpdate: (range: CepRange) => void
  onDeleteRequest: () => void
}

export const RangeField = ({
  range,
  onUpdate,
  onDeleteRequest
}: RangeFieldProps) => {
  const { ref: startCEPRef, onKeyUp: onKeyUpStart } = useInputMask('99999-999')
  const { ref: endCEPRef, onKeyUp: onKeyUpEnd } = useInputMask('99999-999')
  return (
    <div className="col-span-1 md:col-span-1 flex flex-row gap-4 mb-5">
      <div>
        <label
          htmlFor="from"
          className="block text-sm font-medium text-gray-700"
        >
          De:
        </label>
        <input
          type="text"
          name="from"
          id="from"
          ref={startCEPRef}
          maxLength={8}
          onKeyUp={(e: KeyboardEvent) => {
            onKeyUpStart()
            onUpdate({
              ...range,
              cepStartFormatted: (e.target as HTMLInputElement).value
            })
          }}
          defaultValue={range.cepStartFormatted}
          onChange={(e) => {
            onUpdate({
              ...range,
              cepStart: e.target.value
            })
          }}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div>
        <label htmlFor="to" className="block text-sm font-medium text-gray-700">
          Até:
        </label>
        <input
          type="text"
          name="to"
          id="to"
          defaultValue={range.cepEndFormatted}
          ref={endCEPRef}
          maxLength={8}
          onKeyUp={(e: KeyboardEvent) => {
            onKeyUpEnd()
            onUpdate({
              ...range,
              cepEndFormatted: (e.target as HTMLInputElement).value
            })
          }}
          onChange={(e) => {
            onUpdate({
              ...range,
              cepEnd: e.target.value
            })
          }}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <button
        className="h-12 w-12 flex opacity-80 hover:opacity-100 z-10 bg-red-500 hover:bg-red-700 rounded-full self-end justify-center items-center"
        onClick={() => {
          onDeleteRequest()
        }}
      >
        <img src={DeleteIcon} className="h-7 w-7 p-1" />
      </button>
    </div>
  )
}
