import { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import Modal from 'react-modal'

import { User } from '../../usersPageTypes'

import { api } from 'services/api'
import { centeredModal } from 'utils/modalStyles'
import { generateRequestConfig } from 'utils/requestConfig'
import * as Ui from './style'

type ModalProps = {
  isOpen: boolean
  onClose: (reload?: boolean) => void
  index: number
  editingUser?: User
}
export const UserModal = ({ isOpen, onClose, editingUser }: ModalProps) => {
  const [email, setEmail] = useState('')
  const [isManager, setIsManager] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (editingUser) {
      setEmail(editingUser.email)
      setIsManager(editingUser.isManager)
    } else {
      resetStates()
    }
  }, [editingUser])

  const resetStates = () => {
    setEmail('')
  }

  const saveStore = async () => {
    if (!email) {
      return alert('Informe o e-mail do usuário')
    }

    try {
      setLoading(true)

      const data = {
        email,
        isManager
      }

      await api.put('/v1/admin-users/', data, await generateRequestConfig())

      setLoading(false)
      resetStates()
      onClose(true)
    } catch (err) {
      setLoading(false)
      alert('Erro ao incluir o usuário')
      console.log(err)
    }
  }

  return (
    <Modal isOpen={isOpen} style={centeredModal}>
      <div className="w-full h-full bg-blue flex flex-col">
        <div className="pb-4 border-b border-gray-200 mb-5 flex justify-end">
          <button
            className="text-lg leading-6 font-medium text-gray-900"
            onClick={() => onClose()}
          >
            Fechar
          </button>
        </div>
        <div className="flex flex-col flex-1">
          <div className="rounded shadow-sm mb-5">
            <Ui.FormTitle htmlFor="user-email" className="mb-4 font-bold">
              E-mail do usuário
            </Ui.FormTitle>
            <input
              id="user-email"
              name="E-mail"
              type="text"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o e-mail do usuário"
            />
          </div>
          <div className="flex items-center mb-4">
            <input
              onChange={() => setIsManager(!isManager)}
              checked={isManager}
              id="checkbox-1"
              type="checkbox"
              value=""
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="checkbox-1"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              É administrador?
            </label>
          </div>
        </div>

        <div className="pt-4 border-t border-gray-200 mt-5 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => saveStore()}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading type="bars" color="#fff" height={20} width={20} />
            ) : (
              'Salvar'
            )}
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onClose()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}
