import CategoryItem from './Components/CategoryItem'
import { IoCellular, IoWifi } from 'react-icons/io5'
import { IoIosBatteryFull } from 'react-icons/io'
import { Category } from '../../categoriesTypes'

const OurStores = ({ categories }: { categories: Category[] }) => {
  return (
    <div
      className="w-full relative flex flex-col bg-white mx-auto border-8 border-solid border-black overflow-hidden"
      style={{ height: '600px', width: '300px', borderRadius: '2.5rem' }}
    >
      <div className="flex items-center py-2 px-3 border-b border-gray-100">
        <div className="w-2/5">
          <span className="text-sm font-medium">16:30</span>
        </div>
        <div className="w-1/5">
          <span className="w-full h-4 bg-black rounded-3xl block"></span>
        </div>
        <div className="w-2/5">
          <div className="flex flex-1 items-center gap-x-1 justify-end">
            <IoWifi />
            <IoCellular />
            <IoIosBatteryFull size={24} />
          </div>
        </div>
      </div>
      <h6 className="px-3">Categorias</h6>
      <div
        className="flex flex-col w-full p-3 overflow-y-auto gap-y-3"
        style={{ height: '600px' }}
      >
        {categories.map((x: Category, index: number) => (
          <CategoryItem category={x} key={`${index}-store-item-preview`} />
        ))}
      </div>
    </div>
  )
}

export default OurStores
