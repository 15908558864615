import { useState } from 'react'
import { HiOutlineXCircle } from 'react-icons/hi'
import ReactLoading from 'react-loading'
import { api } from 'services/api'

import { convertStatus } from 'utils/convertStatus'
import { formatDate } from 'utils/formatDate'
import { generateRequestConfig } from 'utils/requestConfig'

export interface NotificationProps {
  dateTime: string
  description: string
  id: string
  page: string
  status: string
  title: string
}

interface NotificationTableProps {
  notifications: NotificationProps[]
  loadingNotifications: boolean
  getNotifications: () => Promise<void>
}

export const NotificationTable = ({
  notifications,
  loadingNotifications,
  getNotifications
}: NotificationTableProps) => {
  const [loading, setLoading] = useState(false)

  const cancelNotification = async (id: string) => {
    try {
      setLoading(true)

      await api.delete(
        `/v2/notification/${id}`,

        await generateRequestConfig()
      )

      alert('Notificação Cancelada com Sucesso!')
      setLoading(false)
      getNotifications()
    } catch (e) {
      setLoading(false)
      alert('Erro ao cancelar Notificação')
      console.log(e)
    }
  }

  if (loadingNotifications) {
    return (
      <div className="my-5 flex justify-center items-center">
        <h2>Carregando notificações</h2>
      </div>
    )
  }

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Título
            </th>
            <th scope="col" className="px-6 py-3">
              Descrição
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
            <th scope="col" className="px-6 py-3">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((notification) => {
            const { id, title, dateTime, description, status } = notification
            return (
              <tr
                key={id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {title}
                  <small className="block text-gray-400">
                    {status === 'scheduled' ? 'Agendado para:' : 'Enviado'}{' '}
                    {formatDate(dateTime)}
                  </small>
                </th>
                <td className="px-6 py-4">{description}</td>
                <td className="px-6 py-4">{convertStatus(status)}</td>
                <td className="px-4 py-2 flex items-center justify-center">
                  {status === 'scheduled' && (
                    <button
                      className="p-2"
                      title="Cancelar agendamento"
                      onClick={() => cancelNotification(id)}
                    >
                      {loading ? (
                        <ReactLoading
                          type="bars"
                          color="#ffffff"
                          height={20}
                          width={20}
                        />
                      ) : (
                        <HiOutlineXCircle size={20} />
                      )}
                    </button>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
