import { Filter, Home } from 'pages/custom-home/homeTypes'
import { ChangeEvent, useRef, useState } from 'react'
import { FilterModal } from '../filter-modal'
import PlaceHolder from '../../../../assets/image-placeholder.png'
import { FilterPreivew } from '../filter-preview'

type ShelfProps = {
  home: Home
  onUpdate: () => void
  onUpdateImage: (newImage: File) => void
}

export const ProductShelfWithBanner = ({
  home,
  onUpdate,
  onUpdateImage
}: ShelfProps) => {
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false)
  const inputFile = useRef<HTMLInputElement | null>(null)
  const [imageAsFile, setImageAsFile] = useState<
    { file: File; b64: string } | undefined
  >()
  const [filter, setFilter] = useState<Filter>(
    home.metadata.filters?.at(0) || {}
  )
  const [title, setTitle] = useState<string>(home.metadata.title)
  const [subTitle, setSubTitle] = useState<string | undefined>(
    home.metadata.subtitle
  )

  const handleImageAsFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : undefined
    if (!file) return

    function getBase64(callback: (b64: string) => void) {
      if (!file) return callback('')
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => callback(reader.result?.toString() ?? '')
      reader.onerror = () => callback('')
    }

    getBase64((b64: string) => {
      setImageAsFile({ file, b64 })
      onUpdateImage(file)
    })
  }

  return (
    <div className="p-4 bg-white rounded-b-md">
      <div className="relative lg:w-5/12 h-40 mb-5">
        <img
          onClick={() => {
            inputFile?.current?.click()
          }}
          src={PlaceHolder}
          className="absolute hover:opacity-80 opacity-0 object-cover w-full h-full cursor-pointer"
        />
        <img
          src={imageAsFile?.b64 ?? home.metadata.banner ?? PlaceHolder}
          className="object-cover w-full h-full"
        />
      </div>
      <div className="mb-8">
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Título *
        </label>
        <input
          type="text"
          name="title"
          id="title"
          value={title}
          onChange={(e) => {
            home.metadata.title = e.target.value
            setTitle(e.target.value)
            onUpdate()
          }}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-8">
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          SubTítulo
        </label>
        <input
          type="text"
          name="title"
          id="title"
          value={subTitle}
          onChange={(e) => {
            home.metadata.subtitle = e.target.value
            setSubTitle(e.target.value)
            onUpdate()
          }}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <FilterPreivew
        filter={filter}
        onEditClick={() => {
          setFilterOpen(true)
        }}
      />

      <input
        type="file"
        accept="image/png"
        onChange={handleImageAsFile}
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
      />
      <FilterModal
        isOpen={isFilterOpen}
        onCancel={function (): void {
          setFilterOpen(false)
        }}
        onSave={function (savedFilter: Filter): void {
          home.metadata.filters = [savedFilter]
          setFilter(savedFilter)
          setFilterOpen(false)
          onUpdate()
        }}
        currentFilter={filter}
      />
    </div>
  )
}
