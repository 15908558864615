import { useEffect, useState } from 'react'
import { HiOutlineClock } from 'react-icons/hi'
import Layout from 'components/layout'
import { api } from 'services/api'
import { generateRequestConfig } from 'utils/requestConfig'
import { MaintenancePeriodData } from './maintenancePageTypes'
import ReactLoading from 'react-loading'

interface MaintenancePeriodForm {
  startDate?: Date
  endDate?: Date
  message?: string
}

export function MaintenancePeriod() {
  const [form, setForm] = useState<MaintenancePeriodForm>({
    startDate: new Date(),
    endDate: new Date(),
    message: ''
  })
  const [loading, setLoading] = useState(false)

  const getMaintenancePeriod = async () => {
    setLoading(true)
    const response = await api.get<MaintenancePeriodData>(
      '/v1/maintenance',
      await generateRequestConfig()
    )

    setForm({
      startDate: response.data.start
        ? new Date(response.data.start)
        : undefined,
      endDate: response.data.end ? new Date(response.data.end) : undefined,
      message: response.data.message
    })
    setLoading(false)
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await api.put(
        '/v1/maintenance/update',
        {
          start: form.startDate,
          end: form.endDate,
          message: form.message
        },
        await generateRequestConfig()
      )
      alert('Período de manutenção salvo com sucesso')
    } catch (error) {
      alert('Erro ao salvar período de manutenção')
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getMaintenancePeriod()
  }, [])

  return (
    <Layout>
      <div className="flex flex-col flex-1 bg-gray-50">
        <div className="py-6 px-8">
          <div className="flex items-center mb-6">
            <HiOutlineClock size={24} className="text-primary mr-2" />
            <h1 className="text-2xl font-semibold text-gray-900">
              Período de Manutenção
            </h1>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <form className="space-y-6">
              <div>
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Data Início
                </label>
                <input
                  type="datetime-local"
                  id="startDate"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                  value={form.startDate?.toISOString().slice(0, 16)}
                  onChange={(e) =>
                    setForm({ ...form, startDate: new Date(e.target.value) })
                  }
                />
              </div>

              <div>
                <label
                  htmlFor="endDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Data Fim
                </label>
                <input
                  type="datetime-local"
                  id="endDate"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                  value={form.endDate?.toISOString().slice(0, 16)}
                  onChange={(e) =>
                    setForm({ ...form, endDate: new Date(e.target.value) })
                  }
                />
              </div>

              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mensagem
                </label>
                <textarea
                  id="message"
                  rows={4}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                  value={form.message}
                  onChange={(e) =>
                    setForm({ ...form, message: e.target.value })
                  }
                />
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  className="inline-flex justify-center ml-4 mb-4 py-2 px-4 bg-primary shadow-sm text-sm font-medium rounded-md text-white hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => handleSubmit()}
                  disabled={loading}
                >
                  {loading ? (
                    <ReactLoading
                      type="bars"
                      color="rgba(255, 255, 255, var(--tw-border-opacity))"
                      height={20}
                      width={20}
                    />
                  ) : (
                    'Salvar'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}
