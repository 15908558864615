import { CepRange } from 'pages/custom-home/homeTypes'
import EditIcon from '../../../../../assets/edit_48.svg'

type CepRangePreivewProps = {
  cepRanges: CepRange[]
  onEdit: () => void
}

export const CepRangePreview = ({
  cepRanges,
  onEdit
}: CepRangePreivewProps) => {
  const maxRender = 3
  const renderCep = (cepRange: CepRange) => {
    return (
      <div className="m-2">
        <b>{cepRange.cepStartFormatted}</b> Até{' '}
        <b>{cepRange.cepEndFormatted}</b>
      </div>
    )
  }

  return (
    <div className="select-none p-3 border-dashed border-2 border-gray-400 rounded-md relative w-60">
      <h3 className="text-lg text-black pb-1 mb-3 border-b">
        Intervalos de CEPs
      </h3>
      <div
        className="absolute hover:bg-gray-600 hover:opacity-70 opacity-0 cursor-pointer top-0 left-0 w-full flex flex-col items-center justify-center rounded-md h-full"
        onClick={() => {
          onEdit()
        }}
      >
        <img src={EditIcon} />
      </div>
      {cepRanges.length ? (
        <div className="flex flex-col justify-center items-center">
          {cepRanges.map((range, index) =>
            index < maxRender ? renderCep(range) : <></>
          )}
          {cepRanges.length > maxRender ? (
            <p className="text-3xl self-center">...</p>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <p className="m-4 text-gray-500">
          Não há intervalos de CEPs cadastrados
        </p>
      )}
    </div>
  )
}
