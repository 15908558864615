import { CepRange } from 'pages/custom-home/homeTypes'
import { useState } from 'react'
import Modal from 'react-modal'
import { centeredModal } from 'utils/modalStyles'
import { RangeField } from './components/cep-range-input'

type CepRangeModalProps = {
  isOpen: boolean
  currentCepRanges: CepRange[]
  onCancel: () => void
  onSave: (cepRange: CepRange[]) => void
}

export const CepRangeModal = ({
  isOpen,
  currentCepRanges,
  onCancel,
  onSave
}: CepRangeModalProps) => {
  const [cepRanges, setCepRanges] = useState<CepRange[]>([])

  return (
    <Modal
      isOpen={isOpen}
      style={centeredModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        onCancel()
      }}
      onAfterOpen={() => {
        setCepRanges([...currentCepRanges])
      }}
    >
      <div className="flex flex-col items-center">
        <div className="grid grid-cols-2 gap-1 px-4 py-5">
          <h3 className="col-span-1 md:col-span-1 w-full text-center text-lg leading-6 mt-2 font-medium text-gray-900 pb-2 pt-2">
            Alterar Intervalos de CEPs
          </h3>
          <div className="col-span-1 md:col-span-1"></div>
        </div>
        <div className="max-h-72 overflow-auto">
          {cepRanges.map((range, index) => (
            <RangeField
              key={index}
              range={range}
              onUpdate={(r) => {
                cepRanges[index] = r
                setCepRanges([...cepRanges])
              }}
              onDeleteRequest={() => {
                const index = cepRanges.indexOf(range)
                cepRanges.splice(index, 1)
                setCepRanges([...cepRanges])
              }}
            />
          ))}
        </div>
        <div
          className="flex justify-center cursor-pointer hover:bg-gray-300 items-center h-10 w-10 border-dashed border-2 border-indigo-900 opacity-70 rounded-full"
          onClick={() => {
            cepRanges.push({
              cepEnd: '',
              cepEndFormatted: '',
              cepStart: '',
              cepStartFormatted: ''
            })
            setCepRanges([...cepRanges])
          }}
        >
          <svg className="h-8 w-8" fill="#222c85" viewBox="0 0 24 24">
            <path d="M19,13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
          </svg>
        </div>
        <div className="pt-4 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              const filtered = cepRanges.filter(
                (range) => range.cepEnd && range.cepStart
              )
              setCepRanges(filtered)
              onSave(filtered)
            }}
          >
            Salvar
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onCancel()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}
