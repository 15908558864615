import { auth, firestore } from 'config/firebase'
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where
} from 'firebase/firestore'
import { App, AppData, UserApp } from './appsTypes'

export const getApps = async (): Promise<App[]> => {
  const id = auth.currentUser?.uid ?? ''
  const q = query(collection(firestore, 'userApps'), where('ownerId', '==', id))
  const snapShot = await getDocs(q)
  const userApps = snapShot.docs[0].data()['apps'] ?? []
  return await Promise.all(
    userApps.map(async (app: UserApp) => {
      const appData = await getAppData(app.appId)
      return {
        selected: app.selected,
        title: appData.title,
        appId: appData.appId
      }
    })
  )
}

export const selectApp = async (appId: string): Promise<boolean> => {
  const id = auth.currentUser?.uid ?? ''
  const q = query(collection(firestore, 'userApps'), where('ownerId', '==', id))
  const snapShot = await getDocs(q)
  const userApps: UserApp[] = snapShot.docs[0].data()['apps'] ?? []
  const selectedApp = userApps.find((app: UserApp) => app.selected)
  const appToSelect = userApps.find((app: UserApp) => app.appId == appId)

  if (selectedApp?.appId == appId) return false

  if (appToSelect) {
    userApps.forEach((app: UserApp) => (app.selected = app.appId == appId))
  }
  await updateDoc(snapShot.docs[0].ref, {
    apps: userApps
  })
  return true
}

const getAppData = async (appId: string): Promise<AppData> => {
  const q = query(
    collection(firestore, 'appsData'),
    where('appId', '==', appId)
  )
  const snapShot = await getDocs(q)
  const appDocument = snapShot.docs.at(0)?.data()
  return appDocument
    ? {
        title: appDocument['title'],
        appId: appDocument['appId']
      }
    : {
        title: '',
        appId: ''
      }
}
