import { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { api } from 'services/api'
import { generateRequestConfig } from 'utils/requestConfig'

import { CategoryModal } from './components/modal'

import { Promotion } from './promotionsTypes'

import { Button } from 'components/button'

import Layout from 'components/layout'
import * as Ui from './style'

export const PromotionsPage = () => {
  const [stores, setStores] = useState<Promotion[]>([])
  const [showModal, setShowModal] = useState(false)
  const [editingCategory, setEditingCategory] = useState<Promotion>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getPages()
  }, [])

  async function getPages() {
    try {
      setLoading(true)
      const { data } = await api.get<Promotion[]>(
        '/v1/promotions',
        await generateRequestConfig()
      )

      if (data && data.length) {
        const strSorted = data

        setStores(strSorted)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      alert('Erro ao consultar Lojas')
      console.log(e)
    }
  }

  async function removePage(store: Promotion) {
    if (window.confirm('Deseja excluir a Pagina: ' + store.name + '?')) {
      try {
        setLoading(true)
        await api.delete(
          '/v1/promotions/' + store.id,
          await generateRequestConfig()
        )
        getPages()
        setLoading(false)
      } catch (e) {
        setLoading(false)
        alert('Erro ao excluir Pagina')
        console.log(e)
      }
    }
  }

  async function updateOrder(promotions: Promotion[]) {
    try {
      setLoading(true)
      await api.put(
        '/v1/promotions/order',
        { promotions: promotions.map((s) => ({ id: s.id, order: s.order })) },
        await generateRequestConfig()
      )
      setLoading(false)
    } catch (e) {
      setLoading(false)
      alert('Erro ao atualizar Ordens')
      console.log(e)
    }
  }

  const SortableItem = SortableElement(
    ({ value: store }: { value: Promotion }) => (
      <div className="border border-gray-200 p-5 w-full rounded-lg flex items-start mb-2 bg-white shadow overflow-hidden">
        <Ui.InfosContainer className="items-start">
          <Ui.StoreImage src={store.image} />
          <Ui.StoreName>
            <b>Nome: </b>
            {store.name}
          </Ui.StoreName>
          <Ui.StoreAddress>
            <b>Descrição: </b>
            {store.description}
          </Ui.StoreAddress>
          <Ui.StoreAddress>
            <b>Título: </b>
            {store.title}
          </Ui.StoreAddress>
          <Ui.StoreAddress>
            <b>Id da Página: </b>
            {store.pageId}
          </Ui.StoreAddress>
          <Ui.StoreAddress>
            <b>Cor: </b>
            {store.buttonColor}
          </Ui.StoreAddress>
          <Ui.StoreAddress>
            <b>#ID: </b>
            {store.id}
          </Ui.StoreAddress>
          <div className="flex flex-row items-start">
            <Button
              onClick={() => {
                setEditingCategory(store)
                setShowModal(true)
              }}
              disabled={loading}
              title="Editar"
            />
            <button
              type="button"
              className="inline-flex justify-center ml-2 py-2 px-4 border border-red-600 shadow-sm text-sm font-medium rounded-md text-red-600 bg-white hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={loading}
              onClick={() => removePage(store)}
            >
              Excluir
            </button>
          </div>
        </Ui.InfosContainer>
      </div>
    )
  )

  const SortableList = SortableContainer(
    ({ items }: { items: Promotion[] }) => {
      return (
        <div className="border-t border-gray-200 p-5 flex flex-col">
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setShowModal(true)}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading
                type="bars"
                color="rgba(79, 70, 229, var(--tw-border-opacity))"
                height={30}
                width={30}
              />
            ) : (
              'Adicionar'
            )}
          </button>
          {items.map((store: Promotion, index: number) => (
            <SortableItem
              key={`item-${store.id}`}
              index={index}
              value={store}
            />
          ))}
        </div>
      )
    }
  )

  return (
    <Layout>
      <section className="w-full mx-auto flex gap-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg w-full md:mx-4">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Promoções
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Gerencie as promoções que serão exibidas no menu Promoções.
            </p>
          </div>
          <SortableList
            items={stores}
            onSortEnd={(e) => {
              if (!loading && e.newIndex != e.oldIndex) {
                const strs = [...stores]
                const item = strs.splice(e.oldIndex, 1)
                strs.splice(e.newIndex, 0, item[0])
                strs.forEach((s, i) => {
                  s.order = i + 1
                })
                console.log('STRS', strs)
                updateOrder(strs)
                setStores(strs)
              }
            }}
          />
        </div>

        <CategoryModal
          isOpen={showModal}
          onClose={(reload) => {
            setShowModal(false)
            setEditingCategory(undefined)
            if (reload) getPages()
          }}
          index={0}
          editingCategory={editingCategory}
        />
      </section>
    </Layout>
  )
}
