import { useEffect, useState } from 'react'
import { Link } from 'react-location'
import { signInWithEmailAndPassword } from 'firebase/auth'
import Logo from 'assets/omnion.svg'
import { auth } from 'config/firebase'
import { Loading } from '../loading'

export const FormLogin = () => {
  const [state, setState] = useState({
    email: '',
    password: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const onSubmit = () => {
    setIsLoading(true)
    signInWithEmailAndPassword(auth, state.email, state.password)
      .then((userCredential) => {
        const user = userCredential.user
        console.log(user)
      })
      .catch((error) => {
        const errorMessage = error.message
        console.error(errorMessage)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (state.email.length && state.password.length) {
      setIsValid(true)
    } else setIsValid(false)
  }, [state])

  return (
    <>
      <div className="h-screen flex items-center justify-center py-12 px-2">
        <div className="max-w-md w-full space-y-8">
          <div>
            <Link to="/">
              <img className="mx-auto h-16 w-auto" src={Logo} alt="Workflow" />
            </Link>
            {/* <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Seja bem vindo!
            </h2> */}
          </div>
          <div className="mt-8 space-y-6">
            <div className="rounded-md shadow-sm -space-y-px">
              <div className="relative block">
                <input
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  type="email"
                  id="email"
                  name="email"
                  className="customInput"
                  placeholder=" "
                />
                <label htmlFor="email" className="customLabel">
                  E-mail
                </label>
              </div>
              <br />
              <hr />
              <br />
              <div className="relative block">
                <input
                  onChange={(e) =>
                    setState({ ...state, password: e.target.value })
                  }
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  className="customInput"
                  placeholder=" "
                />
                <label htmlFor="password" className="customLabel">
                  Senha
                </label>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <a
                  href="#"
                  className="font-medium text-purple-600 hover:text-purple-500"
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                onClick={onSubmit}
                disabled={!isValid}
                className={`${
                  isValid
                    ? 'bg-primary hover:bg-primaryDark transition-all'
                    : 'bg-gray-300'
                } text-white text-sm font-semibold text-center w-full h-11 rounded`}
              >
                {isLoading ? <Loading /> : 'Entrar'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
