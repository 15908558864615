import styled from 'styled-components'

const homeStyle = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  `,
  InfosContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
  `,
  StoreImage: styled.img`
    height: 100px;
    width: 100px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 3px;
  `,
  StoreInfoContainer: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
  `,
  TitleText: styled.span`
    font-weight: bold;
    font-size: 16px;
    color: #ba007c;
  `,
  DescriptionText: styled.span`
    font-size: 14px;
    color: #637390;
    margin-top: 5px;
  `,
  DescriptionBoldText: styled.span`
    font-weight: bold;
    margin-top: 12px;
  `,
  ButtonsContainer: styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 0 10px;
  `,
  ButtonCallContainer: styled.button`
    flex: 1;
    height: 40px;
    border-radius: 3px;
    border-width: 1px;
    border-color: #2f559c;
    color: #2f559c;
    border-style: solid;
    align-items: center;
    justify-content: center;
  `,
  ButtonCallTitle: styled.span`
    font-size: 14px;
    color: #2f559c;
  `,
  Separator: styled.div`
    width: 10px;
    display: flex;
  `,
  ButtonNavigateContainer: styled.button`
    flex: 1;
    height: 40px;
    border-radius: 3px;
    background-color: #2f559c;
    align-items: center;
    justify-content: center;
  `,
  ButtonNavigateTitle: styled.span`
    font-size: 14px;
    color: #ffffff;
  `,
  SeparatorCont: styled.div`
    width: 100%;
    height: 5px;
    background: lightGrey;
    margin: 20px 0;
  `
}

export default homeStyle
