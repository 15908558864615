import Layout from 'components/layout'
import {
  NotificationProps,
  NotificationTable
} from 'components/notification-table'
import { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { api } from 'services/api'
import { formatDateToSend } from 'utils/formatDate'
import { generateRequestConfig } from 'utils/requestConfig'

export const NotificationsPage = () => {
  const [scheduled, setScheduled] = useState<boolean>(false)
  const [loadingNotifications, setLoadingNotifications] =
    useState<boolean>(false)
  const [notifications, setNotifications] = useState<NotificationProps[]>([])
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState<{
    title: string
    subtitle: string
    selectPage: string
    order: string
    categoryId?: string
    brandId?: string
    collectionsIds?: string
    productId?: string
    dateTime?: string
  }>({
    title: '',
    subtitle: '',
    selectPage: 'home',
    order: 'OrderByNameDESC'
  })

  const sendNotification = async () => {
    const {
      order,
      selectPage,
      subtitle,
      title,
      brandId,
      categoryId,
      collectionsIds,
      productId,
      dateTime
    } = state

    if (!title.trim()) {
      return alert('Informe o Título')
    } else if (!subtitle.trim()) {
      return alert('Informe a Descrição')
    } else if (!selectPage.trim()) {
      return alert('Informe a Página')
    } else if (!order.trim() && selectPage === 'productList') {
      return alert('Informe a Ordem')
    } else if (!productId?.trim() && selectPage === 'productDetails') {
      return alert('Informe o Id do Produto')
    }

    try {
      setLoading(true)

      const sendData = {
        title,
        description: subtitle,
        page: selectPage,
        metadata: {
          order,
          brandId,
          categoryId,
          collectionsIds,
          productId
        },
        ...(scheduled && { dateTime: formatDateToSend(dateTime!) })
      }

      await api.post(
        '/v2/notification',
        sendData,
        await generateRequestConfig()
      )
      setState({
        title: '',
        subtitle: '',
        selectPage: 'home',
        order: 'OrderByNameDESC'
      })
      alert('Notificação Enviada com Sucesso!')
      setLoading(false)
      getNotifications()
    } catch (e) {
      setLoading(false)
      alert('Erro ao enviar Notificação')
      console.log(e)
    }
  }

  const getNotifications = async () => {
    setLoadingNotifications(true)
    const response = await api.get(
      '/v2/notification',
      await generateRequestConfig()
    )
    setLoadingNotifications(false)
    setNotifications(response.data)
  }

  useEffect(() => {
    getNotifications()
  }, [])

  return (
    <Layout>
      <section className="w-full mx-auto flex gap-8">
        <div className="w-full bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Notificações
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Envie notificações promocionais ou com novidades.
            </p>
          </div>
          <div className="border-t border-gray-200">
            <dl className="bg-gray-50 m-3 border border-gray-200 rounded-md">
              <div className="flex gap-3 px-4 py-5">
                <div className="w-1/2 flex flex-col gap-3">
                  <div className="col-span-12 md:col-span-3">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Título
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      value={state.title}
                      onChange={(e) =>
                        setState({
                          ...state,
                          title: e.target.value
                        })
                      }
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-12 md:col-span-3">
                    <label
                      htmlFor="subtitle"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Descrição
                    </label>
                    <textarea
                      name="subtitle"
                      id="subtitle"
                      value={state.subtitle}
                      onChange={(e) =>
                        setState({
                          ...state,
                          subtitle: e.target.value
                        })
                      }
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <fieldset className="mt-3">
                    <legend className="block text-sm font-medium text-gray-700">
                      Deseja agendar o envio?
                    </legend>

                    <div className="flex mt-2 gap-2 items-center">
                      <input
                        type="checkbox"
                        id="scheduled"
                        name="scheduled"
                        checked={scheduled}
                        onChange={() => setScheduled(!scheduled)}
                      />
                      <label
                        className="block text-sm font-medium text-gray-700"
                        htmlFor="scheduled"
                      >
                        Sim
                      </label>
                    </div>
                  </fieldset>

                  {scheduled && (
                    <div className="col-span-12 md:col-span-3">
                      <label
                        htmlFor="dateTime"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Data do envio
                      </label>
                      <input
                        type="datetime-local"
                        name="dateTime"
                        id="dateTime"
                        value={state.dateTime}
                        onChange={(e) =>
                          setState({
                            ...state,
                            dateTime: e.target.value
                          })
                        }
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  )}
                </div>
                <div className="w-1/2 flex flex-col gap-3">
                  <div className="col-span-12 md:col-span-3">
                    <label
                      htmlFor="pageSelect"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Página
                    </label>
                    <select
                      name="pageSelect"
                      id="pageSelect"
                      value={state.selectPage}
                      onChange={(e) =>
                        setState({
                          ...state,
                          selectPage: e.target.value
                        })
                      }
                      placeholder="Selecione uma página"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="home">Home</option>
                      <option value="productList">Lista de Produtos</option>
                      <option value="productDetails">Página de Produto</option>
                    </select>
                  </div>

                  {state.selectPage === 'productList' && (
                    <>
                      <div className="col-span-12 md:col-span-3">
                        <label
                          htmlFor="orderSelect"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Ordem
                        </label>
                        <select
                          name="orderSelect"
                          id="orderSelect"
                          value={state.order}
                          onChange={(e) =>
                            setState({
                              ...state,
                              order: e.target.value
                            })
                          }
                          placeholder="Selecione uma página"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        >
                          <option value="OrderByPriceASC">Menor Preço</option>
                          <option value="OrderByPriceDESC">Maior Preço</option>
                          <option value="OrderByTopSaleDESC">
                            Mais Vendidos
                          </option>
                          <option value="OrderByBestDiscountDESC">
                            Maior Desconto
                          </option>
                          <option value="OrderByNameASC">Nome Ascedente</option>
                          <option value="OrderByNameDESC">
                            Nome Descendente
                          </option>
                        </select>
                      </div>
                      <div className="col-span-12 md:col-span-3">
                        <label
                          htmlFor="categoryId"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Id da Categoria
                        </label>
                        <input
                          type="text"
                          name="categoryId"
                          id="categoryId"
                          value={state.categoryId}
                          onChange={(e) =>
                            setState({
                              ...state,
                              categoryId: e.target.value
                            })
                          }
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-12 md:col-span-3">
                        <label
                          htmlFor="brandId"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Id da Marca
                        </label>
                        <input
                          type="text"
                          name="brandId"
                          id="brandId"
                          value={state.brandId}
                          onChange={(e) =>
                            setState({
                              ...state,
                              brandId: e.target.value
                            })
                          }
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-12 md:col-span-3">
                        <label
                          htmlFor="collectionsIds"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Id da Coleção
                        </label>
                        <input
                          type="text"
                          name="collectionsIds"
                          id="collectionsIds"
                          value={state.collectionsIds}
                          onChange={(e) =>
                            setState({
                              ...state,
                              collectionsIds: e.target.value
                            })
                          }
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </>
                  )}

                  {state.selectPage === 'productDetails' && (
                    <>
                      <div className="col-span-12 md:col-span-3">
                        <label
                          htmlFor="productId"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Id do Produto
                        </label>
                        <input
                          type="text"
                          name="productId"
                          id="productId"
                          value={state.productId}
                          onChange={(e) =>
                            setState({
                              ...state,
                              productId: e.target.value
                            })
                          }
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </>
                  )}

                  <div className="flex justify-end">
                    <button
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => sendNotification()}
                    >
                      {loading ? (
                        <ReactLoading
                          type="bars"
                          color="#ffffff"
                          height={20}
                          width={20}
                        />
                      ) : scheduled ? (
                        'Agendar Notificação'
                      ) : (
                        'Enviar Notificação'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </dl>
          </div>

          <NotificationTable
            notifications={notifications}
            loadingNotifications={loadingNotifications}
            getNotifications={getNotifications}
          />
        </div>
      </section>
    </Layout>
  )
}
