import { Filter, Home } from 'pages/custom-home/homeTypes'
import { useState } from 'react'
import { FilterModal } from '../filter-modal'
import { FilterPreivew } from '../filter-preview'

type ShelfProps = {
  home: Home
  onUpdate: () => void
}

export const ProductShelf = ({ home, onUpdate }: ShelfProps) => {
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false)
  const [filter, setFilter] = useState<Filter>(
    home.metadata.filters?.at(0) || {}
  )
  const [title, setTitle] = useState<string>(home.metadata.title)

  return (
    <div className="p-4 bg-white rounded-b-md">
      <div className="mb-8">
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Título *
        </label>
        <input
          type="text"
          name="title"
          id="title"
          value={title}
          onChange={(e) => {
            home.metadata.title = e.target.value
            setTitle(e.target.value)
            onUpdate()
          }}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <FilterPreivew
        filter={filter}
        onEditClick={() => {
          setFilterOpen(true)
        }}
      />
      <FilterModal
        isOpen={isFilterOpen}
        onCancel={function (): void {
          setFilterOpen(false)
        }}
        onSave={function (savedFilter: Filter): void {
          home.metadata.filters = [savedFilter]
          setFilter(savedFilter)
          setFilterOpen(false)
          onUpdate()
        }}
        currentFilter={filter}
      />
    </div>
  )
}
