export interface HeadingProps {
  title: string
  subtitle: string
}

export function Heading({ title, subtitle }: HeadingProps) {
  return (
    <div className="flex flex-col flex-auto mb-10">
      <p className="text-2xl font-semibold text-gray-900 tracking-tight leading-8">
        {title}
      </p>
      <p className="text-sm text-gray-900">{subtitle}</p>
    </div>
  )
}
