import { Category } from '../../../../categoriesTypes'

const CategoryItem = ({ category }: { category: Category }) => {
  return (
    <div className="">
      <img src={category?.image} />
    </div>
  )
}

export default CategoryItem
