import { useEffect, useState } from 'react'
import { HiExternalLink, HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'
import { useNavigate } from 'react-location'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { api } from 'services/api'
import { generateRequestConfig } from 'utils/requestConfig'

import Preview from './components/preview'

import { CategoryModal } from './components/modal'

import { Category } from './categoriesTypes'

import { Heading } from 'components'
import Layout from 'components/layout'
import * as Ui from './style'

export const CategoriesPage = () => {
  const navigate = useNavigate()
  const [stores, setStores] = useState<Category[]>([])
  const [showModal, setShowModal] = useState(false)
  const [editingCategory, setEditingCategory] = useState<Category>()
  const [loading, setLoading] = useState(true)
  const [lastIndex, setlastIndex] = useState(0)

  useEffect(() => {
    getStores()
  }, [])

  async function getStores() {
    try {
      setLoading(true)
      const { data } = await api.get<Category[]>(
        '/v1/categories',
        await generateRequestConfig()
      )

      if (data && data.length) {
        const strSorted = data.sort((a, b) => (a.order || 0) - (b.order || 0))

        setlastIndex(strSorted[strSorted.length - 1].order || 0)
        setStores(strSorted)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      alert('Erro ao consultar Lojas')
      console.log(e)
    }
  }

  async function updateOrder(stores: Category[]) {
    try {
      setLoading(true)
      await api.put(
        '/v1/categories/order',
        { categories: stores.map((s) => ({ id: s._id, order: s.order })) },
        await generateRequestConfig()
      )
      setLoading(false)
    } catch (e) {
      setLoading(false)
      alert('Erro ao atualizar Ordens')
      console.log(e)
    }
  }

  async function removeStore(store: Category) {
    if (window.confirm('Deseja excluir a loja: ' + store.title + '?')) {
      try {
        setLoading(true)
        await api.delete(
          '/v1/categories/' + store._id,
          await generateRequestConfig()
        )
        getStores()
        setLoading(false)
      } catch (e) {
        setLoading(false)
        alert('Erro ao excluir Loja')
        console.log(e)
      }
    }
  }

  const SortableItem = SortableElement(
    ({ value: store }: { value: Category }) => (
      <div className="relative bg-white shadow w-full rounded-lg group p-3">
        <p className="font-semibold mb-1">
          {store.id} - {store.title}
        </p>
        {!!store.link && (
          <>
            <a href={store.link} target="_blank" rel="noreferrer">
              Link Redirecionamento
            </a>
            <br />
          </>
        )}
        <button
          type="button"
          className="inline-flex justify-center py-2 text-sm font-medium text-primary hover:underline"
          onClick={() => {
            navigate({
              to: '/subcategories/' + store._id,
              search: { category: store }
            })
          }}
        >
          {store.children?.length
            ? `Sub-Categorias (${store.children?.length})`
            : 'Crie uma Sub-Categoria'}
        </button>
        <Ui.StoreImage src={store.image} />
        <button
          onClick={() => {
            setEditingCategory(store)
            setShowModal(true)
          }}
          disabled={loading}
          title="Editar"
          className="opacity-0 group-hover:opacity-100 absolute text-gray-500 right-1 top-1 h-8 w-8 bg-gray-200 hover:bg-gray-300 flex items-center justify-center rounded-full transition-all"
        >
          <HiOutlinePencil />
        </button>
        <button
          onClick={() => removeStore(store)}
          disabled={loading}
          title="Excluir"
          className="opacity-0 group-hover:opacity-100 absolute text-red-600 right-10 top-1 h-8 w-8 bg-red-100 hover:bg-red-200 flex items-center justify-center rounded-full transition-all"
        >
          <HiOutlineTrash />
        </button>
        {!!store.link && (
          <button
            onClick={() => {
              window.open(store.link, '_blank')
            }}
            disabled={loading}
            title="Abrir Link"
            className="opacity-0 group-hover:opacity-100 absolute text-blue-600 right-20 top-1 h-8 w-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded-full transition-all"
          >
            <HiExternalLink />
          </button>
        )}
      </div>
    )
  )

  const SortableList = SortableContainer(({ items }: { items: Category[] }) => {
    return (
      <div className="flex flex-col gap-y-3">
        {items.map((store: Category, index: number) => (
          <SortableItem key={`item-${store._id}`} index={index} value={store} />
        ))}
      </div>
    )
  })

  return (
    <Layout>
      <section className="w-full mx-auto">
        <Heading
          title="Categorias"
          subtitle="Gerencia as categorias que serão exibidas no menu Categorias."
        />

        <div className="flex gap-8">
          <div className="w-1/3">
            <button
              type="button"
              className="btn-primary"
              onClick={() => setShowModal(true)}
              disabled={loading}
            >
              Adicionar Categoria
            </button>
            <div className="border border-dashed border-gray-300 rounded-lg p-2">
              <SortableList
                items={stores}
                onSortEnd={(e) => {
                  if (!loading && e.newIndex != e.oldIndex) {
                    const strs = [...stores]
                    const item = strs.splice(e.oldIndex, 1)
                    strs.splice(e.newIndex, 0, item[0])
                    strs.forEach((s, i) => {
                      s.order = i + 1
                    })
                    console.log('STRS', strs)
                    updateOrder(strs)
                    setStores(strs)
                  }
                }}
              />
            </div>
          </div>

          <div className="w-2/3">
            <h2 className="text-center text-gray-300 font-black text-5xl">
              PREVIEW
            </h2>

            <Preview categories={stores} />
          </div>
        </div>
        <CategoryModal
          isOpen={showModal}
          onClose={(reload) => {
            setShowModal(false)
            setEditingCategory(undefined)
            if (reload) getStores()
          }}
          index={lastIndex}
          editingCategory={editingCategory}
        />
      </section>
    </Layout>
  )
}
