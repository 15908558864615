import styled from 'styled-components'

const homeStyle = {
  Container: styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 17px 0;
    background: #fffffff;
    padding-right: 5px;
  `,
  Touchable: styled.button``,
  IconContainer: styled.div`
    display: flex;
    padding: 0 13px;
  `,
  TitleContainer: styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
  `,
  TitleText: styled.span`
    font-weight: bold;
    font-size: 16px;
    color: #637390;
  `,
  SubTitleText: styled.span`
    font-weight: bold;
    font-size: 12px;
    color: #777;
  `,
  ScrollContainer: styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    overflow: auto;
  `
}

export default homeStyle
