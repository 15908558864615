import { Filter } from 'pages/custom-home/homeTypes'
import { useState } from 'react'
import { OrderTypes } from 'utils/orderTypes'
import DeleteIcon from '../../../../assets/delete_48.svg'
import EditIcon from '../../../../assets/edit_48.svg'
import { ConfirmModal } from '../confirm-modal'

type FilterPreivewProps = {
  filter: Filter
  canDelete?: boolean
  onEditClick: () => void
  onDeleteClick?: () => void
}

export const FilterPreivew = ({
  filter,
  onEditClick: onEdit,
  onDeleteClick: onDelete = undefined,
  canDelete = false
}: FilterPreivewProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const handleDelete = () => {
    setShowDeleteModal(false)
    if (!filter) return
    if (onDelete) onDelete()
  }

  const renderFilterOption = (label: string, description?: string) => (
    <p className="overflow-ellipsis text-black w-52 min-w-max text-sm">
      <b className="font-semibold">{`${label}: `}</b>
      {`${description ?? '(vazio)'}`}
    </p>
  )

  const editClass = canDelete ? 'rounded-t-md h-1/2' : 'rounded-md h-full'

  return (
    <div className="select-none p-3 border-dashed border border-gray-400 rounded-md relative w-60">
      <h3 className="text-black pb-1 mb-3 border-b">Filtros</h3>
      <div
        className={`absolute hover:bg-gray-600 hover:opacity-70 opacity-0 cursor-pointer top-0 left-0 w-full flex flex-col items-center justify-center ${editClass}`}
        onClick={() => {
          onEdit()
        }}
      >
        <img src={EditIcon} />
      </div>
      {canDelete ? (
        <div
          className="absolute hover:bg-red-600 rounded-b-md hover:opacity-70 opacity-0 cursor-pointer bottom-0 left-0 w-full h-1/2 flex flex-col items-center justify-center"
          onClick={() => {
            setShowDeleteModal(true)
          }}
        >
          <img src={DeleteIcon} />
        </div>
      ) : (
        <></>
      )}
      <div className="w-52 overflow-hidden overflow-ellipsis">
        {renderFilterOption('Título', filter.title)}
        {renderFilterOption('Ordem', OrderTypes[filter.order ?? ''])}
        {renderFilterOption('ID da Categoria', filter.category)}
        {renderFilterOption('ID da Marca', filter.brand)}
        {renderFilterOption(
          'ID da Coleção',
          Array.isArray(filter.collectionsIds)
            ? filter.collectionsIds?.reduce((a, b) => `${a}, ${b}`)
            : filter.collectionsIds
        )}
        {renderFilterOption(
          'IDs de produtos',
          filter.productsIds?.reduce((a, b) => `${a}, ${b}`)
        )}
        {renderFilterOption(
          'IDs de SKU',
          filter.skusIds?.reduce((a, b) => `${a}, ${b}`)
        )}
      </div>
      <ConfirmModal
        isOpen={showDeleteModal}
        message="Tem certeza que deseja excluir este item?"
        negativeText="Não"
        positiveText="Sim"
        onNegative={() => {
          setShowDeleteModal(false)
        }}
        onPositive={handleDelete}
      />
    </div>
  )
}
