import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  DataGrid,
  GridActionsCellItem,
  GridRenderCellParams
} from '@mui/x-data-grid'
import { SetStateAction, useEffect, useState } from 'react'
import ReactLoading from 'react-loading'

import { api } from 'services/api'
import { generateRequestConfig } from 'utils/requestConfig'

import { CategoryModal } from './components/modal'

import Layout from 'components/layout'
import { Page } from './productPageTypes'

export const ProductPagesPage = () => {
  const [stores, setStores] = useState<Page[]>([])
  const [showModal, setShowModal] = useState(false)
  const [editingCategory, setEditingCategory] = useState<Page>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getPages()
  }, [])

  async function getPages() {
    try {
      setLoading(true)
      const { data } = await api.get<Page[]>(
        '/v1/customProductPage',
        await generateRequestConfig()
      )

      if (data && data.length) {
        const strSorted = data

        setStores(strSorted)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      alert('Erro ao consultar Paginas')
      console.log(e)
    }
  }

  async function removePage(store: Page) {
    if (window.confirm('Deseja excluir a Pagina: ' + store.name + '?')) {
      try {
        setLoading(true)
        await api.delete(
          '/v1/customProductPage/' + store.id,
          await generateRequestConfig()
        )
        getPages()
        setLoading(false)
      } catch (e) {
        setLoading(false)
        alert('Erro ao excluir Pagina')
        console.log(e)
      }
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 300 },
    { field: 'coupon', headerName: 'Cupom', width: 200 },
    { field: 'name', headerName: 'Nome', width: 300 },
    {
      field: 'banner',
      headerName: 'Banner',
      width: 200,
      renderCell: (params: GridRenderCellParams<string>) => (
        <a
          href={params.value}
          target="_blank"
          rel="noreferrer"
          className="text-blue-700"
        >
          Abrir
          <OpenInNewIcon className="ml-2" />
        </a>
      )
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params: { row: SetStateAction<Page | undefined> }) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Deletar"
          key="1"
          onClick={() => {
            if (params.row) removePage(params.row as Page)
          }}
        />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Editar"
          key="2"
          onClick={() => {
            setEditingCategory(params.row)
            setShowModal(true)
          }}
        />
      ]
    }
  ]

  return (
    <Layout>
      <section className="w-full mx-auto flex gap-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg w-full">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Páginas de Produtos
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Gerencia as Páginas de produto.
            </p>
          </div>
          <button
            type="button"
            className="inline-flex justify-center ml-4 mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setShowModal(true)}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading
                type="bars"
                color="rgba(79, 70, 229, var(--tw-border-opacity))"
                height={30}
                width={30}
              />
            ) : (
              'Adicionar'
            )}
          </button>
          <div style={{ height: 400 }} className="px-4">
            <DataGrid
              rows={stores}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </div>
        </div>
        <CategoryModal
          isOpen={showModal}
          onClose={(reload) => {
            setShowModal(false)
            setEditingCategory(undefined)
            if (reload) getPages()
          }}
          index={0}
          editingCategory={editingCategory}
        />
      </section>
    </Layout>
  )
}
