export const convertStatus = (status: string) => {
  let convertedStatus = null

  switch (status) {
    case 'sent':
      convertedStatus = (
        <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
          Enviado
        </span>
      )
      break

    case 'failed':
      convertedStatus = (
        <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
          Falhou
        </span>
      )
      break

    case 'canceled':
      convertedStatus = (
        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
          Cancelado
        </span>
      )
      break

    case 'scheduled':
      convertedStatus = (
        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
          Agendado
        </span>
      )
      break

    case 'warning':
      convertedStatus = (
        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
          Enviado
        </span>
      )
      break

    default:
      break
  }

  return convertedStatus
}
