import { Brand, Home } from 'pages/custom-home/homeTypes'
import { useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable
} from 'react-beautiful-dnd'
import DeleteIcon from '../../../../assets/delete_48.svg'
import EditIcon from '../../../../assets/edit_48.svg'
import { ConfirmModal } from '../confirm-modal'
import { BrandsModal } from './components/modal'

type BrandsCompProps = {
  home: Home
  onChange: (brand?: Brand, newImage?: File) => void
}

export const BrandsComp = ({ home, onChange }: BrandsCompProps) => {
  const [dragging, setDragging] = useState<boolean>(false)
  const [state, setState] = useState<Home>(home)

  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [brandNewImage, setBrandNewImage] = useState<{
    [key: string]: string
  }>({})
  const [selectedBrand, setSelectedBrand] = useState<Brand | undefined>(
    undefined
  )

  const handleDelete = () => {
    setShowDeleteModal(false)
    if (!selectedBrand) return
    home.metadata.brands?.splice(
      home.metadata.brands?.indexOf(selectedBrand),
      1
    )
    onChange(undefined, undefined)
  }

  const handleDrop = (result: DropResult) => {
    setDragging(false)
    if (!result.destination) return
    const updatedList = [...(home.metadata.brands ?? [])]
    const [reorderedItem] = updatedList.splice(result.source.index, 1)
    updatedList.splice(result.destination.index, 0, reorderedItem)
    if (
      !home.metadata.brands?.every((val, index) => val === updatedList[index])
    ) {
      home.metadata.brands = updatedList
      setState({
        ...home,
        metadata: {
          ...home.metadata,
          brands: updatedList
        }
      })
      onChange(undefined, undefined)
    }
  }

  const handleStart = () => setDragging(true)

  const onSave = (
    oldId: number,
    brand: Brand,
    newImage?: { file: File; b64: string }
  ) => {
    setShowEditModal(false)
    if (oldId != brand.id && brandNewImage[oldId]) {
      brandNewImage[brand.id] = brandNewImage[oldId]
      brandNewImage[oldId] = ''
    }
    const index = home?.metadata?.brands?.findIndex((b) => {
      return b.id == oldId
    })
    if (index != undefined && index >= 0) {
      home?.metadata?.brands?.splice(index, 1, brand)
    } else {
      home?.metadata?.brands?.push(brand)
    }
    if (newImage) {
      brandNewImage[brand.id] = newImage.b64
      setBrandNewImage(brandNewImage)
    }
    onChange(brand, newImage?.file)
  }

  return (
    <div className="p-4 bg-white rounded-b-md">
      <DragDropContext onDragEnd={handleDrop} onDragStart={handleStart}>
        <div className="mb-2">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Título *
          </label>
          <input
            type="text"
            name="title"
            id="title"
            value={state.metadata.title}
            onChange={(e) => {
              home.metadata.title = e.target.value
              setState({
                ...home,
                metadata: {
                  ...home.metadata,
                  title: e.target.value
                }
              })
              onChange(selectedBrand)
            }}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <Droppable droppableId="list-container" direction="horizontal">
          {(provided) => (
            <div
              className="flex overflow-auto gap-2 px-2"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {state.metadata?.brands?.map((brand, index) => (
                <Draggable
                  key={brand.id}
                  draggableId={brand.id.toString()}
                  index={index}
                >
                  {(provided: DraggableProvided) => (
                    <div
                      className="static mr-2 pb-4 flex flex-col select-none items-center justify-center"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <div>
                        <div className="flex flex-row object-cover h-12 w-24 items-center justify-center">
                          <div
                            className="justify-center flex bg-indigo-600 h-7 w-14 hover:opacity-80 cursor-pointer"
                            onClick={() => {
                              setSelectedBrand(brand)
                              setShowEditModal(true)
                            }}
                          >
                            <img src={EditIcon} className="h-7 w-7 p-1" />
                          </div>
                          <div
                            className="justify-center flex bg-red-500 h-7 w-14 hover:opacity-80 cursor-pointer"
                            onClick={() => {
                              setSelectedBrand(brand)
                              setShowDeleteModal(true)
                            }}
                          >
                            <img src={DeleteIcon} className="h-7 w-7 p-1" />
                          </div>
                        </div>
                        <img
                          src={brandNewImage[brand.id] ?? brand.image}
                          className="object-scale-down h-12 w-24 justify-center"
                        />
                      </div>
                      <p className="mt-5 overflow-ellipsis overflow-hidden whitespace-nowrap">
                        {brand.name}
                      </p>
                    </div>
                  )}
                </Draggable>
              ))}

              {dragging ? (
                <></>
              ) : (
                <div
                  className="flex flex-col pb-4 h-24 w-24 justify-center items-center self-center"
                  onClick={() => {
                    setSelectedBrand(undefined)
                    setShowEditModal(true)
                  }}
                >
                  <div className="flex justify-center cursor-pointer hover:bg-gray-300 items-center h-16 w-16 border-dashed border-2 border-indigo-900 opacity-70 rounded-full">
                    <svg
                      className="h-12 w-12"
                      fill="#AAAAAA"
                      viewBox="0 0 24 24"
                    >
                      <path d="M19,13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                    </svg>
                  </div>
                </div>
              )}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <BrandsModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        brand={selectedBrand}
        selectedImage={brandNewImage[selectedBrand?.id ?? '']}
        onSave={onSave}
      />
      <ConfirmModal
        isOpen={showDeleteModal}
        message="Tem certeza que deseja excluir esta marca?"
        negativeText="Não"
        positiveText="Sim"
        onNegative={() => {
          setShowDeleteModal(false)
        }}
        onPositive={handleDelete}
      />
    </div>
  )
}
