import { Store } from 'pages/stores/storeTypes'

import Header from './Components/Header'
import StoreItem from './Components/StoreItem'

import Ui from './style'

type PreviewProps = {
  stores: Store[]
}

const OurStores = ({ stores }: PreviewProps) => {
  return (
    <Ui.Container>
      <Header />
      <Ui.ScrollContainer>
        {stores.map((x: Store, index: number) => (
          <StoreItem data={x} key={`${index}-store-item-preview`} />
        ))}
      </Ui.ScrollContainer>
    </Ui.Container>
  )
}

export default OurStores
