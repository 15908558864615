import { Category } from 'pages/subcategories/categoriesTypes'
import Header from './Components/Header'
import StoreItem from './Components/StoreItem'

import Ui from './style'

type PreviewProps = {
  stores: Category[]
}

const OurStores = ({ stores }: PreviewProps) => {
  return (
    <Ui.Container>
      <Header />
      <Ui.ScrollContainer>
        {stores.map((x: Category, index: number) => (
          <StoreItem data={x} key={`${index}-store-item-preview`} />
        ))}
      </Ui.ScrollContainer>
    </Ui.Container>
  )
}

export default OurStores
