import { Filter } from 'pages/custom-home/homeTypes'

type FilterGridPreviewProps = {
  filter: Filter
  bgColor: string
}

export const FilterGridFilterPreview = ({
  filter,
  bgColor
}: FilterGridPreviewProps) => {
  return (
    <p
      className="px-2 py-2 h-10 w-full overflow-hidden text-center text-black select-none"
      style={{ background: `${bgColor}` }}
    >
      {filter.title}
    </p>
  )
}
