import Logo from '../../assets/omnion.svg'

export const Loading = () => {
  return (
    <div className="h-screen bg-gray-900 flex flex-col justify-center items-center">
      <img className="mx-auto h-16 w-auto" src={Logo} alt="Workflow" />
      <div
        className="
      animate-spin
      rounded-full
      h-11
      w-11
      border-t-2 border-b-2 border-purple-500
    "
      ></div>
    </div>
  )
}
