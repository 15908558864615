import { FilterGridFilter, Home } from 'pages/custom-home/homeTypes'
import { useState } from 'react'
import { SortEnd, SortableContainer, SortableElement } from 'react-sortable-hoc'
import { newObjectID } from 'utils/customHome'
import DeleteIcon from '../../../../assets/delete_48.svg'
import EditIcon from '../../../../assets/edit_48.svg'
import { ConfirmModal } from '../confirm-modal'
import { FilterGridFilterModal } from './components/modal'
import { FilterGridFilterPreview } from './components/preview'

type FilterGridProps = {
  home: Home
  onUpdate: () => void
}

export const FilterGridComp = ({ home, onUpdate }: FilterGridProps) => {
  const [items, setItems] = useState<FilterGridFilter[] | undefined>(
    home.metadata.filterGrid
  )
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [selectedFilterGriFilter, setSelectedFilterGridFilter] = useState<
    FilterGridFilter | undefined
  >(undefined)
  const [title, setTitle] = useState<string>(home.metadata.title)

  const createNewItem = (): FilterGridFilter => ({
    bgColor: '#e6e6e6',
    filter: { title: 'Novo filtro' },
    id: newObjectID()
  })

  const handleDelete = () => {
    setShowDeleteModal(false)
    if (!selectedFilterGriFilter) return
    home.metadata.filterGrid?.splice(
      home.metadata.filterGrid?.indexOf(selectedFilterGriFilter),
      1
    )
    onUpdate()
  }

  const handleDrop = (sort: SortEnd) => {
    if (sort.newIndex === sort.oldIndex) return
    const updatedList = [...(home.metadata.filterGrid ?? [])]
    const [reorderedItem] = updatedList.splice(sort.oldIndex, 1)
    updatedList.splice(sort.newIndex, 0, reorderedItem)
    if (
      !home.metadata.filterGrid?.every(
        (val, index) => val === updatedList[index]
      )
    ) {
      setItems(updatedList)
      home.metadata.filterGrid = updatedList
      onUpdate()
    }
  }

  const onSave = (filterGridFilter: FilterGridFilter) => {
    setShowEditModal(false)
    const index = home?.metadata?.filterGrid?.findIndex((fgf) => {
      return fgf.id == filterGridFilter.id
    })
    if (index != undefined && index >= 0) {
      home?.metadata?.filterGrid?.splice(index, 1, filterGridFilter)
    } else {
      home?.metadata?.filterGrid?.push(filterGridFilter)
    }
    onUpdate()
  }

  type ItemProps = {
    item: FilterGridFilter
  }

  const SortableItem = SortableElement(({ item }: ItemProps) => (
    <div className="cursor-move relative">
      {renderDelete(item)}
      {renderEdit(item)}
      <FilterGridFilterPreview bgColor={item.bgColor} filter={item.filter} />
    </div>
  ))

  type ListProps = {
    items: FilterGridFilter[]
  }

  const SortableList = SortableContainer(({ items }: ListProps) => (
    <div className="grid grid-cols-2 gap-2 w-full auto-rows-min">
      {items.map((value, index) => (
        <SortableItem key={`item-${value}`} index={index} item={value} />
      ))}
    </div>
  ))

  const renderDelete = (item: FilterGridFilter) => (
    <div
      className="select-none justify-center items-center absolute h-full flex bg-red-500 w-1/2 opacity-0 hover:opacity-80 cursor-pointer"
      onClick={() => {
        setSelectedFilterGridFilter(item)
        setShowDeleteModal(true)
      }}
    >
      <img src={DeleteIcon} className="h-7 w-7" draggable={false} />
    </div>
  )

  const renderEdit = (item: FilterGridFilter) => (
    <div
      className="justify-center items-center right-0 absolute h-full flex bg-blue-500 w-1/2 opacity-0 hover:opacity-80 cursor-pointer"
      onClick={() => {
        setSelectedFilterGridFilter(item)
        setShowEditModal(true)
      }}
    >
      <img src={EditIcon} className="h-7 w-7" draggable={false} />
    </div>
  )

  const renderFilterGrid = (items: FilterGridFilter[]) => (
    <SortableList
      axis="xy"
      items={items}
      distance={3}
      onSortStart={() => {
        document.body.style.overflow = 'hidden'
      }}
      onSortEnd={(sort) => {
        document.body.style.overflow = 'auto'
        handleDrop(sort)
      }}
    />
  )

  const renderAddFilter = () => (
    <div
      className="flex flex-col h-14 w-72 justify-center self-center mb-3"
      onClick={() => {
        setSelectedFilterGridFilter(createNewItem())
        setShowEditModal(true)
      }}
    >
      <div
        className="
      flex justify-center
      cursor-pointer
      self-center
      hover:bg-gray-300
      items-center
      h-10 w-10
      border-dashed border-2
      border-indigo-900
      opacity-70
      rounded-full
    "
      >
        <svg className="h-8 w-8" fill="#222c85" viewBox="0 0 24 24">
          <path d="M19,13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
        </svg>
      </div>
    </div>
  )

  const renderTitleInput = () => (
    <div className="mb-2">
      <label
        htmlFor="title"
        className="block text-sm font-medium text-gray-700"
      >
        Título
      </label>
      <input
        type="text"
        name="title"
        id="title"
        value={title}
        onChange={(e) => {
          home.metadata.title = e.target.value
          setTitle(e.target.value)
          onUpdate()
        }}
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />
    </div>
  )

  return (
    <section>
      <div className="flex flex-col w-full justify-center p-4 bg-white rounded-b-md">
        {renderTitleInput()}
        {renderAddFilter()}
        <div className="flex w-72 self-center">
          {renderFilterGrid(items ?? [])}
        </div>
      </div>

      {selectedFilterGriFilter ? (
        <FilterGridFilterModal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          filterGridFilter={selectedFilterGriFilter}
          onSave={onSave}
        />
      ) : (
        <></>
      )}
      <ConfirmModal
        isOpen={showDeleteModal}
        message="Tem certeza que deseja excluir este item?"
        negativeText="Não"
        positiveText="Sim"
        onNegative={() => {
          setShowDeleteModal(false)
        }}
        onPositive={handleDelete}
      />
    </section>
  )
}
