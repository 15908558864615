import { ChangeEvent, useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import Modal from 'react-modal'

import { Page } from '../../productPageTypes'

import { api } from 'services/api'
import { upload } from 'services/storage'

import { generateRequestConfig } from 'utils/requestConfig'

import { centeredModal } from 'utils/modalStyles'
import * as Ui from './style'
//adjus
type ModalProps = {
  isOpen: boolean
  onClose: (reload?: boolean) => void
  index: number
  editingCategory?: Page
}
export const CategoryModal = ({
  isOpen,
  onClose,
  editingCategory
}: ModalProps) => {
  const [pageId, setPageId] = useState('')

  const [pageName, setPageName] = useState('')
  const [pageCoupon, setPageCoupon] = useState('')

  const [loading, setLoading] = useState(false)

  const [imageAsFile, setImageAsFile] = useState<File | undefined>()

  const [state, setState] = useState<{
    order: string
    categoryId?: string
    brandId?: string
    collectionsIds?: string
    productsIds?: string
    skusIds?: string
  }>({ order: 'OrderByNameDESC' })

  useEffect(() => {
    if (editingCategory) {
      setPageName(editingCategory.name)
      setPageCoupon(editingCategory.coupon || '')
      setPageId(editingCategory.id)
      setState({
        order: editingCategory.filters?.order || 'OrderByNameDESC',
        brandId: editingCategory.filters?.category,
        categoryId: editingCategory.filters?.category,
        collectionsIds: editingCategory.filters?.collectionsIds?.reduce(
          (a: string, b: string) => `${a},${b}`
        ),
        productsIds: editingCategory.filters?.productsIds?.reduce(
          (a: string, b: string) => `${a},${b}`
        ),
        skusIds: editingCategory.filters?.skusIds?.reduce(
          (a: string, b: string) => `${a},${b}`
        )
      })
    } else {
      resetStates()
    }
  }, [editingCategory])

  const handleImageAsFile = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files ? e.target.files[0] : undefined
    setImageAsFile(() => image)
  }

  const resetStates = () => {
    setPageName('')
    setPageCoupon('')
    setPageId('')
    setState({ order: 'OrderByNameDESC' })
    setImageAsFile(undefined)
  }

  const saveStore = async () => {
    if (!pageName) {
      return alert('Informe o Nome da Página')
    }

    try {
      setLoading(true)

      const image = imageAsFile ? await upload(imageAsFile) : undefined

      const filters = {
        order: state.order,
        brand: state.brandId || undefined,
        category: state.categoryId || undefined,
        collectionsIds:
          state.collectionsIds?.split(',').map((x) => x.trim()) || undefined,
        productsIds:
          state.productsIds?.split(',').map((x) => x.trim()) || undefined,
        skusIds: state.skusIds?.split(',').map((x) => x.trim()) || undefined
      }

      const data = {
        coupon: pageCoupon,
        banner: image,
        name: pageName,
        id: pageId,
        filters
      }

      if (editingCategory)
        await api.put(
          '/v1/customProductPage/' + editingCategory.id,
          data,
          await generateRequestConfig()
        )
      else
        await api.post(
          '/v1/customProductPage',
          data,
          await generateRequestConfig()
        )

      setLoading(false)
      resetStates()
      onClose(true)
    } catch (err) {
      setLoading(false)
      alert('Erro ao incluir a Loja')
      console.log(err)
    }
  }

  return (
    <Modal isOpen={isOpen} style={centeredModal}>
      <div className="w-full h-full bg-blue flex flex-col">
        <div className="pb-4 border-b border-gray-200 mb-5 flex justify-end">
          <button
            className="text-lg leading-6 font-medium text-gray-900"
            onClick={() => onClose()}
          >
            Fechar
          </button>
        </div>
        <div className="flex flex-col flex-1">
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-name" className="mb-4 font-bold">
              Nome da Página
            </Ui.FormTitle>
            <input
              id="store-name"
              name="categoryName"
              type="text"
              required
              value={pageName}
              onChange={(e) => setPageName(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o nome da Página"
            />
          </div>
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-address" className="mb-4 font-bold">
              Cupom
            </Ui.FormTitle>
            <input
              id="store-address"
              name="categoryDescription"
              type="text"
              required
              value={pageCoupon}
              onChange={(e) => setPageCoupon(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o cupom se houver"
            />
          </div>

          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-phone" className="mb-4 font-bold">
              Imagem
            </Ui.FormTitle>
            <input
              id="store-image"
              name="storeImage"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              required
              onChange={handleImageAsFile}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o telefone da Loja"
            />
          </div>

          <Ui.FormTitle htmlFor="store-name" className="mb-4 font-bold">
            Filtros
          </Ui.FormTitle>
          <div className="grid grid-cols-6 gap-6 px-4 py-5 bg-white rounded-b-md">
            <div className="col-span-12 md:col-span-3">
              <label
                htmlFor="orderSelect"
                className="block text-sm font-medium text-gray-700"
              >
                Ordem
              </label>
              <select
                name="orderSelect"
                id="orderSelect"
                value={state.order}
                onChange={(e) =>
                  setState({
                    ...state,
                    order: e.target.value
                  })
                }
                placeholder="Selecione uma página"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              >
                <option value="OrderByPriceASC">Menor Preço</option>
                <option value="OrderByPriceDESC">Maior Preço</option>
                <option value="OrderByTopSaleDESC">Mais Vendidos</option>
                <option value="OrderByBestDiscountDESC">Maior Desconto</option>
                <option value="OrderByNameASC">Nome Ascedente</option>
                <option value="OrderByNameDESC">Nome Descendente</option>
              </select>
            </div>
            <div className="col-span-12 md:col-span-3">
              <label
                htmlFor="categoryId"
                className="block text-sm font-medium text-gray-700"
              >
                Id da Categoria
              </label>
              <input
                type="text"
                name="categoryId"
                id="categoryId"
                value={state.categoryId}
                onChange={(e) =>
                  setState({
                    ...state,
                    categoryId: e.target.value
                  })
                }
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <label
                htmlFor="brandId"
                className="block text-sm font-medium text-gray-700"
              >
                Id da Marca
              </label>
              <input
                type="text"
                name="brandId"
                id="brandId"
                value={state.brandId}
                onChange={(e) =>
                  setState({
                    ...state,
                    brandId: e.target.value
                  })
                }
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <label
                htmlFor="collectionsIds"
                className="block text-sm font-medium text-gray-700"
              >
                Ids das Coleções (Separados por vírgula)
              </label>
              <input
                type="text"
                name="collectionsIds"
                id="collectionsIds"
                value={state.collectionsIds}
                onChange={(e) =>
                  setState({
                    ...state,
                    collectionsIds: e.target.value
                  })
                }
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <label
                htmlFor="productsIds"
                className="block text-sm font-medium text-gray-700"
              >
                Ids de Produtos (Separados por vírgula)
              </label>
              <input
                type="text"
                name="productsIds"
                id="productsIds"
                value={state.productsIds}
                onChange={(e) =>
                  setState({
                    ...state,
                    productsIds: e.target.value
                  })
                }
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <label
                htmlFor="skusIds"
                className="block text-sm font-medium text-gray-700"
              >
                Ids de Skus (Separados por vírgula)
              </label>
              <input
                type="text"
                name="skusIds"
                id="skusIds"
                value={state.skusIds}
                onChange={(e) =>
                  setState({
                    ...state,
                    skusIds: e.target.value
                  })
                }
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
        <div className="pt-4 border-t border-gray-200 mt-5 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => saveStore()}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading type="bars" color="#fff" height={20} width={20} />
            ) : (
              'Salvar'
            )}
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onClose()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}
