import { Category } from 'pages/subcategories/categoriesTypes'
import Ui from './style'

type StoreItemProps = {
  data: Category
}

const StoreItem = ({ data }: StoreItemProps) => {
  return (
    <Ui.Container>
      <Ui.InfosContainer>
        <Ui.StoreImage src={data.image} />
        <Ui.StoreInfoContainer>
          <Ui.TitleText>{data.title}</Ui.TitleText>
          {/* <Ui.DescriptionText>{data.address}</Ui.DescriptionText> */}

          <Ui.DescriptionText>
            <Ui.DescriptionBoldText>
              Horário de Funcionamento
            </Ui.DescriptionBoldText>
          </Ui.DescriptionText>

          {/* <Ui.DescriptionText>{data.spedient}</Ui.DescriptionText> */}
        </Ui.StoreInfoContainer>
      </Ui.InfosContainer>
      <Ui.ButtonsContainer>
        <Ui.ButtonCallContainer>
          <Ui.ButtonCallTitle>LIGAR</Ui.ButtonCallTitle>
        </Ui.ButtonCallContainer>
        <Ui.Separator />
        <Ui.ButtonNavigateContainer>
          <Ui.ButtonNavigateTitle>NAVEGAR</Ui.ButtonNavigateTitle>
        </Ui.ButtonNavigateContainer>
      </Ui.ButtonsContainer>
      <Ui.SeparatorCont />
    </Ui.Container>
  )
}

export default StoreItem
