export const formatDate = (originalDate: string) => {
  const data = new Date(originalDate)

  const dia = String(data.getDate()).padStart(2, '0')
  const mes = String(data.getMonth() + 1).padStart(2, '0') // Meses são baseados em zero
  const ano = String(data.getFullYear()).slice(-2)
  const horas = String(data.getHours()).padStart(2, '0')
  const minutos = String(data.getMinutes()).padStart(2, '0')

  const formattedDate = `${dia}/${mes}/${ano} ${horas}:${minutos}`

  return formattedDate
}

export const formatDateToSend = (inputDate: string) => {
  const date = new Date(inputDate)
  const formattedDate = date.toISOString()

  return formattedDate
}
