import { PencilAltIcon, UserRemoveIcon } from '@heroicons/react/solid'
import Layout from 'components/layout'
import { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'

import { api } from 'services/api'
import { generateRequestConfig } from 'utils/requestConfig'

import { UserModal } from './components/modal'

import { User, Users } from './usersPageTypes'

export const UsersPage = () => {
  const [users, setUsers] = useState<User[]>([])
  const [showModal, setShowModal] = useState(false)
  const [editingUser, setEditingUser] = useState<User>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getUsers()
  }, [])

  async function getUsers() {
    try {
      setLoading(true)
      const { data } = await api.get<Users>(
        '/v1/admin-users',
        await generateRequestConfig()
      )

      if (data?.admins && data?.admins.length) {
        const strSorted = data?.admins

        setUsers(strSorted)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      alert('Erro ao consultar usuários')
      console.log(e)
    }
  }

  async function removePage(user: User) {
    if (window.confirm('Deseja excluir o usuário: ' + user.email + '?')) {
      try {
        setLoading(true)
        await api.delete(
          '/v1/admin-users/' + user.email,
          await generateRequestConfig()
        )
        getUsers()
        setLoading(false)
      } catch (e) {
        setLoading(false)
        alert('Erro ao excluir usuário')
        console.log(e)
      }
    }
  }

  return (
    <Layout>
      <section className="w-full mx-auto flex gap-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg w-full">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Usuários
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Gerencia os usuários do sistema.
            </p>
          </div>

          <button
            type="button"
            className="inline-flex justify-center ml-6 mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setShowModal(true)}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading
                type="bars"
                color="rgba(79, 70, 229, var(--tw-border-opacity))"
                height={30}
                width={30}
              />
            ) : (
              'Adicionar'
            )}
          </button>

          <div className="relative overflow-x-auto p-6">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    E-mail
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nível
                  </th>
                  <th scope="col" className="px-6 py-3 w-44 text-center">
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr
                    key={user.email}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {user.email}
                    </th>
                    <td className="px-6 py-4">
                      {user.isManager ? (
                        <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                          admin
                        </span>
                      ) : (
                        <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                          normal
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 w-44 text-center">
                      <button
                        onClick={() => {
                          setEditingUser(user)
                          setShowModal(true)
                        }}
                        disabled={loading}
                        className="w-7 h-7 p-1 rounded-full hover:bg-gray-200 duration-200 transition-all"
                      >
                        <PencilAltIcon />
                      </button>

                      <button
                        onClick={() => {
                          removePage(user)
                        }}
                        disabled={loading}
                        className="w-7 h-7 p-1 rounded-full hover:bg-gray-200 duration-200 transition-all"
                      >
                        <UserRemoveIcon />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <UserModal
          isOpen={showModal}
          onClose={(reload) => {
            setShowModal(false)
            setEditingUser(undefined)
            if (reload) getUsers()
          }}
          index={0}
          editingUser={editingUser}
        />
      </section>
    </Layout>
  )
}
