import { useState, useEffect } from 'react'
import ReactLoading from 'react-loading'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { generateRequestConfig } from 'utils/requestConfig'
import { api } from 'services/api'

import Preview from './components/preview'

import { StoreModal } from './components/modal'

import { Store } from './storeTypes'

import { Button } from 'components/button'
import Layout from 'components/layout'

import * as Ui from './style'

export const StoresPage = () => {
  const [stores, setStores] = useState<Store[]>([])
  const [showModal, setShowModal] = useState(false)
  const [editingStore, setEditingStore] = useState<Store>()
  const [loading, setLoading] = useState(true)
  const [lastIndex, setlastIndex] = useState(0)

  useEffect(() => {
    getStores()
  }, [])

  async function getStores() {
    try {
      setLoading(true)
      const { data } = await api.get<Store[]>(
        '/v1/stores',
        await generateRequestConfig()
      )

      if (data && data.length) {
        const strSorted = data.sort((a, b) => a.order - b.order)

        setlastIndex(strSorted[strSorted.length - 1].order)
        setStores(strSorted)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      alert('Erro ao consultar Lojas')
      console.log(e)
    }
  }

  async function updateOrder(stores: Store[]) {
    try {
      setLoading(true)
      await api.put(
        '/v1/stores/order',
        { stores: stores.map((s) => ({ id: s._id, order: s.order })) },
        await generateRequestConfig()
      )
      setLoading(false)
    } catch (e) {
      setLoading(false)
      alert('Erro ao atualizar Ordens')
      console.log(e)
    }
  }

  async function removeStore(store: Store) {
    if (window.confirm('Deseja excluir a loja: ' + store.title + '?')) {
      try {
        setLoading(true)
        await api.delete(
          '/v1/stores/' + store._id,
          await generateRequestConfig()
        )
        getStores()
        setLoading(false)
      } catch (e) {
        setLoading(false)
        alert('Erro ao excluir Loja')
        console.log(e)
      }
    }
  }

  const SortableItem = SortableElement(({ value: store }: { value: Store }) => (
    <div className="border border-gray-200 p-5 w-full rounded-lg flex items-start mb-2 bg-white shadow overflow-hidden">
      <Ui.StoreImage src={store.image} />
      <Ui.InfosContainer className="items-start">
        <Ui.StoreName>{store.title}</Ui.StoreName>
        <Ui.StoreAddress>{store.address}</Ui.StoreAddress>
        <Ui.StoreAddress className="font-bold">
          Horário de Funcionamento
        </Ui.StoreAddress>
        <Ui.StoreAddress>{store.spedient}</Ui.StoreAddress>
        <Ui.StoreAddress className="mb-2">
          <span className="font-bold">Telefone:</span> {store.phone} {` `}
          <span className="font-bold">Lat-Long:</span>{' '}
          {`${store.coord[0]},${store.coord[1]}`}
        </Ui.StoreAddress>
        <div className="flex flex-row items-start">
          <Button
            onClick={() => {
              setEditingStore(store)
              setShowModal(true)
            }}
            disabled={loading}
            title="Editar"
          />
          <button
            type="button"
            className="inline-flex justify-center ml-2 py-2 px-4 border border-red-600 shadow-sm text-sm font-medium rounded-md text-red-600 bg-white hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => removeStore(store)}
            disabled={loading}
          >
            Excluir
          </button>
        </div>
      </Ui.InfosContainer>
    </div>
  ))

  const SortableList = SortableContainer(({ items }: { items: Store[] }) => {
    return (
      <div className="border-t border-gray-200 p-5 flex flex-col">
        <button
          type="button"
          className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => setShowModal(true)}
          disabled={loading}
        >
          {loading ? (
            <ReactLoading
              type="bars"
              color="rgba(79, 70, 229, var(--tw-border-opacity))"
              height={30}
              width={30}
            />
          ) : (
            'Adicionar'
          )}
        </button>
        {items.map((store: Store, index: number) => (
          <SortableItem key={`item-${store._id}`} index={index} value={store} />
        ))}
      </div>
    )
  })

  return (
    <Layout>
      <section className="w-full mx-auto flex gap-8">
        <div className="max-w-7xl mx-auto py-6 px-6 lg:px-8 md:flex">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg w-full md:w-2/3 md:mx-4">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Lojas
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Gerencia as lojas físicas que serão exibidas no aplicativo.
              </p>
            </div>
            <SortableList
              items={stores}
              onSortEnd={(e) => {
                if (!loading && e.newIndex != e.oldIndex) {
                  const strs = [...stores]
                  const item = strs.splice(e.oldIndex, 1)
                  strs.splice(e.newIndex, 0, item[0])
                  strs.forEach((s, i) => {
                    s.order = i + 1
                  })
                  console.log('STRS', strs)
                  updateOrder(strs)
                  setStores(strs)
                }
              }}
            />
          </div>
          <div
            className="bg-white shadow overflow-auto sm:rounded-lg w-full my-4 md:w-1/3 md:mx-4 md:my-0 flex-row sm:hidden md:block flex flex-column"
            style={{ height: 896, width: 414 }}
          >
            <h3 className="mx-4 mt-5 text-lg leading-6 font-medium text-gray-900">
              Preview
            </h3>
            <div className="px-4">
              <Preview stores={stores} />
            </div>
          </div>
        </div>

        <StoreModal
          isOpen={showModal}
          onClose={(reload) => {
            setShowModal(false)
            setEditingStore(undefined)
            if (reload) getStores()
          }}
          index={lastIndex}
          editingStore={editingStore}
        />
      </section>
    </Layout>
  )
}
