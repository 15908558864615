import Modal from 'react-modal'
import { useState, useEffect, ChangeEvent } from 'react'
import ReactLoading from 'react-loading'
import { Page } from '../../../product-pages/productPageTypes'

import { Promotion } from '../../promotionsTypes'

import { upload } from 'services/storage'
import { api } from 'services/api'

import { generateRequestConfig } from 'utils/requestConfig'

import * as Ui from './style'
import { centeredModal } from 'utils/modalStyles'

type ModalProps = {
  isOpen: boolean
  onClose: (reload?: boolean) => void
  index: number
  editingCategory?: Promotion
}
export const CategoryModal = ({
  isOpen,
  onClose,
  index,
  editingCategory
}: ModalProps) => {
  const [pageId, setPageId] = useState('')
  const [stores, setStores] = useState<Page[]>([])

  const [promotionName, setPromotionName] = useState('')
  const [promotionDescription, setPromotionDescription] = useState('')
  const [promotionVality, setPromotionVality] = useState('')
  const [promotionPage, setPromotionPage] = useState('')
  const [promotionTitle, setPromotionTitle] = useState('')
  const [promotionColor, setPromotionColor] = useState('')

  const [loading, setLoading] = useState(false)

  const [imageAsFile, setImageAsFile] = useState<File | undefined>()

  useEffect(() => {
    getPages()
    if (editingCategory) {
      setPromotionName(editingCategory.name)
      setPromotionDescription(editingCategory.description)
      setPromotionVality(editingCategory.validity)
      setPromotionPage(editingCategory.pageId)
      setPromotionTitle(editingCategory.title)
      setPromotionColor(editingCategory.buttonColor)
      setPageId(editingCategory.id)
    } else {
      resetStates()
    }
  }, [editingCategory])

  async function getPages() {
    try {
      setLoading(true)
      const { data } = await api.get<Page[]>(
        '/v1/customProductPage',
        await generateRequestConfig()
      )

      if (data && data.length) {
        const strSorted = data

        setStores(strSorted)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      alert('Erro ao consultar Lojas')
      console.log(e)
    }
  }

  const handleImageAsFile = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files ? e.target.files[0] : undefined
    setImageAsFile(() => image)
  }

  const resetStates = () => {
    setPromotionName('')
    setPageId('')
    setImageAsFile(undefined)
  }

  const saveStore = async () => {
    const image = imageAsFile ? await upload(imageAsFile) : undefined
    if (!image && !editingCategory) {
      return alert('Selecione uma imagem')
    }

    try {
      setLoading(true)

      const data = {
        name: promotionName,
        description: promotionDescription,
        validity: promotionVality,
        pageId: promotionPage || stores[0].id,
        image: image,
        title: promotionTitle,
        buttonColor: promotionColor,
        id: pageId,
        order: index + 1
      }

      if (editingCategory)
        await api.put(
          '/v1/promotions/' + editingCategory.id,
          data,
          await generateRequestConfig()
        )
      else await api.post('/v1/promotions', data, await generateRequestConfig())

      setLoading(false)
      resetStates()
      onClose(true)
    } catch (err) {
      setLoading(false)
      alert('Erro ao incluir a Loja')
      console.log(err)
    }
  }

  return (
    <Modal isOpen={isOpen} style={centeredModal}>
      <div className="w-full h-full bg-blue flex flex-col">
        <div className="pb-4 border-b border-gray-200 mb-5 flex justify-end">
          <button
            className="text-lg leading-6 font-medium text-gray-900"
            onClick={() => onClose()}
          >
            Fechar
          </button>
        </div>
        <div className="flex flex-col flex-1">
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-name" className="mb-4 font-bold">
              Nome da Página
            </Ui.FormTitle>
            <input
              id="store-name"
              name="categoryName"
              type="text"
              required
              value={promotionName}
              onChange={(e) => setPromotionName(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o nome da Página"
            />
          </div>
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="page-description" className="mb-4 font-bold">
              Descrição
            </Ui.FormTitle>
            <input
              id="page-description"
              name="pageDescription"
              type="text"
              required
              value={promotionDescription}
              onChange={(e) => setPromotionDescription(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe a descrição se houver"
            />
          </div>
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="page-validy" className="mb-4 font-bold">
              Validade
            </Ui.FormTitle>
            <input
              id="page-validy"
              name="pageVality"
              type="date"
              required
              value={promotionVality}
              onChange={(e) => setPromotionVality(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe a validade se houver"
            />
          </div>
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="page-Page" className="mb-4 font-bold">
              Página
            </Ui.FormTitle>
            <select
              id="page-Page"
              name="promotionPage"
              required
              value={promotionPage}
              onChange={(e) => {
                console.log(e.target.value)
                setPromotionPage(e.target.value)
              }}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Selecione a Página"
            >
              {stores.map((x) => (
                <option
                  value={x.id}
                  key={`${x.name} ${x.id}`}
                >{`${x.name} - #${x.id}`}</option>
              ))}
            </select>
          </div>
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="page-title" className="mb-4 font-bold">
              Título
            </Ui.FormTitle>
            <input
              id="page-title"
              name="pageTitle"
              type="text"
              required
              value={promotionTitle}
              onChange={(e) => setPromotionTitle(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o Título se houver"
            />
          </div>
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-color" className="mb-4 font-bold">
              Cor do Botão
            </Ui.FormTitle>
            <input
              id="store-color"
              name="storeColor"
              type="text"
              required
              value={promotionColor}
              onChange={(e) => setPromotionColor(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe a cor: #000000"
            />
          </div>

          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-phone" className="mb-4 font-bold">
              Imagem
            </Ui.FormTitle>
            <input
              id="store-image"
              name="storeImage"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              required
              onChange={handleImageAsFile}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o telefone da Loja"
            />
          </div>
        </div>
        <div className="pt-4 border-t border-gray-200 mt-5 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => saveStore()}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading type="bars" color="#fff" height={20} width={20} />
            ) : (
              'Salvar'
            )}
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onClose()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}
