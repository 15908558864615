import { useEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Home } from '../../homeTypes'

type CarouselProps = {
  home: Home
  onChange: (home: Home) => void
}

export const ProductListComp = ({ home, onChange }: CarouselProps) => {
  const [state, setState] = useState<{
    title: string
    order: string
    categoryId?: string
    brandId?: string
    collectionsIds?: string
    productsIds?: string
    skusIds?: string
  }>({
    title: home.metadata.title,
    order: home.metadata.filters![0].order || 'OrderByNameDESC',
    brandId: home.metadata.filters![0].brand,
    categoryId: home.metadata.filters![0].category,
    collectionsIds: Array.isArray(home.metadata.filters![0].collectionsIds)
      ? home.metadata.filters![0].collectionsIds?.reduce((a, b) => `${a},${b}`)
      : home.metadata.filters![0].collectionsIds,
    productsIds: home.metadata.filters![0].productsIds?.reduce(
      (a, b) => `${a},${b}`
    ),
    skusIds: home.metadata.filters![0].skusIds?.reduce((a, b) => `${a},${b}`)
  })

  useEffect(() => {
    const homeEdit = { ...home }

    homeEdit.metadata.title = state.title
    homeEdit.metadata.filters![0].order = state.order
    homeEdit.metadata.filters![0].brand = state.brandId || undefined
    homeEdit.metadata.filters![0].category = state.categoryId || undefined
    homeEdit.metadata.filters![0].collectionsIds =
      state.collectionsIds?.split(',').map((x) => x.trim()) || undefined
    homeEdit.metadata.filters![0].productsIds =
      state.productsIds?.split(',').map((x) => x.trim()) || undefined
    homeEdit.metadata.filters![0].skusIds =
      state.skusIds?.split(',').map((x) => x.trim()) || undefined

    onChange(homeEdit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <div className="grid grid-cols-6 gap-6 px-4 py-5 bg-white rounded-b-md">
      <div className="col-span-6">
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Título *
        </label>
        <input
          type="text"
          name="title"
          id="title"
          value={state.title}
          onChange={(e) =>
            setState({
              ...state,
              title: e.target.value
            })
          }
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div className="col-span-12 md:col-span-3">
        <label
          htmlFor="orderSelect"
          className="block text-sm font-medium text-gray-700"
        >
          Ordem
        </label>
        <select
          name="orderSelect"
          id="orderSelect"
          value={state.order}
          onChange={(e) =>
            setState({
              ...state,
              order: e.target.value
            })
          }
          placeholder="Selecione uma página"
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        >
          <option value="OrderByPriceASC">Menor Preço</option>
          <option value="OrderByPriceDESC">Maior Preço</option>
          <option value="OrderByTopSaleDESC">Mais Vendidos</option>
          <option value="OrderByBestDiscountDESC">Maior Desconto</option>
          <option value="OrderByNameASC">Nome Ascedente</option>
          <option value="OrderByNameDESC">Nome Descendente</option>
        </select>
      </div>
      <div className="col-span-12 md:col-span-3">
        <label
          htmlFor="categoryId"
          className="block text-sm font-medium text-gray-700"
        >
          Id da Categoria
        </label>
        <input
          type="text"
          name="categoryId"
          id="categoryId"
          value={state.categoryId}
          onChange={(e) =>
            setState({
              ...state,
              categoryId: e.target.value
            })
          }
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div className="col-span-12 md:col-span-3">
        <label
          htmlFor="brandId"
          className="block text-sm font-medium text-gray-700"
        >
          Id da Marca
        </label>
        <input
          type="text"
          name="brandId"
          id="brandId"
          value={state.brandId}
          onChange={(e) =>
            setState({
              ...state,
              brandId: e.target.value
            })
          }
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div className="col-span-12 md:col-span-3">
        <label
          htmlFor="collectionsIds"
          className="block text-sm font-medium text-gray-700"
        >
          Ids das Coleções (Separados por vírgula)
        </label>
        <input
          type="text"
          name="collectionsIds"
          id="collectionsIds"
          value={state.collectionsIds}
          onChange={(e) =>
            setState({
              ...state,
              collectionsIds: e.target.value
            })
          }
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div className="col-span-12 md:col-span-3">
        <label
          htmlFor="productsIds"
          className="block text-sm font-medium text-gray-700"
        >
          Ids de Produtos (Separados por vírgula)
        </label>
        <input
          type="text"
          name="productsIds"
          id="productsIds"
          value={state.productsIds}
          onChange={(e) =>
            setState({
              ...state,
              productsIds: e.target.value
            })
          }
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div className="col-span-12 md:col-span-3">
        <label
          htmlFor="skusIds"
          className="block text-sm font-medium text-gray-700"
        >
          Ids de Skus (Separados por vírgula)
        </label>
        <input
          type="text"
          name="skusIds"
          id="skusIds"
          value={state.skusIds}
          onChange={(e) =>
            setState({
              ...state,
              skusIds: e.target.value
            })
          }
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
  )
}
