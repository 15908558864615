import Modal from 'react-modal'
import { useState, useEffect } from 'react'
import ReactLoading from 'react-loading'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { Category, Child } from '../../categoriesTypes'

import { api } from 'services/api'

import { generateRequestConfig } from 'utils/requestConfig'

import * as Ui from './style'

type ModalProps = {
  isOpen: boolean
  onClose: (subcat?: Child, oldcat?: Child) => void
  index: number
  subCategory?: Child
  category: Category
}
export const CategoryModal = ({
  isOpen,
  onClose,
  subCategory,
  category
}: ModalProps) => {
  const [subCategoryName, setsubCategoryName] = useState('')
  const [subCategoryId, setsubCategoryId] = useState(0)
  const [, setRightAlignment] = useState(false)
  const [, setCategoryId] = useState(0)
  const [addItem, setAddItem] = useState(false)

  const [subCategoryEditing, setSubCategoryEditing] = useState<Child>({})

  const [categoryIdAdd, setCategoryIdAdd] = useState(0)
  const [categorynameAdd, setCategorynameAdd] = useState('')

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (subCategory) {
      setSubCategoryEditing(subCategory)
      setsubCategoryName(subCategory.title || '')
      setsubCategoryId(subCategory.id || 0)
    } else {
      resetStates()
    }
  }, [subCategory])

  const resetStates = () => {
    setsubCategoryName('')
    setsubCategoryId(0)
    setRightAlignment(false)
    setCategoryId(0)
  }

  const saveSubCategory = async () => {
    if (!subCategoryName) {
      return alert('Informe o Nome da Sub-Categoria')
    } else if (!subCategoryId) {
      return alert('Informe o Identificador da Sub-Categoria')
    }

    try {
      setLoading(true)

      const data: Child = {
        id: subCategoryId,
        title: subCategoryName,
        children: subCategoryEditing?.children
      }

      if (subCategory)
        await api.put(
          '/v1/categories/' + category._id + '/subcategories/' + subCategory.id,
          data,
          await generateRequestConfig()
        )
      else
        await api.post(
          '/v1/categories/' + category._id + '/subcategories',
          data,
          await generateRequestConfig()
        )

      setLoading(false)
      resetStates()
      setSubCategoryEditing({})
      onClose(data, subCategory)
    } catch (err) {
      setLoading(false)
      alert('Erro ao incluir a Loja')
      console.log(err)
    }
  }

  const SortableItem = SortableElement(
    ({ value: sub, index }: { value: Child; index: number }) => (
      <Ui.FormTitle
        key={`${sub.id}-subchilds`}
        className={`group cursor-pointer`}
      >
        <b>{sub.id}</b> - {sub.title}{' '}
        <button
          type="button"
          className="inline-flex justify-center px-4 border border-transparent text-transparent ml-3 group-hover:border-red-600 group-hover:text-red-600 shadow-sm text-sm font-medium rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={(event) => {
            event.preventDefault()
            const subCat = { ...subCategoryEditing }
            subCat.children!.splice(index, 1)
            setSubCategoryEditing(subCat)
          }}
        >
          Excluir
        </button>
      </Ui.FormTitle>
    )
  )

  const SortableList = SortableContainer(() => {
    return (
      <div className="flex-1 flex flex-col">
        {subCategoryEditing?.children?.map((x, index) => (
          <SortableItem index={index} value={x} key={`item-subs-${x.id}`} />
        ))}
      </div>
    )
  })

  return (
    <Modal isOpen={isOpen}>
      <div className="w-full h-full bg-blue flex flex-col">
        <div className="pb-4 border-b border-gray-200 mb-5 flex justify-end">
          <button
            className="text-lg leading-6 font-medium text-gray-900"
            onClick={() => {
              setSubCategoryEditing({})
              onClose()
            }}
          >
            Fechar
          </button>
        </div>
        <div className="flex flex-col flex-1 h-full">
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-name" className="mb-4 font-bold">
              Nome da Sub-Categoria
            </Ui.FormTitle>
            <input
              id="store-name"
              name="subCategoryName"
              type="text"
              required
              value={subCategoryName}
              onChange={(e) => setsubCategoryName(e.target.value)}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o nome da Sub-Categoria"
            />
          </div>
          <div className="rounded shadow-sm -space-y-px mb-5">
            <Ui.FormTitle htmlFor="store-address" className="mb-4 font-bold">
              Id da Sub-Categoria
            </Ui.FormTitle>
            <input
              id="store-address"
              name="subCategoryId"
              type="number"
              required
              value={subCategoryId}
              onChange={(e) => setsubCategoryId(parseInt(e.target.value))}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Informe o Id da Sub-Categoria"
            />
          </div>
          <div className="flex-1 flex flex-col w-full overflow-auto  border border-gray-300 px-3 py-2 rounded-md shadow-md">
            {!addItem && (
              <button
                type="button"
                className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setAddItem(true)}
              >
                Adicionar
              </button>
            )}
            {addItem && (
              <div className="flex flex-row mb-5 items-end">
                <div className="rounded shadow-sm -space-y-px mr-2">
                  <Ui.FormTitle
                    htmlFor="add-subcategory-id"
                    className="mb-4 font-bold"
                  >
                    Id
                  </Ui.FormTitle>
                  <input
                    id="add-subcategory-id"
                    name="subCategoryIdAdd"
                    type="number"
                    required
                    value={categoryIdAdd}
                    onChange={(e) => setCategoryIdAdd(parseInt(e.target.value))}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Informe o Id da Sub-Categoria"
                  />
                </div>
                <div className="rounded shadow-sm -space-y-px mr-5">
                  <Ui.FormTitle
                    htmlFor="add-subcategory-name"
                    className="mb-4 font-bold"
                  >
                    Nome
                  </Ui.FormTitle>
                  <input
                    id="add-subcategory-name"
                    name="add-subcategory-name"
                    type="text"
                    required
                    value={categorynameAdd}
                    onChange={(e) => setCategorynameAdd(e.target.value)}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Informe o Id da Sub-Categoria"
                  />
                </div>
                <button
                  type="button"
                  className="inline-flex mr-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    const subCat = { ...subCategoryEditing }

                    if (!categoryIdAdd) {
                      return alert('Informe o Id da Sub-Categoria')
                    } else if (isNaN(categoryIdAdd)) {
                      return alert('O Id precisa ser um número')
                    } else if (!categorynameAdd) {
                      return alert('Informe o Nome da Sub-Categoria')
                    }

                    if (!subCat.children) subCat.children = []
                    subCat.children?.splice(0, 0, {
                      id: categoryIdAdd || 0,
                      title: categorynameAdd
                    })

                    setSubCategoryEditing(subCat)

                    setAddItem(false)
                    setCategorynameAdd('')
                    setCategoryIdAdd(0)
                  }}
                  disabled={loading}
                >
                  Salvar
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setAddItem(false)}
                >
                  Cancelar
                </button>
              </div>
            )}
            <SortableList
              onSortEnd={(e) => {
                if (e.newIndex != e.oldIndex) {
                  const subCat = { ...subCategoryEditing }

                  const itm = subCat.children!.splice(e.oldIndex, 1)
                  subCat.children!.splice(e.newIndex, 0, itm[0])
                  setSubCategoryEditing(subCat)
                }
              }}
            />
          </div>
        </div>
        <div className="pt-4 border-t border-gray-200 mt-5 flex">
          <button
            type="button"
            className="inline-flex mr-2 justify-center mb-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => saveSubCategory()}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading type="bars" color="#fff" height={20} width={20} />
            ) : (
              'Salvar'
            )}
          </button>
          <button
            type="button"
            className="inline-flex justify-center mb-4 py-2 px-4 border border-indigo-600 shadow-sm text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              setSubCategoryEditing({})
              onClose()
            }}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  )
}
