import { FormLogin } from 'pages/login/components/form-login'
import { auth } from '../../config/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Navigate } from 'react-location'

export const LoginPage = () => {
  const [user, loading] = useAuthState(auth)

  if (loading) {
    return null
  }

  if (user) {
    return <Navigate to={'/'} />
  }

  return (
    <div className="h-screen w-full flex">
      <main className="w-full h-full flex">
        <div className="w-5/12 h-full flex justify-end items-center px-20">
          <div className="flex flex-col w-80">
            <FormLogin />
          </div>
        </div>
        <div className="w-7/12 h-full bg-primary flex flex-col justify-center px-24 relative">
          <div className="z-10">
            <h1 className="text-white text-5xl font-bold">Bem-vindo ao</h1>
            <h1 className="text-white text-5xl font-bold">Omnion Admin</h1>
            <p className="text-gray-300 mt-5">
              Gerêncie seu aplicativo e consulte as métricas de vendas e acessos
              do aplicativo.
            </p>
          </div>
          <svg
            className="absolute inset-0 pointer-events-none"
            viewBox="0 0 960 540"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMax slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              className="opacity-20"
              fill="none"
              stroke="currentColor"
              strokeWidth="100"
            >
              <circle r="234" cx="196" cy="23"></circle>
              <circle r="234" cx="790" cy="491"></circle>
            </g>
          </svg>
        </div>
      </main>
    </div>
  )
}
