import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBL3xMQ0dVPZP3mg5ujxGFPF2jIDx-ZLyE',
  authDomain: 'omnionadmin.firebaseapp.com',
  projectId: 'omnionadmin',
  storageBucket: 'omnionadmin.appspot.com',
  messagingSenderId: '80537029235',
  appId: '1:80537029235:web:ffae6469f484ad9591f806',
  measurementId: 'G-H8LRVLHQCG'
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const analytics = getAnalytics(app)
const firestore = getFirestore(app)
const storage = getStorage(app)

export { auth, analytics, firestore, storage }
