import { Bubble, Home } from 'pages/custom-home/homeTypes'
import { useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable
} from 'react-beautiful-dnd'
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'
import { ConfirmModal } from '../confirm-modal'
import { BubblesModal } from './components/modal'

type BubblesProps = {
  home: Home
  onUpdate: (bubble?: Bubble, newImage?: File) => void
}

export const Bubbles = ({ home, onUpdate }: BubblesProps) => {
  const [dragging, setDragging] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [bubbleNewImage, setBubbleNewImage] = useState<{
    [key: string]: string
  }>({})
  const [selectedBubble, setSelectedBubble] = useState<Bubble | undefined>(
    undefined
  )

  const handleDelete = () => {
    setShowDeleteModal(false)
    if (!selectedBubble) return
    home.metadata.bubbles?.splice(
      home.metadata.bubbles?.indexOf(selectedBubble),
      1
    )
    onUpdate(undefined, undefined)
  }

  const handleDrop = (result: DropResult) => {
    setDragging(false)
    if (!result.destination) return
    const updatedList = [...(home.metadata.bubbles ?? [])]
    const [reorderedItem] = updatedList.splice(result.source.index, 1)
    updatedList.splice(result.destination.index, 0, reorderedItem)
    if (
      !home.metadata.bubbles?.every((val, index) => val === updatedList[index])
    ) {
      home.metadata.bubbles = updatedList
      onUpdate(undefined, undefined)
    }
  }

  const handleStart = () => setDragging(true)

  const onSave = (
    oldId: string,
    bubble: Bubble,
    newImage?: { file: File; b64: string }
  ) => {
    setShowEditModal(false)
    if (oldId != bubble.id && bubbleNewImage[oldId]) {
      bubbleNewImage[bubble.id] = bubbleNewImage[oldId]
      bubbleNewImage[oldId] = ''
    }
    const index = home?.metadata?.bubbles?.findIndex((b) => {
      return b.id == oldId
    })
    if (index != undefined && index >= 0) {
      home?.metadata?.bubbles?.splice(index, 1, bubble)
    } else {
      home?.metadata?.bubbles?.push(bubble)
    }
    if (newImage) {
      bubbleNewImage[bubble.id] = newImage.b64
      setBubbleNewImage(bubbleNewImage)
    }
    onUpdate(bubble, newImage?.file)
  }

  const renderEditDelete = (bubble: Bubble) => (
    <div className="-mb-14 flex-row object-cover rounded-full h-14 w-14 group">
      <div
        className="justify-center items-center flex bg-gray-200 text-gray-600 h-7 w-14 rounded-t-full opacity-0 hover:opacity-95 cursor-pointer"
        onClick={() => {
          setSelectedBubble(bubble)
          setShowEditModal(true)
        }}
      >
        <HiOutlinePencil />
      </div>
      <div
        className="justify-center items-center flex bg-red-100 text-red-600 h-7 w-14 rounded-b-full opacity-0 hover:opacity-95 cursor-pointer"
        onClick={() => {
          setSelectedBubble(bubble)
          setShowDeleteModal(true)
        }}
      >
        <HiOutlineTrash />
      </div>
    </div>
  )

  const renderBubble = (bubble: Bubble, provided: DraggableProvided) => (
    <div
      key={bubble.id}
      className="item-container flex flex-col items-center mr-4 min-w-max"
      ref={provided.innerRef}
      {...provided.dragHandleProps}
      {...provided.draggableProps}
    >
      <div>
        {renderEditDelete(bubble)}
        <img
          className="object-cover rounded-full h-14 w-14"
          src={bubbleNewImage[bubble.id] ?? bubble.image}
        />
      </div>
      <p className="truncate w-20 text-center text-black text-xs mt-2">
        {bubble.title}
      </p>
    </div>
  )

  const renderAddBubble = () => (
    <div
      className="flex flex-col h-20 w-20 justify-center items-center"
      onClick={() => {
        setSelectedBubble(undefined)
        setShowEditModal(true)
      }}
    >
      <div
        className="
      flex justify-center
      cursor-pointer
      hover:bg-gray-300
      items-center
      h-14 w-14
      border-dashed border
      border-indigo-900
      opacity-70
      rounded-full
    "
      >
        <svg className="h-8 w-8" fill="#222c85" viewBox="0 0 24 24">
          <path d="M19,13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
        </svg>
      </div>
      <p className="truncate w-20 text-center text-black text-xs mt-2">Nova</p>
    </div>
  )

  return (
    <section>
      <DragDropContext onDragEnd={handleDrop} onDragStart={handleStart}>
        <Droppable droppableId="list-container" direction="horizontal">
          {(provided) => (
            <div
              className="bg-white list-container flex flex-row w-auto overflow-x-auto pt-10 pb-10 pl-4 pr-4 rounded-b-md"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {home.metadata?.bubbles?.map((bubble, index) => (
                <Draggable
                  key={bubble.id}
                  draggableId={bubble.id}
                  index={index}
                >
                  {(provided) => renderBubble(bubble, provided)}
                </Draggable>
              ))}
              {dragging ? <></> : renderAddBubble()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <BubblesModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        bubble={selectedBubble}
        selectedImage={bubbleNewImage[selectedBubble?.id ?? '']}
        onSave={onSave}
      />
      <ConfirmModal
        isOpen={showDeleteModal}
        message="Tem certeza que deseja excluir este item?"
        negativeText="Não"
        positiveText="Sim"
        onNegative={() => {
          setShowDeleteModal(false)
        }}
        onPositive={handleDelete}
      />
    </section>
  )
}
